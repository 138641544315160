import React, { createContext, useContext, useState, useEffect } from 'react';
import { doc, setDoc, onSnapshot, getDoc } from 'firebase/firestore';
import { db } from './firebase';

const UserDataContext = createContext();

export const useUserData = () => {
  return useContext(UserDataContext);
};

export const UserDataProvider = ({ children, user, loading }) => {
  const [userData, setUserData] = useState({});
  useEffect(() => {
    let unsubscribe;
    if (user) {
      const userId = user.uid;
      const docRef = doc(db, 'Profiles', userId);

      // Check if user document exists
      getDoc(docRef)
        .then((docSnap) => {
          if (!docSnap.exists()) {
            // If document does not exist, initialize it
            const initialData = {
              email: user.email,
              applied: [],
              favorites: [],
              onboarded: false,
            };
            setDoc(docRef, initialData, { merge: true })
              .then(() => {
               //console.log("User data initialized!");
                setUserData(initialData); // Set the initialData in the state
              })
              .catch((error) => {
                console.error("Error initializing user data: ", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error checking user data: ", error);
        });

      // Listen for real-time updates
      unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          setUserData(docSnap.data());
        }
      });
    }
    return () => unsubscribe && unsubscribe();
  }, [user]);

  useEffect(() => {
    if (user && userData) {
      const userId = user.uid;
      const docRef = doc(db, 'Profiles', userId);
      setDoc(docRef, userData, { merge: true }).catch((error) => {
        console.error("Error updating user data: ", error);
      });
    }
  }, [userData, user]);

  return (
    <UserDataContext.Provider
      value={{ userData, setUserData, user, isAuthenticated: !!user, isLoading: loading, currentLink: "https://app.wranglejobs.com", currentApi: "https://app.wranglejobs.com/api" }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
