import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { collection, addDoc, doc, setDoc, getDoc, arrayUnion, arrayRemove} from "firebase/firestore"; 
import { db, storage } from "../firebase";
import { onSnapshot } from "firebase/firestore";
import { motion } from "framer-motion";
import { useUserData } from "../UserDataContext";
import { useJobs } from "../jobsContext";


const JobContainer = ({
  onSelect,
  position,
  pubDate,
  company,
  location,
  tags,
  guid,
  applied
}) => {
  const {user, isAuthenticated, userData, setUserData} = useUserData();
  const {selectedJob = {}} = useJobs();
  const [isFavorites, setIsFavorites] = useState(false);

  const timeSince = (date) => {
    const now = new Date();
    const publishedDate = new Date(date);
    const seconds = Math.floor((now - publishedDate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  };
  

  useEffect(() => {
    if (userData && userData.favorites) {
      setIsFavorites(userData.favorites.includes(guid));
    }
  }, [userData, guid]);

  const tagColors = {
    engineering: { bgColor: "bg-green-50", textColor: "text-green-600" },
    sales: { bgColor: "bg-blue-50", textColor: "text-blue-600" },
    marketing: { bgColor: "bg-purple-50", textColor: "text-purple-600" },
    product: { bgColor: "bg-pink-50", textColor: "text-pink-600" },
    design: { bgColor: "bg-teal-50", textColor: "text-teal-600" },
    support: { bgColor: "bg-red-50", textColor: "text-red-600" },
    operations: { bgColor: "bg-orange-50", textColor: "text-orange-600" },
    data: { bgColor: "bg-indigo-50", textColor: "text-indigo-600" },
    finance: { bgColor: "bg-yellow-50", textColor: "text-yellow-600" },
    management: { bgColor: "bg-gray-100", textColor: "text-gray-600" },
  };

  const fallbackColor = { bgColor: "bg-gray-200", textColor: "text-gray-700" };

  const handleStarClick = async () => {
   //console.log(userData);
   //console.log(selectedJob);
    setIsFavorites(!isFavorites);
  
    if (!isFavorites) {
      // Add guid to favorites array if it's not a favorite
      setUserData(prevUserData => ({
        ...prevUserData,
        favorites: [...prevUserData.favorites, guid],
      }));
    } else {
      // Remove guid from favorites array if it's a favorite
      setUserData(prevUserData => ({
        ...prevUserData,
        favorites: prevUserData.favorites.filter(favorite => favorite !== guid),
      }));
    }
  };
  
  
  

  return (
    <div onClick={onSelect} className={`self-stretch rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-col py-3 px-0 items-start justify-center text-left text-lg text-gray-700 font-text-l-regular border-[1px] border-solid ${selectedJob.guid != null ? selectedJob.guid === guid ? "border-mediumseagreen" : "border-neutral-600" : "border-neutral-600" } cursor-pointer transform-gpu transition-all duration-150 ${selectedJob.guid != null ? selectedJob.guid === guid ? "border-mediumseagreen-200" : "hover:border-neutral-300" : "hover:border-neutral-300"} hover:scale-[0.99]`}>
      <div className="self-stretch flex flex-col py-0 px-5 items-start justify-start">
        <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
          <div className="self-stretch flex flex-row items-start justify-start gap-[10px]">
            <div className="flex-1 relative tracking-[-0.1px] leading-[28px] font-semibold">
              {position}
            </div>
            <div className={`relative text-sm tracking-[-0.1px] leading-[28px] font-semibold text-gray-50`}>
              {timeSince(pubDate)}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px] text-sm text-gray-50">
            <div className="flex flex-row items-center justify-start text-gray-700">
              <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                {company}
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <img
                className="relative w-[18px] h-[18px] shrink-0"
                alt=""
                src="/location-pin.svg"
              />
              <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                {location}
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <img
                className="relative w-[18px] h-[18px] shrink-0"
                alt=""
                src="/timeloading.svg"
              />
              <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                Full Time
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between gap-[10px]">
            <div className="flex flex-row items-center justify-start gap-[16px] text-smi">
              {tags && tags.map((tag) => {
                const normalizedTag = tag.toLowerCase();
                const color = tagColors[normalizedTag] || fallbackColor;
                return (
                  <div
                    key={tag}
                    className={`rounded-8xs ${color.bgColor} ${color.textColor} flex flex-row py-0.5 px-2 items-center justify-start gap-[4px]`}
                  >
                    <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                      {tag.charAt(0).toUpperCase() + tag.slice(1)}
                    </div>
                  </div>
                );
              })}
            </div>
            {applied ? null : 
            (<motion.svg
              onClick={handleStarClick}
              className={`outline-none relative w-[24px] h-[24px] shrink-0 ${isFavorites ? 'text-yellow-400' : 'text-gray-200'}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              whileTap={{ scale: 1.3 }} // Add the animation effect using Framer Motion's whileTap prop
            >
              <path d="M12.6456 1.77566C12.2372 1.58096 11.7628 1.58096 11.3545 1.77566C11.0014 1.94398 10.8068 2.23622 10.7087 2.39654C10.6072 2.56228 10.503 2.77341 10.4015 2.97919L8.24576 7.34648L3.42371 8.05129C3.19672 8.08442 2.96381 8.11841 2.7749 8.16384C2.59214 8.20779 2.25423 8.30284 1.98531 8.58668C1.67422 8.91505 1.52792 9.36626 1.58714 9.8147C1.63833 10.2023 1.85618 10.4776 1.97838 10.6204C2.10469 10.768 2.27334 10.9322 2.43771 11.0923L5.9255 14.4894L5.10254 19.2877C5.06369 19.5139 5.02383 19.746 5.00849 19.9398C4.99365 20.1272 4.97931 20.4781 5.16601 20.8218C5.38196 21.2194 5.76584 21.4983 6.21071 21.5807C6.59531 21.652 6.9246 21.5299 7.09823 21.4579C7.27778 21.3834 7.4862 21.2737 7.68936 21.1668L12 18.8999L16.3107 21.1668C16.5139 21.2737 16.7223 21.3834 16.9018 21.4579C17.0755 21.5299 17.4047 21.652 17.7893 21.5807C18.2342 21.4983 18.6181 21.2194 18.834 20.8218C19.0207 20.4781 19.0064 20.1272 18.9916 19.9398C18.9762 19.746 18.9364 19.5139 18.8975 19.2877L18.0745 14.4894L21.5624 11.0922C21.7267 10.9322 21.8954 10.768 22.0217 10.6204C22.1439 10.4776 22.3617 10.2023 22.4129 9.8147C22.4721 9.36626 22.3258 8.91505 22.0147 8.58668C21.7458 8.30284 21.4079 8.20779 21.2252 8.16384C21.0362 8.11841 20.8033 8.08442 20.5763 8.05129L15.7543 7.34648L13.5986 2.97927C13.4971 2.77347 13.3929 2.56229 13.2914 2.39654C13.1932 2.23622 12.9986 1.94398 12.6456 1.77566Z"/>
              </motion.svg>)
              }
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobContainer;

