import React, { useState, useRef, useEffect } from 'react';

const DropdownInput = ({ titleText, options, value, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [prevValue, setPrevValue] = useState(null);
  const [selectedValue, setSelectedValue] = useState(() => {
    if (options && options.length) {
      const option = options.find(({value: optVal}) => optVal.includes(value) || optVal === value);
      return value && option?.value || null;
    }
    return null;
  });
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    const option = options.find(({value: optVal}) => optVal === value);
    if (value && value !== selectedValue && options && option) {
      setSelectedValue(value);

      if (value !== prevValue) {
        onChange(value);
        setPrevValue(value);
      }
    }
  }, [value, options, prevValue]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!titleText || !options || options.length === 0) {
    return null;
  }

  return (
    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] text-left text-sm text-gray-700 font-text-l-regular">
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
        {titleText}
      </div>
      <div ref={dropdownRef} className="relative w-full">
        <div
          className="font-text-l-regular text-mini bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-between border-[1px] border-solid border-neutral-700 focus:outline-none cursor-pointer"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div>{selectedValue ? options.find(({value: optVal}) => optVal === selectedValue)?.label : ''}</div>
          <img
            className="relative w-6 h-6 shrink-0"
            alt=""
            src="/chevrondown2.svg"
          />
        </div>
        {isDropdownOpen && (
          <div className="absolute left-0 w-full top-full bg-white border-[1px] border-solid border-neutral-700 rounded-md z-10">
            {options.map((option, index) => (
              <div
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                key={option.value}
                onClick={() => {
                  setIsDropdownOpen(false);
                  onChange(index);
                  setSelectedValue(option.value);
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
        Hint text
      </div>
    </div>
  );
};

export default DropdownInput;
