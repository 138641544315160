import React from 'react';
import { useState, useRef, useEffect } from 'react';
import AutoApplyModal from './AutoApplyModal';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, addDoc, doc, getDoc} from "firebase/firestore"; 
import { db, storage } from "../firebase";
import { useUserData } from '../UserDataContext';
import { useJobs } from '../jobsContext';
import { usePayment } from '../PaymentContext';
import Banner from "./Banner";


const JobBar = props => {
  const {setUpgradeFeatureModal, fetchSubscriptionData, currentPlan} = usePayment();
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const {setShowUSOnly, setShowRemoteOnly, setShowFavoritesOnly, selectedTags, setSelectedTags} = useJobs();
  const inputRef = useRef(null);
  const inputContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const dropdownWidthRef = useRef(null);
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
  const filterButtonRef = useRef(null);
  const filterDropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {userData, isAuthenticated} = useUserData();
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerType, setBannerType] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const displayBanner = (message) => {
    setBannerMessage(message);
    setShowBanner(true);
    setStartExitAnimation(false);
    setTimeout(() => {
      setStartExitAnimation(true);
    }, 3000);
  };
  const tagColors = {
    engineering: { bgColor: 'bg-green-50', textColor: 'text-green-600' },
    sales: { bgColor: 'bg-blue-50', textColor: 'text-blue-600' },
    marketing: { bgColor: 'bg-purple-50', textColor: 'text-purple-600' },
    product: { bgColor: 'bg-pink-50', textColor: 'text-pink-600' },
    design: { bgColor: 'bg-teal-50', textColor: 'text-teal-600' },
    support: { bgColor: 'bg-red-50', textColor: 'text-red-600' },
    operations: { bgColor: 'bg-orange-50', textColor: 'text-orange-600' },
    data: { bgColor: 'bg-indigo-50', textColor: 'text-indigo-600' },
    finance: { bgColor: 'bg-yellow-50', textColor: 'text-yellow-600' },
    management: { bgColor: 'bg-gray-100', textColor: 'text-gray-600' },
  };
  const allTags = Object.keys(tagColors);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsInputFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutsideFilter = (event) => {
    if (
      filterButtonRef.current &&
      !filterButtonRef.current.contains(event.target) &&
      filterDropdownRef.current &&
      !filterDropdownRef.current.contains(event.target)
    ) {
      setIsFilterDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    };
  }, []);

  const handleFilterOptionClick = (option) => {
    if (isFilterDropdownOpen) {
      if (option === 'Favorites') {
        setShowFavoritesOnly(prevShowFavoritesOnly => !prevShowFavoritesOnly);
      } else if (option === 'US Only') {
        setShowUSOnly(prevShowUSOnly => !prevShowUSOnly);
      } else if (option === 'Remote Jobs') {
        setShowRemoteOnly(prevShowRemoteOnly => !prevShowRemoteOnly);
      }
      setSelectedFilters((prevSelectedFilters) => {
        if (prevSelectedFilters.includes(option)) {
          return prevSelectedFilters.filter((filter) => filter !== option);
        } else {
          return [...prevSelectedFilters, option];
        }
      });
      setIsFilterDropdownOpen(false);
    }
  };

  const checkSubscriptions = async () => {
    if(!isAuthenticated) return
    /*if(currentPlan == 1){
      setUpgradeFeatureModal(true);
    }
    else{*/
      //const currentStatus = await fetchSubscriptionData();
      //if(currentPlan == 1){
        //setUpgradeFeatureModal(true);
      //}
      else{
        let nameregex = new RegExp("^[a-zA-Z]+[-' ]+[a-zA-Z]+([-' ]+[a-zA-Z]+)?$");
        let emailregex= new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])");
        let phoneregex = new RegExp("^(?=([+()\\d\\s-]*\\d){10,})[+\\d()\\s-]*$");
        if(userData.name == undefined || !nameregex.test(userData.name)){
          displayBanner("Error: Invalid name.", "error");

        }
        else if (userData.phone == undefined || !phoneregex.test(userData.phone)){
          displayBanner("Error: Invalid phone.", "error");

        }
        else if (userData.address == undefined){
          displayBanner("Error: Invalid address.", "error");

        }
        else if (userData.email == undefined || !emailregex.test(userData.email)){
          displayBanner("Error: Invalid email", "error");

        }
        else if (userData.filename == undefined || userData.filename == null){
          displayBanner("Error: Please upload a resume", "error");

        }
        else{
          setIsModalOpen(true)
        }
      }
    //}
  }  



  const renderTag = (tag, handleClickTag, fromDropdown) => {
    const normalizedTag = tag.toLowerCase();
    const color =
      tagColors[normalizedTag] || {
        bgColor: 'bg-gray-100',
        textColor: 'text-gray-600',
      };
    const isSelected = selectedTags.includes(tag);
    return (
      <div
        className={`rounded-8xs ${color.bgColor} ${color.textColor} flex flex-row py-0.5 px-2 items-center justify-start gap-[4px] ${isSelected ? 'opacity-60' : 'opacity-100'} cursor-pointer`}
        style={{ display: 'inline-block' }}
        onClick={(event) => {
          event.stopPropagation();
          if (fromDropdown) {
            setIsInputFocused(false);
            setSelectedTags((prevSelectedTags) => {
              if (prevSelectedTags.includes(tag)) {
                return prevSelectedTags.filter((t) => t !== tag);
              } else {
                return [...prevSelectedTags, tag];
              }
            });
            props.onTagSelect(tag);
            props.onSearch('');
          } else if (handleClickTag) {
            handleClickTag(tag);
          }
        }}        
      >
        <div
          className="relative tracking-[-0.1px] leading-[18px] font-medium"
          style={{ display: 'inline-block', overflowWrap: 'break-word' }}
        >
          {tag.charAt(0).toUpperCase() + tag.slice(1)}
        </div>
      </div>
    );
  };

  return (
    <>
    {showBanner && <Banner startExitAnimation={startExitAnimation} bannerMessage={bannerMessage} bannerType={bannerType}/>}

    <div className="self-stretch flex flex-col items-start justify-start gap-[24px] text-9xl ">
      <div className="self-stretch flex flex-col items-start justify-start">
        <div className="self-stretch flex flex-row items-start justify-between">
          <div className="relative tracking-[-0.01em] leading-[38px] font-semibold">
            Opportunities
          </div>
          <button className="cursor-pointer [border:none] py-3 px-[18px] bg-mediumseagreen-200 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300" onClick={() => checkSubscriptions()}>
            <img
              className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
              alt=""
              src="/placeholder1.svg"
            />
            <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
              Auto-Apply
            </div>
            <img
              className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
              alt=""
              src="/placeholder2.svg"
            />
          </button>
            </div>
              <div className="relative text-base tracking-[-0.1px] leading-[24px] text-gray-50 inline-block w-[328px]">
                  Here you’ll see the active listings and apply.
              </div>
            </div>
              <div 
              
              className="self-stretch h-15 shrink-0 flex flex-row items-start justify-start gap-[12px] text-sm relative">
              <div
                ref={inputContainerRef}
                onClick={() => {
                  setIsInputFocused(true);
                }}
                className="cursor-pointer flex-1 rounded-8xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row p-3 items-center justify-start gap-4 text-sm text-success-600 border-[1px] border-solid border-neutral-700"
              >
                <img
                  className="relative w-5 h-5 shrink-0 overflow-hidden"
                  alt=""
                  src="/labeldouble.svg"
                />
                <div className="flex gap-1">
                  {selectedTags.map((tag) =>
                    renderTag(tag, (tagToRemove) => {
                      setSelectedTags((prevSelectedTags) =>
                        prevSelectedTags.filter((t) => t !== tagToRemove)
                      );
                      props.onTagRemove(tagToRemove);
                    }, false)
                  )}
                </div>
                <div className="text-neutral-400">
                  Click to select tags...
                </div>
              </div>
              <div
                ref={filterButtonRef}
                onClick={() => {
                  dropdownWidthRef.current = filterDropdownRef.clientWidth;
                  setIsFilterDropdownOpen(!isFilterDropdownOpen);
                }}                
                className="cursor-pointer self-stretch rounded-8xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center px-3 justify-center gap-4 text-sm text-success-600 border-[1px] border-solid border-neutral-700"
              >
              <motion.svg
                  whileTap={{ scale: 1.1 }}
                  className={`outline-none relative w-[24px] h-[24px] shrink-0 ${isFilterDropdownOpen || props.showFavoritesOnly || props.showUSOnly || props.showRemoteOnly ? 'mediumseagreen-200' : 'text-gray-200'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M3.56809 2C3.57871 2 3.58934 2.00001 3.59999 2.00001L20.4319 2C20.6843 1.99997 20.9301 1.99994 21.1382 2.01695C21.3668 2.03563 21.6365 2.07969 21.908 2.21799C22.2843 2.40974 22.5903 2.7157 22.782 3.09202C22.9203 3.36345 22.9644 3.63318 22.983 3.86178C23 4.06993 23 4.31571 23 4.56809L23 5.2694C23 5.28764 23 5.30639 23.0001 5.32559C23.0007 5.53391 23.0015 5.79616 22.9343 6.05176C22.8761 6.27325 22.7803 6.4831 22.651 6.67216C22.5019 6.89031 22.3032 7.06149 22.1454 7.19746C22.1308 7.21 22.1166 7.22224 22.1028 7.23419L15.707 12.7772C15.5983 12.8714 15.5439 12.9189 15.506 12.9552L15.5031 12.958L15.5029 12.9621C15.5003 13.0145 15.5 13.0867 15.5 13.2306V18.4584C15.5 18.4693 15.5001 18.4821 15.5003 18.4964C15.5017 18.6339 15.5048 18.9193 15.4054 19.1858C15.3218 19.4099 15.1858 19.6108 15.0087 19.7716C14.7983 19.9629 14.5321 20.0661 14.4039 20.1158C14.3905 20.121 14.3787 20.1256 14.3685 20.1296L10.9406 21.5008C10.7817 21.5644 10.606 21.6347 10.4511 21.681C10.2844 21.7307 10.0106 21.7957 9.69481 21.7301C9.30778 21.6497 8.96815 21.4198 8.74974 21.0903C8.57152 20.8214 8.53021 20.543 8.51449 20.3698C8.49987 20.2088 8.49993 20.0196 8.49998 19.8484L8.49999 13.2306C8.49999 13.0867 8.4997 13.0145 8.49711 12.9621L8.4969 12.958L8.49396 12.9552C8.45606 12.9189 8.40167 12.8714 8.29294 12.7772L1.89717 7.23419C1.88338 7.22224 1.86918 7.21 1.85462 7.19747C1.6968 7.06149 1.49812 6.89032 1.34897 6.67216C1.21972 6.4831 1.12389 6.27325 1.06568 6.05176C0.998502 5.79617 0.999267 5.53391 0.999875 5.3256C0.999931 5.30639 0.999986 5.28764 0.999986 5.2694V4.60001C0.999986 4.58936 0.999985 4.57873 0.999983 4.56811C0.999953 4.31572 0.999924 4.06993 1.01693 3.86178C1.03561 3.63318 1.07968 3.36345 1.21797 3.09202C1.40972 2.7157 1.71568 2.40974 2.092 2.21799C2.36343 2.07969 2.63316 2.03563 2.86176 2.01695C3.06991 1.99994 3.31571 1.99997 3.56809 2Z"/>
                </motion.svg>
              </div>
              <AnimatePresence>
              <AnimatePresence key="filter-presence">
              {filterButtonRef.current && (
                <motion.div
                  key="filter-dropdown"
                  ref={filterDropdownRef}
                  className={`absolute top-full bg-white rounded-md z-10 ${!isFilterDropdownOpen ? 'hidden' : ''}`}
                  style={{
                    right: filterButtonRef.current
                    ? '0px'
                    : "auto",
                    width: "fit-content",
                    minWidth: filterButtonRef.current ? filterButtonRef.current.clientWidth + "px" : "100%",
                    boxSizing: "border-box",
                    border: "1px solid #CBD5E0",
                    maxHeight: isFilterDropdownOpen? "160px" : "40px",
                    overflowY: "scroll",
                  }}
                  initial={{ opacity: 0, y: -10 }}
                  animate={isFilterDropdownOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: -10 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="list-none">
                    {["Favorites", "US Only", "Remote Jobs"].map((option) => (
                      <li
                        key={option}
                        className={`py-4 pr-16 pl-4 ${
                          selectedFilters.includes(option) ? "text-mediumseagreen-200" : ""
                        } hover:bg-gray-100 cursor-pointer whitespace-nowrap text-left`}
                        onClick={() => handleFilterOptionClick(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </div>
                </motion.div>
              )}

              </AnimatePresence>
              <AnimatePresence key="input-presence">
                {isInputFocused && (
                  <motion.div
                    key="input-dropdown"
                    ref={dropdownRef}
                    className={`absolute left-0 top-full bg-white ${isInputFocused ? 'border-[1px] border-solid border-neutral-700' : ''} rounded-md z-10`}
                    style={{
                      width: inputContainerRef.current
                        ? inputContainerRef.current.clientWidth + 'px'
                        : '100%',
                      boxSizing: 'border-box',
                      ...(isInputFocused ? { border: '1px solid #CBD5E0' } : {}),
                      maxHeight: '170px',
                      overflowY: 'scroll',
                    }}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    {props.searchInput
                      ? props.suggestedTags.map((tag) => (
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            key={tag}
                            onClick={() => {
                              setIsInputFocused(false);
                              setSelectedTags((prevSelectedTags) => {
                                if (prevSelectedTags.includes(tag)) {
                                  return prevSelectedTags.filter((t) => t !== tag);
                                } else {
                                  return [...prevSelectedTags, tag];
                                }
                              });
                              props.onTagSelect(tag);
                              props.onSearch('');
                            }}
                          >
                            {renderTag(tag, null, true)}
                          </div>
                        ))
                      : allTags.map((tag) => (
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            key={tag}
                            onClick={() => {
                              setIsInputFocused(false);
                              setSelectedTags((prevSelectedTags) => {
                                if (prevSelectedTags.includes(tag)) {
                                  return prevSelectedTags.filter((t) => t !== tag);
                                } else {
                                  return [...prevSelectedTags, tag];
                                }
                              });
                              props.onTagSelect(tag);
                              props.onSearch('');
                            }}
                          >
                            {renderTag(tag, null, true)}
                          </div>
                        ))}
                  </motion.div>
                )}
              </AnimatePresence>
                </AnimatePresence>
        </div>
        <AutoApplyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        tags={allTags}
        onSelectTags={(selectedTags) => {
          setSelectedTags(selectedTags);
        }}
        showUpgrade={() => {
          setIsModalOpen(false);
          setUpgradeFeatureModal(true);
        }}
      />
    </div>
    </>
  );
};

export default JobBar;
