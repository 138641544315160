import { useCallback, useState, useEffect, useMemo, useRef } from "react";
import React from "react";
import ContainerButton from "../components/ContainerButton";
import ApplicationModal from "./ApplicationModal";
import "whatwg-fetch";
import { db, storage } from "../firebase";
import { onSnapshot } from "firebase/firestore";
import Rive from "rive-react";
import CheckboxGroup from "./CheckBoxGroup";
import DropdownInput from "./DropdownInput";
import FormInputCard from "./FormInputCard";
import RadioInputCard from "./RadioInputCard";
import YesNoToggle from "./YesNoToggle";
import FileUpload from "./FileUpload";
import { useUserData } from "../UserDataContext";
import { useJobs } from "../jobsContext";
import AppliedFormContainer from "./AppliedFormContainer"
import { collection, query, orderBy, limit, getDocs, startAfter, getDoc, doc, setDoc } from "firebase/firestore"; // Import required Firestore functions

const AppliedPanel = ({title, posted, expiry, timezone, category, link, guid }) => {
  const {user, isAuthenticated} = useUserData();
  const [userData, setUserData] = useState({});
  const [applied, setApplied] = useState(false);
  const [hashedguid, setHashedguid] = useState("");
  const [fetched, setFetched] = useState(false);  
  const [formInfo, setFormInfo] = useState([]);
  const [inputValues, setInputValues] = useState([]);
  const {filteredJobs, appliedJobs} = useJobs();

  useEffect(() => {
    const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
    setHashedguid(hashCode(guid).toString());
    //console.log(appliedJobs[hashCode(guid).toString()].inputs);
    if(appliedJobs[hashCode(guid).toString()]){
    //if(appliedJobs["-1440700226"]){
      setFormInfo(appliedJobs[hashCode(guid).toString()].inputs)
    }
  }, [guid])
  useEffect(() => {
    if (!isAuthenticated || !user) return;
  
    const userId = user.uid;
  
    const docRef = doc(db, "Profiles", userId);
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (!data.applied) {
          data.applied = [];
        }
        setUserData(data);
      }
    });
  
    return () => unsubscribe();
  }, [isAuthenticated, user]);
  /*useEffect(() => {
   //console.log(filteredJobs)
    //if(guid && hashedguid == ""){
      const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
      setHashedguid(hashCode(guid).toString());
      setFormInfo(userData[hashCode(guid).toString()]);
      setFetched(true);
    //}
  })*/
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };
  
    

   const tagColors = {
        engineering: { bgColor: "bg-green-50", textColor: "text-green-600" },
        sales: { bgColor: "bg-blue-50", textColor: "text-blue-600" },
        marketing: { bgColor: "bg-purple-50", textColor: "text-purple-600" },
        product: { bgColor: "bg-pink-50", textColor: "text-pink-600" },
        design: { bgColor: "bg-teal-50", textColor: "text-teal-600" },
        support: { bgColor: "bg-red-50", textColor: "text-red-600" },
        operations: { bgColor: "bg-orange-50", textColor: "text-orange-600" },
        data: { bgColor: "bg-indigo-50", textColor: "text-indigo-600" },
        finance: { bgColor: "bg-yellow-50", textColor: "text-yellow-600" },
        management: { bgColor: "bg-gray-100", textColor: "text-gray-600" },
    };

    const renderTag = (tag) => {
        const normalizedTag = tag.toLowerCase();
        const color = tagColors[normalizedTag] || { bgColor: "bg-gray-100", textColor: "text-gray-600" };

        return (
            <div
                className={`rounded-8xs ${color.bgColor} ${color.textColor} flex flex-row py-0.5 px-2 items-center justify-start gap-[4px]`}
            >
                <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                    {tag.charAt(0).toUpperCase() + tag.slice(1)}
                </div>
            </div>
        );
    };
    
    
    /*useEffect(() => {
      const fetchFormInfo = () => {
        setFormInfo(userData[hashedguid]);
      };
      if(hashedguid != ""){
        fetchFormInfo();
      }
    }, [hashedguid]);*/
    
  
    const Loader = () => (
      <Rive src="/loadingwrangle.riv" className="min-w-[400px] min-h-[400px]"></Rive>
    );

    const renderInput = useMemo(() => (input, inputIdx) => {
      let newinp = input;
      if(!input){
        return
      }
      //console.log(input)
      newinp = input[1];
      const inputName = newinp.label;
      const inputType = input.type;
      const inputTag = newinp.tag;
      let inputVal = newinp.value;
      const isRequired = true;
      const options = newinp.options?.map((option) => ({
        label: option.label || option.value,
        value: option.value,
      })) || [];
      let inputValue = inputValues[inputName] || "";
      // convert inputValue (label) to value (value)
      if(Array.isArray(inputValue)){
        inputValue.forEach((val, idx) => {
          const inputOptionValue = options?.find(({label}) => label === val || label.includes(val))?.value;
          if(inputOptionValue){
            inputValue[idx] = inputOptionValue;
          }
        });
      }else if(options && options.length) {
        const val = options.find(({label}) => label === inputValue || label.includes(inputValue))?.value;
        inputValue = val || inputValue;
      }
    
      inputValues[inputName] = inputValue; // update inputValues to have the value instead of label
    
      const titleText = isRequired ? `${inputName}` : inputName;
      if (!inputName) {
    
        return (
          <CheckboxGroup
            key={inputIdx}
            label={"Unknown label."}
            options={options}
            required={isRequired}
            onChange={() => {}}
            applied={true}
          />
        );
      }
    
      switch(inputTag) {
        default:
          return inputValue || isRequired ? (
            <AppliedFormContainer
              key={inputIdx}
              titleText={titleText}
              inputText={inputVal}
              value={inputValue}
              onChange={() => {}}
              applied={true}
            />
          ) : null;
    }})
    
    const displayInput = useMemo(() => (input, inputIdx) => {
      return(
        <div>
          {renderInput(input, inputIdx)}
        </div>
      )
    })
    useEffect(() => {
      //console.log(filteredJobs)
      //console.log(Object.entries(formInfo)[0])
    })
    return (
        <div className="relative self-stretch flex-1 rounded-3xs bg-base-white flex flex-row p-8 pr-1 items-start justify-start border-[1px] border-solid border-neutral-600">
          <div className="self-stretch flex-1 flex flex-col items-start justify-between">
            <div className="self-stretch flex flex-col items-start justify-start gap-[32px]">
              <div className="relative tracking-[-0.02em] leading-[40px] font-semibold text-xl whitespace-nowrap overflow-hidden">
                {(title && title.length >45) ? (title.substring(0,45) + "...") : title}
                
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-lg">
                <div className="flex-1 flex flex-col items-start justify-start">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                    <div className="self-stretch relative tracking-[-0.1px] leading-[28px] font-semibold">
                      Your Responses
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-24 pb-10">
                {(formInfo) ? (
                <div className="h-[67vh] overflow-y-auto input-container flex flex-col items-start overflow-x-hidden border-2 border-solid border-gray-100 p-3 mb-12 rounded-lg text-sm m-0 w-[53vh]">
                    {
                      Object.entries(formInfo)
                        .map((input, inputIdx) => displayInput(input, inputIdx))
                        //.filter((input) => input !== null)
                        .map((input, inputIdx) => (
                          <React.Fragment key={`input-${inputIdx}`}>
                            {input}
                          </React.Fragment>
                        ))
                    }
                  </div>
                  ) : (
                    <div className="flex items-center justify-center min-h-[400px] min-w-[100%]">
                      <Loader />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
    );
};

export default AppliedPanel
