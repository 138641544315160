import React from "react";
import { motion, AnimatePresence } from "framer-motion";

const Banner = ({ startExitAnimation, bannerMessage, bannerType }) => {
    const fadeInVariants = {
        hidden: { opacity: 0, y: "-10%" },
        visible: {
          opacity: 1,
          y: "0%",
          transition: {
            duration: 0.7,
            ease: [0.16, 1, 0.3, 1], // cubic-bezier curve for easeInOut
          },
        },
        exit: {
          opacity: 0,
          y: "-10%",
          transition: {
            duration: 0.7,
            ease: [0.16, 1, 0.3, 1], // cubic-bezier curve for easeInOut
          },
        },
      };

  return (
    <AnimatePresence>
      {!startExitAnimation && (
        <motion.div
          key="banner"
          className="fixed top-1/10 inset-x-0 z-10"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={fadeInVariants}
        >
      <div className="relative w-full max-w-[800px] mx-auto text-left text-sm text-error-700 font-text-sm-semibold">
        <div className="rounded-lg bg-error-25 box-border w-full flex flex-row p-4 items-start justify-start gap-[12px] border-[1px] border-solid border-error-300">
          <div className="flex-1 flex flex-col items-start justify-start gap-[12px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
              <div className="self-stretch relative leading-[20px] font-semibold">
                {bannerMessage}
              </div>
              <div className="self-stretch relative leading-[20px]">
                Whoops, looks like something went wrong with your profile. It's hard to submit
                applications without the important information, please complete your profile!
              </div>
            </div>
          </div>
          <div className="relative w-5 h-5">
            <div className="absolute top-[-8px] left-[-8px] rounded-lg overflow-hidden flex flex-row p-2 items-center justify-center">
            </div>
          </div>
          </div>
      </div>
      </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Banner;