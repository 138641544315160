import React from 'react';

const SkeletonJob = () => {
  return (
    <div className="min-h-[90px] self-stretch rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-col py-3 px-0 items-start justify-center text-left text-lg text-gray-700 font-text-l-regular border-[1px] border-solid border-neutral-600 cursor-pointer transform-gpu transition-all duration-150">
      <div className="self-stretch flex flex-col py-0 px-5 items-start justify-start">
        <div className="self-stretch flex flex-col items-start justify-start gap-[8px]">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="w-40 tracking-[-0.1px] leading-[28px] font-semibold bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient self-stretch h-4 rounded"></div>
            <div className="relative text-sm tracking-[-0.1px] leading-[28px] font-semibold text-mediumseagreen-200 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-4 rounded"></div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[16px] text-sm text-gray-50">
            <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-4 rounded"></div>
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-4 rounded"></div>
            </div>
            <div className="flex flex-row items-center justify-start gap-[4px]">
              <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-20 h-4 rounded"></div>
            </div>
          </div>
          <div className="self-stretch flex flex-row py-1 px-0 items-center justify-start gap-[16px] text-smi">
            <div className="bg-gradient-to-r from-gray-100 via-gray-200 to-gray-100 animate-skeleton-gradient w-24 h-4 rounded"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonJob;
