import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import SideMenuGeneral from "../components/SideMenuGeneral";
import InputCard from "../components/InputCard";
import styles from "../components/Loader.module.css";
import ConnectedAccountsContainer from "../components/ConnectedAccountsContainer";
import { collection, addDoc, doc, setDoc, getDoc, updateDoc} from "firebase/firestore"; 
import {ref, uploadBytesResumable, deleteObject, listAll} from "firebase/storage";
import { db, storage } from "../firebase";
import AddNewAccountModal from "../components/AddNewAccountModal";
import ConfirmModal from "../components/ConfirmModal";
import { useUserData } from "../UserDataContext";
import Rive from "rive-react";
import PlacesAutocomplete from 'react-places-autocomplete';
import PlacesInputCard from "../components/PlacesInputCard";



const Dashboard = () => {
  const { user, userData, setUserData, isAuthenticated, isLoading } = useUserData();
  const [filename, setFilename] = useState("");
  const fileInputRef = useRef(null)
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const docRef = doc(db, "Profiles", (isAuthenticated && user.uid));
  const handleSelect = fulladdress => {
    // Do something with the selected address
    updateDoc(docRef, { address: fulladdress }, { merge: true });
    setUserData({ ...userData, address: fulladdress });
  };
  useEffect(() => {
    if(userData.filename){
      setFilename(userData.filename);
    }
  }, [userData.filename]);
  const testFileSize = (file) => {
    if(Math.round(file.size/1000000) > 10){
      alert('file too big')
    }
    else{
      const allowedExtensions = /(\.txt|\.pdf)$/i
      if(!allowedExtensions.exec(file.name)) {
        alert('Invalid file type');
      }
      else {
        const dbRef = ref(storage, String(user.uid));
        listAll(dbRef)
          .then((res) => {
            res.items.forEach((itemRef) => {
              // All the items under listRef.
              deleteObject(itemRef).then(() => {
            }).catch((error) => {
               //console.log("error deleting")
              });
            });
          }).catch((error) => {
           //console.log("error")
          });
        const storageRef = ref(storage, String(user.uid) + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on('state_changed', 
          (snapshot) => {
            setFilename("Uploading... " + String(Math.floor((snapshot.bytesTransferred/snapshot.totalBytes)*100))+ "%");
          }, 
          (error) => {
            console.error("Upload failed", error);
          }, 
          () => {
            // Upload completed successfully, now we can get the download URL
            setFilename(file.name);
            updateDoc(docRef, { filename: file.name }, { merge: true });
            setUserData({...userData, filename: file.name})
          }
        );
      }
    } 
  }
  const handleChooseFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      const fileInput = document.createElement("input");
      fileInput.setAttribute("type", "file");
      fileInput.setAttribute("id", "fileInput");
      fileInput.setAttribute("style", "display: none");
      fileInput.addEventListener("change", (e) => {
        const file = e.target.files[0];
        //25 MB max
        const fileNameInput = document.querySelector('#fileNameInput');
        fileNameInput.value = file.name;
      });
      document.body.appendChild(fileInput);
      fileInput.click();
    }
  };

  const accounts = {
    linkedin: ["LinkedIn", "/linkedin.svg"],
    figma: ["Figma", "/figma.svg"],
    github: ["Github", "/github.svg"],
    dribbble: ["Dribbble", "/dribbble.svg"],
    stackoverflow: ["StackOverflow", "/stack.svg"],
    twitter: ["twitter", "/rg.svg"],
  };

  const Loader = () => (
    <Rive src="/loadingwrangle.riv" className="w-screen h-screen]"></Rive>
  );
  if(isLoading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-9xl text-gray-700 font-text-l-regular">
      <div className="self-stretch flex-1 flex flex-col p-8 items-start justify-start gap-[48px]">
        <div className="w-full flex flex-col items-start justify-start">
          <div className="self-stretch relative tracking-[-0.01em] leading-[38px] font-semibold mb-2">
            Profile
          </div>
          <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50">
            Here’s where you can update your information, connect accounts, and
            specify topics for prompts.
          </div>
        </div>
        <div className="self-stretch flex flex flex-col items-start justify-start text-sm">
          <div className="self-stretch flex-1 rounded-lg flex flex-col items-start justify-start gap-[48px]">
            <div className="self-stretch flex flex-row items-start justify-start gap-[64px]">
            <InputCard
              regex = {new RegExp("^[a-zA-Z]+[-' ]+[a-zA-Z]+$")}
              maxlen="30"
              required={true}
              titleText="Full Name"
              inputText="Name"
              value={userData.name}
              onChange={(value) => {
                updateDoc(docRef, { name: value }, { merge: true });
                setUserData({...userData, name: value}); // set the name value in the user data object
              }}
            />
            <InputCard
              regex={new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])")}
              maxlen="40"
              titleText="Email"
              inputText="Email"
              value={userData.email}
              required={true}
              onChange={(value) => {
                updateDoc(docRef, { email: value }, { merge: true });
                setUserData({ ...userData, email: value }); // set the email value in the user data object
              }}
            />
            <InputCard
              regex = {new RegExp("^(?=([+()\\d\\s-]*\\d){10,})[+\\d()\\s-]*$")}
              maxlen="17"
              titleText="Phone"
              inputText="Number"
              value={userData.phone}
              required={true}
              onChange={(value) => {
                updateDoc(docRef, { phone: value }, { merge: true });
                setUserData({ ...userData, phone: value }); // set the phone value in the user data object
              }}
            />
            </div>
      
            <div className="self-stretch flex flex-row items-start justify-start gap-[64px]">
            <PlacesInputCard
              maxlen="50"
              titleText="Address"
              inputText="City, State, Postal Code"
              value={userData.address}
              required={true}
              onSelect={handleSelect}
              onChange={(value) => {
                updateDoc(docRef, { address: value }, { merge: true });
                setUserData({ ...userData, address: value});
                }
              }
            />
              <InputCard
                regex = {new RegExp(".*")}
                maxlen="40"
                titleText="Portfolio"
                inputText="Personal site, Github, etc."
                value={userData.portfolio}
                onChange={(value) => {
                  updateDoc(docRef, { portfolio: value }, { merge: true });
                  setUserData({ ...userData, portfolio: value }); // set the portfolio value in the user data object
                }}
              />
              <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
                  <span>{`Resume `}</span> <span style={{color: "red"}}>*</span>
                </div>
                <div className="self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-start justify-end">
                <input
                  type="file"
                  id="fileInput"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => {
                    const file = e.target.files[0];
                    testFileSize(file);
                  }}
                />
                  <div className="self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-start justify-between flex-1">
                    <input
                      className="font-text-l-regular text-mini bg-base-white flex-1 rounded-tl-md rounded-tr-none rounded-br-none rounded-bl-md box-border h-[46px] overflow-hidden flex flex-row py-[11px] px-4 items-center justify-start border-[1px] border-solid border-neutral-700 select-none"
                      placeholder="No file chosen"
                      value={filename || ''}
                      readOnly
                    />
                    <button
                      className="cursor-pointer [border:none] py-3 px-[18px] bg-neutral-100 rounded-tl-none rounded-tr-md rounded-br-md rounded-bl-none overflow-hidden flex flex-row items-center justify-between gap-[6px] border-t-[1px] border-solid border-neutral-700 border-r-[1px] border-b-[1px]"
                      onClick={handleChooseFile}
                    >
                      <img
                        className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
                        alt=""
                        src="/placeholder2.svg"
                      />
                      <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-gray-700 text-left">
                        Upload
                      </div>
                      <img
                        className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
                        alt=""
                        src="/placeholder3.svg"
                      />
                    </button>
                  </div>
                </div>
                <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
                  Hint text
                </div>
              </div>
            </div>
            <div className="self-stretch h-[394px] shrink-0 flex flex-row items-start justify-start gap-[64px]">
              <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px] pb-4">
                <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
                  Key information (unique skills, awards, or anything else you
                  want mentioned in your applications)
                </div>
                <textarea
                  maxLength="2000"
                  className="bg-base-white focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 font-text-l-regular text-mini self-stretch flex-1 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex flex-row py-3 px-4 items-start justify-start border-[1px] border-solid border-neutral-700 resize-none"
                  placeholder={`Feel free to mention anything you think might be applicable. The more information you enter helps the AI specialize your applications and have a higher success rate, so say as much as you want!`}
                  value={userData.keyInfo}
                  onChange={(e) => {
                    const val = e.target.value;
                    updateDoc(docRef, { keyInfo: val }, { merge: true });
                    setUserData({...userData, keyInfo: val});
                  }
                    
                  }
                />
                <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
                  Hint text
                </div>
                <div className="[border:none] py-4 self-stretch bg-transparent rounded-t-none rounded-b-3xs flex flex-row items-center justify-start gap-[12px]">
                    <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                      <img
                        className="relative w-[18px] h-[18px] shrink-0 hidden"
                        alt=""
                        src="/add-icon.svg"
                      />
                      <div className="relative text-sm leading-[20px] font-semibold font-text-l-regular text-primary-600 text-left">
                        Autosaving On
                      </div>
                    </div>
              </div>
              </div>
              <div className="self-stretch rounded-3xs w-[352px] h-[378px] shrink-0 overflow-hidden flex flex-col items-center justify-start gap-[8px]">
                <div className="self-stretch relative text-sm tracking-[-0.1px] leading-[20px] font-medium font-text-l-regular text-gray-700 text-left">
                  External Accounts (Github, LinkedIn, etc.)
                </div>
                <div className="overflow-y-auto shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] self-stretch flex-1 rounded-3xs bg-base-white flex flex-col items-center justify-start border-[1px] border-solid border-neutral-700">
                  <div className="self-stretch flex-1 flex flex-col py-0 px-4 items-start justify-start width-full">
                  {Object.keys(accounts).map((key) => {
                    if (userData[key]) {
                      return (
                        <ConnectedAccountsContainer
                          key={key}
                          socialMediaHandle={accounts[key][0]}
                          acctHandle={userData[key]}
                          propBackgroundImage={`url("${accounts[key][1]}")`}
                          visible={true}
                          setInvisible={() => {
                            setUserData({ ...userData, [key]: null });
                          }}
                        />
                      );
                    }
                    return null;
                  })}
                  </div>
                </div>
                  <button onClick={() => setShowAddNewModal(true)} className="cursor-pointer [border:none] py-4 px-5 self-stretch bg-transparent rounded-t-none rounded-b-3xs flex flex-row items-center justify-start gap-[12px]">
                    <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                      <img
                        className="relative w-[18px] h-[18px] shrink-0 hidden"
                        alt=""
                        src="/add-icon.svg"
                      />
                      <div className="relative text-sm leading-[20px] font-semibold font-text-l-regular text-primary-600 text-left">
                        Add New
                      </div>
                      <img
                        className="relative w-[18px] h-[18px] shrink-0 overflow-hidden"
                        alt=""
                        src="/plus.svg"
                      />
                    </div>
                  </button>
                  
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <AddNewAccountModal
        show={showAddNewModal}
        onClose={() => setShowAddNewModal(false)}
      />
    </div>
  );
};

export default Dashboard;