import { useJobs } from '../jobsContext';
import React from 'react';
import OpportunitiesContainer from "../components/OpportunitiesContainer";
import JobPanel from "../components/JobPanel";
import SkeletonJobPanel from "../components/SkeletonPanel";


const Opportunities = () => {
  const { selectedJob } = useJobs();
  
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };


  return (
    <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
      <div className="self-stretch flex-1 overflow-hidden flex flex-row p-8 items-start justify-start gap-[32px] text-9xl text-gray-700">
        <OpportunitiesContainer 
          formatDate={formatDate} 
        />
        {selectedJob ? (
          <JobPanel
          />
        ) : (
          <SkeletonJobPanel />
        )}
      </div>
    </div>
  );
};

export default React.memo(Opportunities);
