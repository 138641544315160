import { useState, useEffect, useRef, useMemo, useCallback, useContext } from 'react';
import AppliedJobBar from './AppliedJobBar';
import JobContainer from './JobContainer';
import SkeletonJob from './SkeletonJob';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, addDoc, doc, getDoc, query, orderBy, limit, getDocs, startAfter} from "firebase/firestore"; 
import { db, storage } from "../firebase";
import { onSnapshot } from "firebase/firestore";
import { useJobs } from '../jobsContext';
import { useUserData } from '../UserDataContext';


const AppliedContainer = props => {
  const { jobs, setJobs, loading, selectedJob, setSelectedJob, setScreen, appliedJobs, jobslist } = useJobs();
  const [showTags, setShowTags] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [manualSelection, setManualSelection] = useState(false);
  const allTags = [...new Set(jobs.flatMap((job) => job.categories))];
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const daysUntil = (expiry, pub) => Math.floor((new Date(expiry) - new Date(pub)) / MS_PER_DAY);
  const [loadingMoreJobs, setLoadingMoreJobs] = useState(false);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    setFlag(true);
  }, [])
  useEffect(() => {
    if(flag && jobslist && appliedJobs){
     //console.log(jobslist[Object.keys(appliedJobs)[0]])
      setFlag(false);
      setSelectedJob(jobslist[Object.keys(appliedJobs)[0]]);
    }
  }, [flag, jobslist, appliedJobs]);
  
  const getPubDateColor = (expiryDate, pubDate) => {
    const days = daysUntil(expiryDate, pubDate);
    if (days < 10) return 'yellow';
    if (days < 20) return 'orange';
    return 'gray';
  };
  


  const findClosestTags = (searchInput) => {
    if (!searchInput) return [];
  
    const searchInputLower = searchInput.toLowerCase();
    const closestTags = allTags.filter((tag) =>
      tag.toLowerCase().includes(searchInputLower)
    );
  
    return closestTags.slice(0, 5);
  };
  
  const suggestedTags = findClosestTags(searchInput);

  return (
    <div className="w-1/2 shrink-0 flex flex-col items-start justify-start gap-[32px] text-left text-lg text-gray-700 font-text-l-regular overflow-y-auto h-screen">
      <AppliedJobBar
        openTags={() => setShowTags(true)}
        onSearch={setSearchInput}
        searchInput={searchInput}
        suggestedTags={suggestedTags}
        onTagSelect={(tag) => {
          setSearchInput('');
          setSelectedTags([...selectedTags, tag]);
        }}
        onTagRemove={(tagToRemove) => {
          setSelectedTags(selectedTags.filter((tag) => tag !== tagToRemove));
        }}
      />
            <div className="space-y-8 flex-grow overflow-y-auto w-full mb-16">
            {(loading || loadingMoreJobs) ? (
              Array(20)
                .fill(null)
                .map((_, index) => (
                  <motion.div
                    key={index}
                    initial={{ scale: 1 }}
                    animate={{ scale: [1, 0.95, 1] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      repeatType: 'loop',
                      delay: index * 0.1,
                    }}
                  >
                    <SkeletonJob />
                  </motion.div>
                ))
      ) : (
        
        <AnimatePresence key={jobslist && jobslist.length}>
        {jobslist && 
        Object.entries(jobslist)
        .sort((a, b) => new Date(appliedJobs[b[0]].submittedAt) - new Date(appliedJobs[a[0]].submittedAt))
        .map(([key, job], index) => (
          <motion.div
          key={job.guid}
          initial={{ opacity: 0, y: -5, scale: .9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: -5, scale: .9 }}
          transition={{ duration: 0.4, delay: index < 10 ? index * 0.075 : 10 * 0.075 }}
        >        
            <JobContainer
              onSelect={() => {
                setManualSelection(true);
                setSelectedJob(job); // Use setSelectedJob from context here
              }}
              position={job.title}
              pubDate={props.formatDate(job.pubDate)}
              pubDateColor={getPubDateColor(job.expiryDate, job.pubDate)}
              company={job.companyName}
              location={job.locationRestrictions ? job.locationRestrictions.join(', ') : 'Remote'}
              tags={job.categories}
              selected={selectedJob && job.guid === selectedJob.guid}// Compare job to selectedJob from context here
              guid={job.guid}
              applied={true}
            />
          </motion.div>
        ))
        }
        {jobslist && jobslist.length > 0 && (
          <div className="last-job h-1px">
          </div>
        )}
      </AnimatePresence>
      )}
      </div>
    </div>
  );
};

export default React.memo(AppliedContainer);