import React, { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { useUserData } from "../UserDataContext";
import SkeletonPlanLoader from "./SkeletonPlanLoader";
import PlanStatusSection from "./PlanStatusSection";
import SideMenuItem from "./SideMenuItem";
import UpgradeModal from "./UpgradeModal";
import SignOutButton from "./SignOutButton";
import { collection, addDoc, doc, setDoc, getDoc, increment} from "firebase/firestore"; 
import { db, storage } from "../firebase";
import { usePayment } from "../PaymentContext"
import { useLocation } from 'react-router-dom';
import { useJobs } from "../jobsContext";


const SideMenuGeneral = (props) => {
  const {setScreen, setShowFavoritesOnly, setShowRemoteOnly, setShowUSOnly, appliedJobs} = useJobs();
  const navigate = useNavigate();
  const {userData, isAuthenticated, user, setUserData, currentLink} = useUserData();
  const {openUpgrade, currentPlan, appsUsed} = usePayment();


  const [transparentStates, setTransparentStates] = useState({
    dashboard: "rgba(230, 251, 240, 0)",
    opportunities: "rgba(243, 249, 254, 0)",
    favorites: "rgba(243, 249, 254, 0)",
    applied: "rgba(243, 249, 254, 0)",
  });

  const [planData, setPlanData] = useState({
    used: -1,
    count: 0,
    name: "",
    barWidth: 0,
  });

  const currentPath = window.location.href.split("/").slice(-1)[0];
  useEffect(() => {
    updateCounter();
    updateTransparentStates();
  }, [currentPlan, appsUsed, currentPath]);

  const handleSignOut = async () => {
    await signOut(auth);
    window.location.href = `${currentLink}/login`;
  };

  const updateCounter = () => {
    if (!userData) return;

    let count, name;
    switch (currentPlan) {
      case 2:
        count = 150;
        name = "Boosted";
        break;
      case 3:
        count = 500;
        name = "Premium";
        break;
      default:
        count = 50;
        name = "Free";
    }

    setPlanData((prev) => ({
      ...prev,
      used: appsUsed,
      count,
      name,
      barWidth: (appsUsed / count) * 100,
    }));
  };

  const updateTransparentStates = () => {
    setTransparentStates((prev) => ({
      ...prev,
      dashboard: currentPath === "dashboard" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
      opportunities: currentPath === "opportunities" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
      applied: currentPath === "applied" ? "rgba(230, 251, 240, 1)" : "rgba(243, 249, 254, 0)",
    }));
  };

  const handleNavigation = (path) => {
    setShowUSOnly(false);
    setShowFavoritesOnly(false);
    setShowRemoteOnly(false);
    navigate(path);
  };



  return (
    <div className="self-stretch bg-base-white box-border w-[280px] shrink-0 overflow-hidden flex flex-col pt-4 px-0 pb-6 items-center justify-start gap-[16px] text-left text-base text-gray-50 font-text-l-regular border-r-[1px] border-solid border-neutral-600">
      <div className="self-stretch flex flex-col py-0 px-7 items-start justify-start">
        <img
          className="self-stretch relative max-w-full overflow-hidden h-14 shrink-0"
          alt=""
          src="/company-logo.svg"
        />
      </div>
      <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[8px]">
        <div className="self-stretch h-11 shrink-0 flex flex-row py-0 px-7 box-border items-center justify-start gap-[6px] mt-6">
          <img
            className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
            alt=""
            src="/placeholder.svg"
          />
          <div className="flex-1 relative tracking-[-0.1px] leading-[24px] font-medium">
            Application
          </div>
          <img className="relative w-6 h-6 shrink-0 hidden" alt="" />
        </div>
        <SideMenuItem
          icon="/home.svg"
          label="Profile"
          onClick={() => handleNavigation("/dashboard")}
          isActive={currentPath === "/dashboard"}
          backgroundColor={transparentStates.dashboard}
        />
        <div className="self-stretch h-11 shrink-0 flex flex-row py-0 px-7 box-border items-center justify-start gap-[12px] mt-6">
          <img
            className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
            alt=""
            src="/placeholder1.svg"
          />
          <div className="flex-1 relative tracking-[-0.1px] leading-[24px] font-medium">
            Jobs
          </div>
          <img className="relative w-6 h-6 shrink-0 hidden" alt="" />
        </div>
        <SideMenuItem
          icon="/earth.svg"
          label="Opportunities"
          onClick={() => {
          handleNavigation("/opportunities")
          setScreen("opportunities")}}
          isActive={currentPath === "/opportunities"}
          backgroundColor={transparentStates.opportunities}
        />
        <SideMenuItem
          icon="/check-done-02.svg"
          label="Applied"
          onClick={() => {
            handleNavigation("/applied")
            setScreen("applied")}}
          isActive={currentPath === "/applied"}
          backgroundColor={transparentStates.applied}
        />
      </div>
      <PlanStatusSection
        planName={planData.name}
        planUsed={planData.used}
        planCount={planData.count}
        barWidth={planData.barWidth}
        showUpgradeModal={() => openUpgrade()}
      />
      <SignOutButton onClick={handleSignOut} />
    </div>
  );
  
  
};

export default SideMenuGeneral;
