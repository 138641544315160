import React from "react";
import SkeletonPlanLoader from "./SkeletonPlanLoader";

const PlanStatusSection = ({ planName, planUsed, planCount, barWidth, showUpgradeModal }) => {
  return (
    <div className="self-stretch overflow-hidden flex flex-col p-6 px-8 items-center gap-[16px] text-[22px] text-gray-700">
      <div className="self-stretch overflow-hidden flex flex-col py-[11px] px-0 items-start justify-start gap-[6px]">
        <div className="flex flex-col items-start justify-start gap-[12px]">
          {(planUsed === undefined) ? <SkeletonPlanLoader /> :
            <div>
              <div className="relative leading-[22px] text-small font-semibold font-text-l-regular text-gray-50">
                {planName} Plan
              </div>
              <div className="relative text-mini leading-[22px] font-semibold text-gray-50">
                {planUsed}/{planCount} Applications Used
              </div>
            </div>
          }
        </div>
        <div className="relative w-[232px] h-[11px]">
          <div className="absolute top-[0px] left-[0px] rounded-xl bg-gray-100 w-[214px] h-[11px]" />
          <div className={`absolute top-[0px] left-[0px] rounded-xl bg-warning-400 h-[11px]`} style={{ width: `${barWidth}%` }} />
        </div>
      </div>
      <button onClick={() => showUpgradeModal(1)} className="cursor-pointer [border:none] py-3 px-[18px] bg-mediumseagreen-200 self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[6px] hover:bg-green-300 transition-colors duration-300">
        <img
          className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
          alt=""
          src="/placeholder1.svg"
        />
        <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular text-base-white text-left">
          Upgrade Now
        </div>
        <img
          className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
          alt=""
          src="/placeholder1.svg"
        />
      </button>
    </div>
  );
};

export default PlanStatusSection;
