import React from 'react';

const CheckboxGroup = ({ label, options, required, onChange, initialChecked }) => {
  const handleChange = (event) => {
    const { name, checked } = event.target;
    onChange(name, checked);
  };

  const values = options.map(({value}) => value) || [];
  if(values.length !== new Set(values).size){
    // TODO: catch and handle this error... unsure where it came up so for now just logging...
    console.error("Duplicate values in checkbox group", values);
    return null;
  }


  return (
    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] text-left text-sm text-gray-700 font-text-l-regular">
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
        <span>
          {label}
          {required}
        </span>
      </div>
      {options.map((option) => (
        <div key={option.value} className="flex items-center space-x-2">
          <input
            className="rounded border-[1px] border-solid border-neutral-700 focus:ring-2 focus:ring-blue-300 focus:border-transparent focus:outline-none"
            type="checkbox"
            id={option.value}
            name={option.value}
            onChange={handleChange}
            checked={initialChecked && initialChecked.includes(option.value)}
          />
          <label
            className="font-text-l-regular text-mini text-gray-700"
            htmlFor={option.value}
          >
            {option.label}
          </label>
        </div>
      ))}
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
        Hint text
      </div>
    </div>
  );
};

export default CheckboxGroup;