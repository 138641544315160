import React from "react";

const SideMenuItem = ({ icon, label, onClick, isActive, backgroundColor }) => {
    return (
      <button
        onClick={onClick}
        className={`cursor-pointer [border:none] py-0 px-7 bg-[transparent] self-stretch h-[46px] shrink-0 flex flex-row box-border items-center justify-start gap-[12px] rounded-none`}
        style={{ backgroundColor }}
      >
        <img className="relative w-[22px] h-[22px] shrink-0 overflow-hidden z-[0]" alt="" src={icon} />
        <div className={`flex-1 relative text-mini tracking-[-0.1px] leading-[22px] bg-[transparent] font-semibold font-text-l-regular text-left z-[1] ${isActive ? "text-primary-600" : "text-gray-50"}`}>
          {label}
        </div>
        <img className="relative w-6 h-6 shrink-0 overflow-hidden hidden z-[3]" alt="" src="/chevrondown.svg" />
      </button>
    );
  };

export default SideMenuItem;