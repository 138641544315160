const InputCard = ({ titleText, inputText, value, required, onChange, maxlen, regex }) => {
  return (
    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] text-left text-sm text-gray-700 font-text-l-regular">
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
        <span>{titleText + " "}</span>
        {required ? <span
        style={{color: "red"}}
        >*</span> : null}
      </div>
      <input
        className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700"
        type="text"
        placeholder={inputText}
        required
        value={value}
        onChange={(e) => onChange(e.target.value)}
        maxLength={maxlen}
      />
    </div>
  );
};
export default InputCard;