import { useState, useEffect, useRef, useMemo, useCallback, useContext } from 'react';
import JobBar from './JobBar';
import JobContainer from './JobContainer';
import SkeletonJob from './SkeletonJob';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useJobs } from '../jobsContext';
import { useUserData } from '../UserDataContext';

const OpportunitiesContainer = props => {
  const { jobs, setJobs, loading, fetchMoreJobs, lastVisibleDoc, selectedJob, setSelectedJob, showFavoritesOnly, setShowFavoritesOnly, showUSOnly, setShowUSOnly, showRemoteOnly, setShowRemoteOnly, filteredJobs, selectedTags, setSelectedTags, setScreen  } = useJobs();
  const [showTags, setShowTags] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [lastFilteredDoc, setLastFilteredDoc] = useState(null);
  const allTags = [...new Set(jobs.flatMap((job) => job.categories))];
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  const daysUntil = (expiry, pub) => Math.floor((new Date(expiry) - new Date(pub)) / MS_PER_DAY);
  const {userData, setUserData} = useUserData();
  const [loadingMoreJobs, setLoadingMoreJobs] = useState(false);

  const observer = useRef();

  useEffect(() => {
    const lastJobNode = document.querySelector(".last-job");
    if (lastJobNode) observer.current.observe(lastJobNode);
  
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  const lastJobElementRef = useCallback(
    (node) => {
      if (loading || loadingMoreJobs) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          jobs
            .filter((job) => (!showFavoritesOnly || userData.favorites.includes(job.guid)))
            .filter((job) => !userData.applied.includes(job.guid))
            .length < jobs.length
        ) {
          //fetchMoreJobs();
        }
      });
  
      if (node) observer.current.observe(node);
    },
    [loading, jobs, fetchMoreJobs]
  );
  

  useEffect(() => {
    if (loading || loadingMoreJobs) return;
    if (!filteredJobs.length) return;
  
    const lastJobNode = document.querySelector(".last-job");
  
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          jobs
            .filter((job) => (!showFavoritesOnly || userData.favorites.some(favorite => favorite.guid === job.guid)))
            .filter((job) => !userData.applied.includes(job.guid)) // Filter out applied jobs
            .filter((job) =>
              selectedTags.length
                ? selectedTags.every((tag) => (job.categories || []).includes(tag))
                : true
            ).length > 6
        ) {
          //fetchMoreJobs();
        }
      },
      {
        threshold: 1.0,
      }
    );
    if (lastJobNode) observer.current.observe(lastJobNode);
  
    // Disable IntersectionObserver if all jobs have been loaded
    if (lastFilteredDoc && lastVisibleDoc && lastFilteredDoc.id === lastVisibleDoc.id) {
      observer.current.disconnect();
    }
  
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [lastFilteredDoc, lastVisibleDoc, jobs, filteredJobs, loading, loadingMoreJobs]);
  
  
  

  return (
    <div className="w-1/2 shrink-0 flex flex-col items-start justify-start gap-[32px] text-left text-lg text-gray-700 font-text-l-regular overflow-y-auto h-screen">
      <JobBar
        openTags={() => setShowTags(true)}
        onSearch={setSearchInput}
        searchInput={searchInput}
        onTagSelect={(tag) => {
          setSearchInput('');
          setSelectedTags([...selectedTags, tag]);
        }}
        onTagRemove={(tagToRemove) => {
          setSelectedTags(selectedTags.filter((tag) => tag !== tagToRemove));
        }}
        showFavoritesOnly={showFavoritesOnly}
        showUSOnly={showUSOnly}
        showRemoteOnly={showRemoteOnly}
        toggleShowFavoritesOnly={() => setShowFavoritesOnly(!showFavoritesOnly)}
      />
            <div className="space-y-8 flex-grow overflow-y-auto w-full mb-16">
        <AnimatePresence key={filteredJobs.length}>
        {filteredJobs.map((job, index) => (
          <motion.div
          key={job.guid}
          initial={{ opacity: 0, y: -5, scale: .9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: -5, scale: .9 }}
          transition={{ duration: 0.4, delay: index < 10 ? index * 0.075 : 10 * 0.075 }}
          ref={index === filteredJobs.length - 1 ? lastJobElementRef : null}
        >        
            <JobContainer
              onSelect={() => {
                setSelectedJob(job);
              }}
              position={job.title}
              pubDate={props.formatDate(job.pubDate)}
              company={job.companyName}
              location={job.locationRestrictions ? job.locationRestrictions.join(', ') : 'Remote'}
              tags={job.categories}
              selected={selectedJob && selectedJob.guid === job.guid}
              guid={job.guid}
            />
          </motion.div>
        ))}
        {filteredJobs.length > 0 && (
          <div ref={lastJobElementRef} className="last-job h-1px">
          </div>
        )}
      </AnimatePresence>
      </div>
    </div>
  );
};

export default React.memo(OpportunitiesContainer);