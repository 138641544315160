import { useState, useEffect, useMemo } from "react";

const ConnectedAccountsContainer = ({
  socialMediaHandle,
  acctHandle,
  propBackgroundImage,
  visible,
  setInvisible,
}) => {

  const [transition, setTransition] = useState(false);

  const avatarStyle = useMemo(() => {
    return {
      backgroundImage: propBackgroundImage,
    };
  }, [propBackgroundImage]);

  const handleCrossClick = () => {
    setTransition(true);
    setTimeout(() => {
      setInvisible();
    }, 500);
  };

  const contentStyle = {
    position: "relative",
    zIndex: 1,
    pointerEvents: transition ? "none" : "auto",
    transition: "opacity 0.1s ease-in-out",
    opacity: transition ? 0 : 1,
  };

  return visible ? (
    <div className="self-stretch relative">
    <div className="flex flex-row py-4 px-0 items-center justify-between text-left text-mini text-gray-700 font-text-l-regular" style={contentStyle}>
      <div className="flex flex-row items-center justify-start gap-[24px] w-64">
        <div
          className="w-8 h-8 shrink-0 flex flex-row items-center justify-center relative bg-cover bg-no-repeat bg-[top]"
          style={avatarStyle}
        >
        </div>
        <div className="flex flex-col items-start justify-start">
          <div
            className="relative tracking-[-0.1px] leading-[22px] font-medium"
          >
            {socialMediaHandle}
          </div>
          <div
            className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50"
          >
            {acctHandle}
          </div>
        </div>
      </div>
        <button
          type="button"
          className="cursor-pointer [border:none] p-0 bg-[transparent] flex flex-row items-center justify-start"
          onClick={handleCrossClick}
        >
          <img
            className="relative w-6 h-6 shrink-0 overflow-hidden position-absolute pl-auto"
            alt=""
            src='/close-cross.svg'
          />
        </button>
    </div>
  </div>
) : null;
};

export default ConnectedAccountsContainer;