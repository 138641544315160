import React from "react";

const YesNoToggle = ({ titleText, value, onChange }) => {
  return (
    <div className="flex-1 flex flex-col items-start justify-start gap-[8px] text-sm text-gray-700 font-text-l-regular">
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
        <span>{titleText}</span>
      </div>
      <label className={`flex items-center justify-between w-12 h-6 ${value ? "bg-mediumseagreen-200" : 'bg-slate-200'} rounded-full p-1`}>
        <input
          type="checkbox"
          className="hidden"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span
          className={`${
            value ? "translate-x-6" : "translate-x-0"
          } inline-block w-5 h-5 bg-white rounded-full transform transition duration-300 ease-in-out`}
        ></span>
      </label>
    </div>
  );
};

export default YesNoToggle;

