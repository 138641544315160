import React, { useState, useMemo } from 'react';
import InputCard from './InputCard';
import { motion, AnimatePresence, useSpring } from "framer-motion";
import { collection, addDoc, doc, setDoc, getDoc} from "firebase/firestore"; 
import {ref, uploadBytesResumable, deleteObject, listAll} from "firebase/storage";
import { db, storage } from "../firebase";
import { useEffect, useRef, useLayoutEffect } from 'react';
import { useUserData } from "../UserDataContext";

const Linkedin = ({handleClose}) => {
  const [currentStep, setCurrentStep] = useState(1);

  const data = {
    1: {
      image: 'linkedin1.png',
      title: 'Navigate to your profile',
      description: 'To get started exporting your resume, navigate to your LinkedIn profile and click “More”, which should open a dropdown with some options. Here, select "build resume".',
    },
    2: {
      image: 'linkedin2.png',
      title: 'Build your resume',
      description: 'Once you click on build a resume, it will open a modal with two options, of which you should select “create from profile”, which will route you to the resume builder.',
    },
    3: {
      image: 'linkedin3.png',
      title: 'Download and submit',
      description: 'Once your resume is customized to your liking, click the “Download as PDF” button near the top of the screen, and upload here!',
    },
  };

  useEffect(() => {
    const imageUrls = Object.values(data).map((item) => item.image);
    imageUrls.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  const handleContinue = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };


  return (
    <motion.div 
        className="flex flex-col items-center justify-start gap-4 text-left text-sm text-e"
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        exit={{ opacity: 0}}
        transition={{ duration: 0.4, ease: "easeInOut" }}
    >
        <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
            <div
                className="fixed inset-0 transition-opacity"
                aria-hidden="true"
                onClick={handleClose}
            >
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="inline-block align-middle justify-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full relative bg-base-white shadow-md w-full overflow-hidden flex flex-col items-start justify-start text-center text-lg text-gray-900 font-text-sm-semibold">
                <div className="self-stretch bg-base-white flex flex-row pt-4 px-0 pb-0 items-center justify-center">
                    <div className="self-stretch flex-1 flex flex-col px-5 pb-6 items-start justify-start gap-12">
                        <div className="self-stretch flex flex-col items-center justify-center gap-8">
                          <motion.div 
                                className="self-stretch flex flex-col py-0 px-3 items-center justify-center gap-2"
                                key={currentStep}
                                initial={{ opacity: 0.01, x: 10 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0.01, x: -10 }}
                                transition={{ duration: 0.7, ease: "easeInOut" }}
                            >
                            <div className="self-stretch flex flex-col items-center justify-center">
                                <img className="relative rounded-3xs w-full h-auto py-4 object-cover" src={data[currentStep].image} />
                            </div>
                    
                            
                                <div className="self-stretch relative leading-7 font-semibold">
                                    {data[currentStep].title}
                                </div>
                                <div className="self-stretch relative text-base leading-6 text-gray-600">
                                    {data[currentStep].description}
                                </div>
                            </motion.div>
                            <div className="flex justify-center space-x-2 mb-4">
                                {Array(3)
                                    .fill()
                                    .map((_, i) => (
                                        <div
                                            key={i}
                                            className={`relative rounded-xl w-2 h-2 ${
                                                i < currentStep
                                                    ? 'bg-mediumseagreen-200'
                                                    : 'bg-gray-neutral-200'
                                            }`}
                                        />
                                    ))}
                            </div>
                            <div className="self-stretch flex flex-row items-start justify-center gap-3 text-left text-sm text-gray-700">
                                <div className="flex-1 rounded-lg bg-base-white shadow-sm overflow-hidden flex flex-row py-3.5 px-4 items-center justify-center border border-solid border-gray-300" onClick={currentStep == 1 ? handleClose : handleBack}>
                                    <div className="relative leading-5 font-semibold">{currentStep == 1 ? "Close" : "Back"}</div>
                                </div>
                                <div className="flex-1 rounded-lg bg-mediumseagreen-100 shadow-sm overflow-hidden flex flex-row py-3.5 px-4 items-center justify-center text-base-white border border-solid border-mediumseagreen-100" onClick={currentStep == 3 ? handleClose : handleContinue}>
                                    <div className="relative leading-5 font-semibold">
                                      {currentStep == 3 ? "Finish" : "Continue"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </motion.div>
  );
};



const ProgressBarUpload = ({ progress }) => {
  const previousProgress = (progress - 10) > 0 ? (progress - 10) : 0;

  return (
    <div className="self-stretch overflow-hidden rounded-md bg-gray-neutral-200">
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: `${progress}%` }}
        transition={{ 
          from: `${previousProgress}%`,
          duration: 0.8, 
          ease: "easeInOut" 
        }}
        className="h-2 bg-mediumseagreen-200 rounded-md"
      />
    </div>
  );
};

const FileUploadBase = ({ onFileUpload }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('idle'); // Modified state variable
  const [uploadProgress, setUploadProgress] = useState(0);
  const { user, userData, setUserData } = useUserData();
  const [filename, setFilename] = useState("");

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const file = e.dataTransfer.files[0];
    uploadFile(file);
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    uploadFile(file);
  };

  const uploadFile = (file) => {
    if(Math.round(file.size/1000000) > 10){
      alert('file too big')
      return;
    }
  
    const allowedExtensions = /(\.txt|\.pdf)$/i;
    if(!allowedExtensions.exec(file.name)) {
      alert('Invalid file type');
      return;
    }
  
    setUploadStatus('uploading');
  
    const dbRef = ref(storage, user.uid);
    listAll(dbRef)
      .then((res) => {
        res.items.forEach((itemRef) => {
          deleteObject(itemRef).then(() => {
          }).catch((error) => {
            console.log("error deleting")
          });
        });
      }).catch((error) => {
       console.log("error")
      });
  
    const storageRef = ref(storage, user.uid + "/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
  
    uploadTask.on('state_changed', 
      (snapshot) => {
        setUploadStatus('uploading');
        setUploadProgress(Math.floor((snapshot.bytesTransferred/snapshot.totalBytes)*100));
      }, 
      (error) => {
        console.error("Upload failed", error);
        setUploadStatus('error');
      }, 
      () => {
        onFileUpload();
        setUploadStatus('complete');
        setFilename(file.name);
        setUserData({...userData, filename: file.name});
      }
    );
  };

  return (
    <div
      className={`relative rounded-md ${
        isDragOver ? 'bg-mintcream-100' : 'bg-base-white'
      } box-border w-full flex flex-col py-4 px-6 items-center justify-start text-left text-sm text-primary-700 font-text-xs-regular border-[1px] border-solid border-gray-200`}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-[12px]">
        <img
          className="relative rounded-[28px] w-[46px] h-[46px]"
          alt=""
          src="/upload.png"
        />
       <div className="self-stretch flex flex-col items-center justify-start gap-[4px]">
      {uploadStatus != 'idle' || uploadProgress < 100 && uploadProgress != 0 ? (
        <>
          {uploadStatus === 'complete' ? <div>Uploading Complete</div> : <div>Uploading... {uploadProgress}%</div>}
          <ProgressBarUpload progress={uploadProgress} />
        </>
      ) : (
       <>
        <div className="self-stretch flex flex-row items-start justify-center gap-[4px]">
          <div className="overflow-hidden flex flex-row items-center justify-center gap-[8px]">
          <label htmlFor="file-upload" className="relative leading-[20px] font-semibold text-mediumseagreen-200 cursor-pointer">
              Click to upload
            </label>

            <input
              type="file"
              id="file-upload"
              className="hidden"
              onChange={handleFileInput}
            />

              </div>
                <div className="relative leading-[20px] text-gray-600">
                   or drag and drop
                </div>
              </div>
                <div className="self-stretch relative text-xs leading-[18px] text-gray-600 text-center">
                  PDF, or TXT (max. 5 MB)
                </div>
              </>
          )}
        </div>
      </div>
      <input
        type="file"
        className="hidden"
        onChange={handleFileInput}
      />
    </div>
  );
};



const Step1 = ({ nextStep }) => {
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    // Your Step 1 content here
    <div className="justify-center items-center">
                <div className="self-stretch flex flex-col items-start justify-start mb-12">
                  <motion.div className="relative tracking-[-0.01em] leading-[38px] font-semibold"
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ duration: .8, delay: 0, ease: "easeOut" }}
                >
                      Welcome to Wrangle!
                  </motion.div>
                  <motion.div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50"
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ duration: .8, delay: 0.1, ease: "easeOut" }}
                >
                    Let’s get your profile ready so we can supercharge your application process. This will only take ~2 minutes.
                  </motion.div>
                </div>
                <div className="self-stretch flex flex-col items-center justify-center gap-[24px] w-full max-w-screen-md mx-auto">
                    <motion.button onClick={nextStep} className="cursor-pointer py-6 px-[18px] bg-mediumseagreen-200 w-full rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-neutral-600"
                      initial="hidden"
                      animate="visible"
                      variants={variants}
                      transition={{ duration: .8, delay: 0.2, ease: "easeOut" }}
                    >
                        <div className="relative text-lg leading-[22px] font-semibold font-text-sm-semibold text-base-white text-left">
                            Get Started
                        </div>
                    </motion.button>
                </div>
              </div>
  );
};

const Step2 = ({nextStep}) => {
  const {userData, setUserData} = useUserData();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);

  const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setIsValidPhone(phoneRegex.test(e.target.value));
  };
  
  const handleSubmit = () => {
    setUserData({...userData, name: name, phone: phone, address: address});
    nextStep();
  };

  return (
    <div className="justify-center items-center">
                  <div className="self-stretch flex flex-col items-start justify-start mb-12">
                <div className="relative tracking-[-0.01em] leading-[38px] font-semibold"
               >
                    Basic Information.
                </div>
                <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50"
               >
                  We'll use this info to help the AI fill out your applications. We won't share this with anyone else, or use it to call you.
                </div>
            </div>
            <div className="relative bg-base-white w-full flex flex-col items-stretch justify-center text-left text-9xl text-gray-700 font-text-sm-semibold">
            <div className="flex flex-col items-stretch justify-center gap-[16px] text-sm mb-12">
                <div className="w-full flex flex-col items-stretch justify-start">
                    <div className="self-stretch flex flex-row items-stretch justify-start gap-[16px]">
                        <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                            <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
                                      Full Name <span className="text-red-600">*</span>
                                  </div>
                                  <input
                                    className={`font-text-sm-semibold text-mini bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] overflow-hidden shrink-0 flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700`}
                                    type="text"
                                    placeholder="Full Name"
                                    required
                                    onChange={e => setName(e.target.value)}
                                />
                              </div>
                              <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                  <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
                                      Phone <span className="text-red-600">*</span>
                                  </div>
                                  <input
                                      className={`font-text-sm-semibold text-mini bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] overflow-hidden shrink-0 flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700`}
                                      type="tel"
                                      placeholder="Phone Number"
                                      required
                                      onChange={handlePhoneChange}
                                  />
                              </div>
                          </div>
                      </div>
                      <div className="w-full flex flex-col items-center justify-center">
                          <div className="self-stretch flex flex-row items-center justify-center">
                              <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                                  <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
                                      Address <span className="text-red-600">*</span>
                                  </div>
                                  <input
                                    className={`font-text-sm-semibold text-mini bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] overflow-hidden shrink-0 flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700`}
                                    type="text"
                                    placeholder="Address (Must include city and state)"
                                    required
                                    onChange={e => setAddress(e.target.value)}
                                />
                              </div>
                          </div>
                      </div>
                  </div>
        <div className="self-stretch flex flex-col items-center justify-end">
        <button onClick={handleSubmit} disabled={!name || !isValidPhone || !address} className={`cursor-pointer py-6 px-[18px] ${name && isValidPhone && address ? 'bg-mediumseagreen-200' : 'bg-gray-neutral-200'} self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-neutral-600`}>
        <div className="relative text-lg leading-[22px] font-semibold font-text-sm-semibold text-base-white text-left">
          Continue to Resume
        </div>
      </button>
        </div>
      </div>
      </div>
  );
};

const Step3 = ({ nextStep }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleFileUpload = () => {
    setFileUploaded(true);
  };

  const handleClose = () => {
    setShowModal(false);
  }

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="justify-center items-center">
      <div className="self-stretch flex flex-col items-start justify-start mb-12">
        <div className="relative tracking-[-0.01em] leading-[38px] font-semibold">
          Resume Upload.
        </div>
        <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50">
          If you don't have a resume, we recommend exporting from LinkedIn.
        </div>
        <span 
            onClick={() => setShowModal(true)} 
            className="text-mediumseagreen-200 cursor-pointer relative text-base tracking-[-0.1px] leading-[24px]"
          >
            How do I do that?
          </span>
      </div>
      {showModal && <AnimatePresence><Linkedin handleClose={handleClose} /></AnimatePresence>}
      <div className="self-stretch flex flex-col items-start justify-start mb-12">
        <FileUploadBase onFileUpload={handleFileUpload} />
      </div>
      <div className="self-stretch flex flex-col items-center justify-end">
        <button 
          onClick={fileUploaded ? nextStep : null} 
          className={`cursor-pointer py-6 px-[18px] ${fileUploaded ? 'bg-mediumseagreen-200' : 'bg-gray-neutral-200'} self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-neutral-600`}
        >
          <div className="relative text-lg leading-[22px] font-semibold font-text-sm-semibold text-base-white text-left">
            Continue to Extra Info
          </div>
        </button>
      </div>
    </div>
  );
};


const Step4 = () => {
  const { userData, setUserData} = useUserData();
  const [keyInfo, setKeyInfo] = useState('');

  const handleSubmit = () => {
    setUserData({...userData, keyInfo: keyInfo, onboarded: true});
  };

  return (
    // Your Step 1 content here
    <div className="justify-center items-center">
                <div className="self-stretch flex flex-col items-start justify-start mb-12">
                <div className="relative tracking-[-0.01em] leading-[38px] font-semibold">
                    Additional Information.
                </div>
                <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50">
                This will be used to specialize the model to answer specifically for you, and increase accuracy. Don't worry, you can edit this later in profile.
                </div>
            </div>
                                          <div className="self-stretch h-[187px] flex flex-col items-start justify-start mb-12">
                                          <textarea
                                            className="bg-base-white focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 font-text-l-regular text-mini self-stretch flex-1 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex flex-row py-3 px-4 items-start justify-start border-[1px] border-solid border-neutral-700 resize-none"
                                            placeholder= {`The more information you add, the better the responses. Include things like education, work history, or anything else you feel would benefit your applications.`}
                                            style={{resize: "none"}}
                                            onChange={e => setKeyInfo(e.target.value)}
                                          />
                                          </div>
                                          <div className="self-stretch flex flex-col items-center justify-end">
                                          <button onClick={handleSubmit} className="cursor-pointer py-6 px-[18px] bg-mediumseagreen-200 self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-neutral-600">
                                            <div className="relative text-lg leading-[22px] font-semibold font-text-sm-semibold text-base-white text-left">
                                              Finish Profile
                                            </div>
                                          </button>
                                          </div>
                                        </div>
  );
};



const Onboarding = () => {
    const [step, setStep] = useState(1);
    const { userData, setUserData } = useUserData();

    const nextStep = () => {
        setStep(step + 1);
    };

    const variants = {
      hidden: { opacity: 0, y: 20 },
      beast: { opacity: .1, y:20 },
      visible: { opacity: 1, y: 0 },
    };
    

    const Header = () => {
      const handleClick = () => {
        setUserData({...userData, onboarded: true});
      };
      return (
      <div className="self-stretch flex flex-row items-center justify-between">
          <div className="w-[143px] h-[34px] flex flex-col items-center justify-center">
              <img
                  className="self-stretch relative max-w-full overflow-hidden h-[33px] shrink-0"
                  alt=""
                  src="/company-logo.svg"
              />
          </div>
          <button className="cursor-pointer [border:none] p-0 bg-[transparent] w-[270px] flex flex-col items-end justify-center">
              <div className="self-stretch flex flex-row items-start justify-end">
                  <div className="relative text-mini tracking-[-0.1px] leading-[22px] font-semibold font-text-sm-semibold text-left">
                      <span className="text-gray-50">{`Want to do this later? `}</span>
                      <span className="[text-decoration:underline] text-mediumseagreen-200" onClick={handleClick}>
                          Go to app
                      </span>
                  </div>
              </div>
          </button>
      </div>
    );
  };
    
    
    const ProgressBar = React.memo(() => {
     //console.log("progress");
     //console.log(step);
      const progress = (step / 4) * 100;
      const previousProgress = ((step - 1) / 4) * 100;
    
      return (
        <div className="self-stretch overflow-hidden rounded-md bg-gray-neutral-200">
          <motion.div
            key={step} // Here we add key prop based on the step
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ 
              from: `${previousProgress}%`,
              duration: 0.8, 
              ease: "easeInOut" 
            }}
            className="h-2 bg-mediumseagreen-200 rounded-md"
          />
        </div>
      );
    }, [step]);

    
    const StepContent = () => {
      switch (step) {
        case 1:
          return <Step1 nextStep={nextStep} />;
        case 2:
          return <Step2 nextStep={nextStep} />;
        case 3:
          return <Step3 nextStep={nextStep} />;
        case 4:
          return <Step4 />;
        default:
          return null;
      }
    };

    return (
      <div className="fixed z-10 inset-0 overflow-y-auto bg-white w-full h-screen">
      <div>
          <div className="items-start">
          <div>
              <div className="relative bg-base-white w-full h-screen flex flex-row items-start justify-start text-left text-9xl text-gray-700 font-text-sm-semibold">
              <div className="self-stretch flex-1 flex flex-row p-8 items-start justify-start gap-[48px]">
                  <div className="self-stretch flex-1 flex flex-col items-start justify-between w-1/2">
                          <Header></Header>

                              {StepContent()}
                          <ProgressBar></ProgressBar>
                  </div>
              <div className="self-stretch rounded-8xs w-3/5 [background:linear-gradient(180deg,_#cdfee3,_#e5ffee_97.92%)] w-stretch overflow-hidden shrink-0 flex flex-col items-start justify-start">
                < motion.img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/zzz.png"
                    initial="beast"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.7, delay: 0, ease: "easeOut" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
};

export default Onboarding;
