import React from "react";
import FileUpload from "./FileUpload";
import YesNoToggle from "./YesNoToggle";
import CheckboxGroup from "./CheckBoxGroup";
import RadioInputCard from "./RadioInputCard";
import DropdownInput from "./DropdownInput";
import FormInputCard from "./FormInputCard";
import { useEffect, useState, useMemo, useRef } from "react";
import { collection, addDoc, doc, getDoc} from "firebase/firestore";
import { db } from "../firebase";
import axios from 'axios';
import { useUserData } from "../UserDataContext";
import { useJobs } from "../jobsContext";
import Rive from "rive-react";
import { useDeprecatedAnimatedState } from "framer-motion";

const ScrapedForm = ({ appLink, inputValues, setInputValues, newApp, setNewApp, setErrored, errored}) => {
  const [formInfo, setFormInfo] = useState([]);
  const {user, isAuthenticated, currentApi, userData} = useUserData();
  const {selectedJob} = useJobs();
  const [fetched, setFetched] = useState(false);
  const [form, setForm] = useState(false);
  const apiCalled = useRef(false);

  useEffect(() => {
    const fetchFormInfo = async () => {
      try {
        const response = await fetch(`${currentApi}/get-form`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ appLink: appLink }),
        });
  
        if (response.ok) {
          const { finalUrl, formInfo } = await response.json();
          setFormInfo(formInfo);
        } else {
          console.error('Error while fetching form information');
        }
      } catch (error) {
        console.error('Error while fetching form information:', error);
      }
    };
  
    fetchFormInfo();
  }, []);
  

  const Loader = () => (
    <Rive src="/loadingwrangle.riv" className="min-w-[400px] min-h-[400px]"></Rive>
  );


const openAIWhisper = useMemo(
  () => async (questions) => {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-3.5-turbo",
          messages: [
            {
              "role": "system",
              "content": `

              You're an AI applying for a job at ${selectedJob.companyName} as ${selectedJob.title}. Based on the instructions below, answer the job application questions to maximize your chances of being hired, and Try to answer as many questions as possible:

              Questions will be in this format: label|type|is required?|options (if applicable). 
              Example of a required question: First Name|text|Required, What is your Notice Period?|textarea|Required, Do you have experience in Python?|radio|Required|Yes,No, Pronouns|checkbox|Required|Please select,He/him/his,She/her/hers,My pronouns are not listed
              Example of a non-required question: First Name|text|Not required

              1. Always answer required questions, regardless of the type. If the question is required but the information isn't available come up with an answer.
              2. If a question is not required, you can leave it blank, but you should try to answer every question.
              3. For visa status questions, answer "No, I will not require sponsorship now or in the future."
              4. Provide specific details where possible. Use the data available to you to generate information if needed.
              5. For multiple-choice questions, choose a suitable option from the given options. Never leave them unanswered if reuiqred. You must answer with one of the given options, never generate a response that is not an option.
              6. When asked for salary expectations, give a reasonable range for the role of ${selectedJob.title}, don't say the job title outright.
              7. Answer all radio questions with a beneficial option for the application. The options you select must be part of the given options.
              8. Keep text responses positive, focusing on the applicant's strengths.
              9. Avoid mentioning AI or the Himalayas.
              10. Fill every required field. If it's beneficial, fill optional fields as well.
              11. When asked for additional information or a signature, provide it if possible.
              12. If given the question "Add a cover letter or anything else you want to share.", craft a response specifically for ${selectedJob.companyName}, mentioning why you're a good fit for the role of ${selectedJob.title}. Ensure this response goes into the field named "Add a cover letter or anything else you want to share." in the output JSON object. Don't attach this at the end of the output, it should still correspond to this question.
              13. Leave file input fields blank.
              14. For textarea types, answer it like you would a normal text question, except longer. 
              15. When given a question about how you heard about the job, answer with something that the company will recieve positively ("I've been following your product for a long time and saw the job opening on your website.", etc.)

              The goal is to create a compelling job application that follows these instructions, keeping in mind the role, industry standards, and company specifics. All your responses should be tailored to align with ${selectedJob.companyName} and the role of ${selectedJob.title}. Try to answer as many questions as possible.
            
              Example profile: {"portfolio":"google.com","phone":"+1 123 456 7890", "keyInfo":"This is a test for generating a cover letter","email":"john.doe@example.com","address":"Portland, OR, 97212", "name":"John Doe", "github": "johndoe", "stackoverflow": null}

              Example inputs in format of label|type|is required?|options (if applicable):
          
              Input:
                First Name|text|Required
                Last Name|text|Required
                Email|text|Required
                Phone|text|Required
                LinkedIn Profile|text|Not required
                Current Company|text|Not required
                Preferred Name|text|Required
                What brings you to this industry?|textarea|Not required
                Pronouns|select|Required|Please select,He/him/his,She/her/hers,My pronouns are not listed
                Are you legally authorized to work in the United States?|select|Required|Yes,No
                Do you now or in the future require sponsorship for employment visa status (e.g., H-1B, TN, E-3, F-1 visa status)?|select|Required|Yes,No
                How did you first learn about Affirm as an employer?|select|Required|Affirm blog,Affirm Recruiting Team reached out,Affirm’s Career Site,Glassdoor,I have used Affirm as a product,I know someone that works at Affirm,Twitter,Other
                GitHub|text|Not required
                Twitter|text|Not required
                Portfolio|text|Not required
                Other Links|textarea|Not required
                Which U.S. State or Canadian Province do you reside in?|select|Required|Indiana,Iowa,Kansas,Kentucky,Nevada,Ohio,Oklahoma,Oregon,Pennsylvania
                What is your current CTC?|textarea|required
                Add a cover letter or anything else you want to share.|textarea|Not required

                
              Output:
                {"First Name":"John","Last Name":"Doe","Email":"john.doe@example.com","Phone":"+1 123 456 7890","LinkedIn Profile":"https://www.linkedin.com/in/johndoe","Preferred Name":"John","What brings you to this industry?":"I've always been passionate about this industry.","Pronouns":"He/him/his","Are you legally authorized to work in the United States?":"Yes","Do you now or in the future require sponsorship for employment visa status (e.g., H-1B, TN, E-3, F-1 visa status)?":"No","How did you first learn about Affirm as an employer?":"Affirm’s Career Site", "Github":"", "Twitter":"", "Portfolio":"https://johndoe.com","Which U.S. State or Canadian Province do you reside in?":"Oregon","What is your current CTC?": "$120,000","Add a cover letter or anything else you want to share.":"Dear Hiring Manager, I am excited to apply for the position of Technical Success Manager at Affirm. As a software engineer at google, I have interacted in a variety of technology companies, including Uber and Roku. My experience in the tech industry, combined with my passion for developing and delivering successful products, make me an excellent candidate for this role. I have a deep understanding of the importance of technical success in a company. My experience working with a variety of startups has given me the ability to identify and solve technical challenges, mitigate risk, and ensure the success of new product launches. I have also developed a strong network of technical experts and engineers, whom I can rely on to help solve any issues that may arise. My attention to detail, communication skills, and focus on results make me an ideal candidate for this role. I am confident that my experience and expertise will enable me to make significant contributions to Spruce. Thank you for considering my application. Best regards, John Doe"}
              
            
              Your response should be no more than 1000 tokens total. Try to compress longer phrases to retain meaning but preserve tokens.  `
            },
            {
              role: "user",
              content: `Here's is your information: ${JSON.stringify(
                {
                  Address: userData.address,
                  Portfolio: userData.portfolio,
                  Phone: userData.phone,
                  Name: userData.name,
                  Keyinfo: userData.keyInfo,
                  Filename: userData.filename,
                  Email: userData.email,
                  linkedin: userData.linkedin,
                  figma: userData.figma,
                  dribbble: userData.dribbble,
                  github: userData.github,
                  twitter: userData.twitter,
                  stackoverflow: userData.stackoverflow
                }
              )}. Input: 
                ${questions}
                
                Output:
                `,
            },
          ],
          temperature: 0.8,
          max_tokens: 1000,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer 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`,
          },
        }
      );
      const {message: {content: rawAnswers}, finish_reason} = response.data.choices[0]
      if(finish_reason !== "stop"){
        console.error("OpenAI response incomplete", {finish_reason, rawAnswers});
        return {};
      }

      try{
        try {
          //console.log(rawAnswers);
          //console.log(JSON.parse(rawAnswers));
         /*console.log(JSON.stringify(
            {
              Address: userData.address,
              Portfolio: userData.portfolio,
              Phone: userData.phone,
              Name: userData.name,
              Keyinfo: userData.keyInfo,
              Filename: userData.filename,
              Email: userData.email,
              linkedin: userData.linkedin,
              figma: userData.figma,
              dribbble: userData.dribbble,
              github: userData.github,
              twitter: userData.twitter,
              stackoverflow: userData.stackoverflow
            }
          ))*/
          return JSON.parse(rawAnswers);
        } catch(e) {
          console.error("Couldn't parse JSON in raw answers", e);
          setErrored(true);
          return {};
        }
      }catch(e){
       //console.log("Error parsing JSON response from OpenAI API", rawAnswers, e);
        setErrored(true);
      }
    } catch (error) {
      console.error("Error while calling OpenAI Whisper API:", error);
      setErrored(true);
      //console.log(userData);
      //console.log(questions);
      return [];
    }
  },
  [formInfo]
);

useEffect(() => {
  async function updateEmptyInputValues() {

    const responses = await handleEmptyInputValues();
   //console.log("responses", responses);

    setInputValues(responses);
    setFetched(true);
    setNewApp(true);
  }
  if (formInfo.length > 0) {
    apiCalled.current = true;
    updateEmptyInputValues();
  } else {
   //console.log("form not ready yet");
  }
}, [formInfo]);

const handleEmptyInputValues = async () => {
  const seenQuestions = new Set();
  const prompt = formInfo.filter(({type, label}) => {
   //console.log(formInfo);
    if (type !== "file" && !seenQuestions.has(label)) {
      seenQuestions.add(label);
      return true;
    }
    return false;
  }).map(({selector, options, ...rest}) => {
    if(options && options.length){
      options = options.filter((opt) => !(opt.label.toLowerCase().includes("please select") || opt.label === "--"));
    }
    // format is: label|type|isRequired|options?
    
    return `${rest.label}|${rest.type || rest.tag}|${rest.required ? 'Required' : 'Not required'}${options ? '|' + options.map((opt) => opt.label).join(",") : ""}`;
  }).join("\n")
 //console.log(prompt);
  const responses = await openAIWhisper(prompt);
  return responses;
 
};



const renderInput = useMemo(() => (input, inputIdx) => {
  const inputName = input.label;
  const inputType = input.type;
  const inputTag = input.tag;
  const isRequired = input.required;
  const options = input.options?.map((option) => ({
    label: option.label || option.value,
    value: option.value,
  })) || [];
  let inputValue = inputValues?.[inputName] || "";

  if (Array.isArray(inputValue)) {
    inputValue.forEach((val, idx) => {
      const inputOptionValue = options?.find(({ label }) => label === val || label.includes(val))?.value;
      if (inputOptionValue) {
        inputValue[idx] = inputOptionValue;
      }
    });
  } else if (options && options.length) {
    const val = options.find(({ label }) => label === inputValue || label.includes(inputValue))?.value;
    inputValue = val || inputValue;
  } else if (inputType !== 'text' && inputType !== 'textarea' && inputType !== 'number' && inputTag !== 'input' && inputTag !== 'textarea' && inputTag !== 'text' && typeof inputValue === "string" && inputValue.includes(",")) {
    inputValue = inputValue.split(",").map(item => item.trim());
  }
  

  inputValues[inputName] = inputValue;

  const titleText = isRequired ? `${inputName} *` : inputName;
  //console.log(inputName + ' name')
  //console.log(inputType + ' type')
  if (!inputName) {

    return (
      <CheckboxGroup
        key={inputIdx}
        label={"Unknown label."}
        options={options}
        required={isRequired}
        onChange={() => {}}
      />
    );
  }

  switch(inputType || inputTag) {
    case "checkbox": {
      if (!input?.options) {
        return (
          <YesNoToggle
            key={inputIdx}
            titleText={titleText}
            required={isRequired}
            onChange={(checked) => setInputValues({ ...inputValues, [inputName]: checked })}
            value={inputValue}
          />
        );
      }

      if(!Array.isArray(inputValue)){
        inputValue = [inputValue];
      }
      return (
        <CheckboxGroup
          key={inputIdx}
          label={titleText}
          options={options}
          required={isRequired}
          initialChecked={inputValue}
          onChange={(name, checked) => {
            const newInputValue = inputValues[inputName] || [];
            if (checked && !newInputValue.includes(name)) {
              newInputValue.push(name);
            } else if (!checked && newInputValue.includes(name)) {
              newInputValue.splice(newInputValue.indexOf(name), 1);
            }
            setInputValues({ ...inputValues, [inputName]: newInputValue });
          }}
        />
      );
    }
    case "group" && !isRequired:
      return;
    case "file":
      return (
        <FileUpload
          key={inputIdx}
          titleText={titleText}
          file={inputValue}
          setFile={() => {}}
        />
      );
    case "radio": {


      return (
        <RadioInputCard
          key={inputIdx}
          titleText={titleText}
          options={options}
          value={inputValue}
          onChange={(checked) =>
            setInputValues({ ...inputValues, [inputName]: checked })
          }
        />
      );
    }
    case "select": {

      return (
        <DropdownInput
          key={inputIdx}
          titleText={titleText}
          options={options}
          value={inputValue} // Pass the value here
          onChange={(value) =>
            setInputValues({ ...inputValues, [inputName]: value })
          }
        />
      );
    }
    default:

      return (
        <FormInputCard
          key={inputIdx}
          titleText={titleText}
          inputText={input.placeholder}
          value={inputValue}
          onChange={(value) => setInputValues({ ...inputValues, [inputName]: value })}
        />

      );
}
});

//print
return (
  <>
    <div className="mb-10">
      {fetched ? (
        <div className="input-container flex flex-col items-start gap-6">
          {formInfo
            .map((input, inputIdx) => renderInput(input, inputIdx))
            .filter((input) => input !== null)}
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-[400px] min-w-[100%]">
          <Loader />
        </div>
      )}
    </div>
  </>
);
};


export default ScrapedForm;