import { useCallback, useState, useEffect } from "react";
import ContainerButton from "../components/ContainerButton";
import ApplicationModal from "./ApplicationModal";
import React from "react";
import openai from "openai";
import "whatwg-fetch";
import { collection, addDoc, doc, getDoc} from "firebase/firestore";
import { db, storage } from "../firebase";
import { onSnapshot } from "firebase/firestore";
import parse from 'html-react-parser';
import Banner from "./Banner";
import { usePayment } from "../PaymentContext";
import { useUserData } from "../UserDataContext";
import { useJobs } from "../jobsContext";
import sanitizeHtml from 'sanitize-html';


const JobPanel = () => {
  const [showModal, setShowModal] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [isAppModalOpen, setIsAppModalOpen] = useState(false);
  const {user, isAuthenticated, userData, setUserData} = useUserData();
  const [applied, setApplied] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerType, setBannerType] = useState("");
  const [showBanner, setShowBanner] = useState(false);
  const [startExitAnimation, setStartExitAnimation] = React.useState(false);
  const {setUpgradeFeatureModal, fetchSubscriptionData} = usePayment();
  const {selectedJob} = useJobs();

  const title = selectedJob.title || "";
  const jobTitle = title? title.match(/[\w\s\/.]+/)[0] : "";

  const displayBanner = (message) => {
    setBannerMessage(message);
    setShowBanner(true);
    setStartExitAnimation(false);
    setTimeout(() => {
      setStartExitAnimation(true);
    }, 3000);
  };

  const checkSubscribe = async () => {
    let nameregex = new RegExp("^[a-zA-Z]+[-' ]+[a-zA-Z]+([-' ]+[a-zA-Z]+)?$");
            let emailregex= new RegExp("(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])");
            let phoneregex = new RegExp("^(?=([+()\\d\\s-]*\\d){10,})[+\\d()\\s-]*$");
            if(userData.name == undefined || !nameregex.test(userData.name)){
              displayBanner("Error: Invalid name.", "error");
              return;

            }
            else if (userData.phone == undefined || !phoneregex.test(userData.phone)){
              displayBanner("Error: Invalid phone.", "error");
              return;

            }
            else if (userData.address == undefined || !(userData.address.length > 1)){
              displayBanner("Error: Invalid address.", "error");
              return;
            }
            else if (userData.email == undefined || !emailregex.test(userData.email)){
              displayBanner("Error: Invalid email", "error");
              return;

            }
            else if (userData.filename == undefined || userData.filename == null){
              displayBanner("Error: Please upload a resume", "error");
              return;
            }
    setIsAppModalOpen(true);
   //console.log(selectedJob.applyNowLink);
    if(!isAuthenticated) {
      return
    }
    //const ret = await updateStatus(); NEWUPDATE
    const subdata = await fetchSubscriptionData();
    if((subdata[0] == 1 && 50-subdata[1] <=0) || (subdata[0] == 2 && 150-subdata[1] <=0) || (subdata[0] == 3 && 500-subdata[1] <=0)){
      setUpgradeFeatureModal(true);
      onClose();
      return;
    }
    else{
      setIsAppModalOpen(true);
    }
  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const isTitleLine = (element) => {
    return element.type === 'h3';
  };

  const truncateDescription = (desc, maxLines = 84) => {
    // Define allowed tags
    const allowedTags = sanitizeHtml.defaults.allowedTags.concat(['img']);

    // Sanitize HTML and remove unwanted tags
    const sanitizedHtml = sanitizeHtml(desc, {
      allowedTags: allowedTags,
    });

    // Parse sanitized HTML
    const parsedDescription = parse(sanitizedHtml);
    let truncatedLines = [];
    let totalLines = 0;

    for (let i = 0; i < parsedDescription.length && totalLines < maxLines; i++) {
      const element = parsedDescription[i];

      if (element.type === 'p' || element.type === 'ul') {
        totalLines += 1;
      } else if (isTitleLine(element)) {
        totalLines += 1;
      }

      if (totalLines <= maxLines) {
        truncatedLines.push(element);
      }
    }

    return truncatedLines;
  };



   const tagColors = {
        engineering: { bgColor: "bg-green-50", textColor: "text-green-600" },
        sales: { bgColor: "bg-blue-50", textColor: "text-blue-600" },
        marketing: { bgColor: "bg-purple-50", textColor: "text-purple-600" },
        product: { bgColor: "bg-pink-50", textColor: "text-pink-600" },
        design: { bgColor: "bg-teal-50", textColor: "text-teal-600" },
        support: { bgColor: "bg-red-50", textColor: "text-red-600" },
        operations: { bgColor: "bg-orange-50", textColor: "text-orange-600" },
        data: { bgColor: "bg-indigo-50", textColor: "text-indigo-600" },
        finance: { bgColor: "bg-yellow-50", textColor: "text-yellow-600" },
        management: { bgColor: "bg-gray-100", textColor: "text-gray-600" },
    };

    const renderTag = (tag) => {
        const normalizedTag = tag.toLowerCase();
        const color = tagColors[normalizedTag] || { bgColor: "bg-gray-100", textColor: "text-gray-600" };

        return (
            <div
                className={`rounded-8xs ${color.bgColor} ${color.textColor} flex flex-row py-0.5 px-2 items-center justify-start gap-[4px]`}
            >
                <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                    {tag.charAt(0).toUpperCase() + tag.slice(1)}
                </div>
            </div>
        );
    };

    return (
      <>
      {showBanner && <Banner startExitAnimation={startExitAnimation} bannerMessage={bannerMessage} bannerType={bannerType}/>}


    <div className="self-stretch flex-1  bg-base-white flex flex-row p-8 items-start justify-start rounded-3xs border-[1px] border-solid border-neutral-600">
          <div className="self-stretch flex-1 flex flex-col items-start justify-between">
            <div className="self-stretch flex flex-col items-start justify-start gap-[32px]">
              <div className="relative tracking-[-0.02em] leading-[40px] font-semibold break-words">
                {selectedJob.title}
              </div>
              <div className="self-stretch rounded-3xs bg-base-white flex flex-row py-4 px-5 items-center justify-between text-sm text-gray-50 border-[1px] border-solid border-neutral-600">
                <div className="flex flex-col items-center justify-start gap-[6px]">
                  <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                    Date Posted
                  </div>
                  <div className="flex flex-row items-center justify-start text-mini text-gray-700">
                    <div className="relative tracking-[-0.1px] leading-[22px] font-semibold">{formatDate(selectedJob.pubDate)}</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start gap-[6px]">
                  <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                    Expiry Date
                  </div>
                  <div className="flex flex-row items-center justify-start text-mini text-gray-700">
                    <div className="relative tracking-[-0.1px] leading-[22px] font-semibold">{formatDate(selectedJob.expiryDate)}</div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start gap-[6px]">
                  <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                    Timezone
                  </div>
                  <div className="flex flex-row items-center justify-start text-mini text-gray-700">
                    <div className="relative tracking-[-0.1px] leading-[22px] font-semibold">
                      {selectedJob.timezoneRestriction || "Any"}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start gap-[6px]">
                  <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                    Category
                  </div>
                  <div className="flex flex-row items-center justify-start text-mini text-gray-700">
                    <div className="relative tracking-[-0.1px] leading-[22px] font-semibold">
                        {renderTag(selectedJob.categories?.[0] || "None")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch flex flex-row items-start justify-start gap-[24px] text-lg">
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                    <div className="self-stretch relative tracking-[-0.1px] leading-[28px] font-semibold">
                        Description
                    </div>
                    <div className="description-container" style={{ position: 'relative', maxHeight: "30vh" }}>
                        <div className="description-text">
                            <div
                                className="self-stretch relative text-mini tracking-[-0.1px] leading-[22px] font-medium text-gray-50 whitespace-pre-wrap break-words"
                                style={{ maxWidth: "100%", maxHeight: "30vh", overflowY: "auto" }}
                            >
                                {truncateDescription(selectedJob.description).map((element, index) => (
                                    <React.Fragment key={index}>{element}</React.Fragment>
                                ))}
                            </div>
                        </div>
                        <div 
                            className="gradient-div"
                            style={{ 
                                position: "absolute", 
                                bottom: 0, 
                                height: "50px", 
                                width: "100%",
                                backgroundImage: "linear-gradient(to top, white, transparent)" 
                            }} 
                        />
                    </div>
                  </div>
                </div>
                <div className="w-44 shrink-0 flex flex-col items-start justify-start text-mini text-black">
                  <div className="self-stretch rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex flex-col py-4 px-5 items-start justify-start gap-[22px] border-[1px] border-solid border-neutral-600">
                    <div className="self-stretch flex flex-col items-center justify-start gap-[12px] text-sm text-gray-700">
                      <div className="self-stretch flex flex-row items-center justify-start gap-[12px]">
                        <img
                          className="relative rounded-[50px] w-8 h-8 shrink-0"
                          alt=""
                          src={selectedJob.companyLogo}
                        />
                        <div className="flex flex-col items-start justify-center">
                          <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                            {selectedJob.companyName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                      <div className="self-stretch relative tracking-[-0.1px] leading-[22px] font-medium">
                        Location
                      </div>
                      <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50">
                        {selectedJob.locationRestrictions? selectedJob.locationRestrictions.join(', ') : "Remote"}
                      </div>
                    </div>
                    <div className="self-stretch flex flex-col items-start justify-start gap-[4px]">
                      <div className="self-stretch relative tracking-[-0.1px] leading-[22px] font-medium">
                        Position
                      </div>
                      <div className="self-stretch relative text-base tracking-[-0.1px] leading-[24px] text-gray-50">
                        {jobTitle}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-end gap-[24px]">
              <ContainerButton
                actionText="View More Information"
                actionLinkId="/external-link.svg"
                propBackgroundColor="transparent"
                propColor="#2c3444"
                onClick={() => window.open(selectedJob.applicationLink)}
              />
              <ContainerButton
                actionText={
                  "Apply"
                }
                actionLinkId="/external-link1.svg"
                propBackgroundColor={"#5bc68d"}
                propColor="#fff"
                propDisplay="none"
                onClick={() =>
                  checkSubscribe()
                }
              >
              </ContainerButton>
              <ApplicationModal
                isOpen={isAppModalOpen}
                onClose={() => setIsAppModalOpen(false)}
                appLink={selectedJob.applyNowLink}
                company={selectedJob.company}
                guid={selectedJob.guid}
                applied={applied}
                setApplied={setApplied}
              />
            </div>
          </div>
        </div>
        </>
    );
};

export default JobPanel
