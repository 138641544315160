import { useState, useEffect, useRef, useMemo, useCallback, useContext } from 'react';
import AppliedContainer from "../components/AppliedContainer";
import SideMenuGeneral from "../components/SideMenuGeneral";
import AppliedPanel from "../components/AppliedPanel";
import SkeletonJobPanel from "../components/SkeletonPanel";
import UpgradeModal from "../components/UpgradeModal";
import { useUserData } from '../UserDataContext';
import { useJobs } from '../jobsContext';
import ConfirmModal from "../components/ConfirmModal";
import { collection, addDoc, doc, setDoc, getDoc, increment} from "firebase/firestore"; 
import { db, storage } from "../firebase";
const Applied = () => {
  const { jobs, setJobs, loading, selectedJob, setSelectedJob } = useJobs();
  const {userData} = useUserData();
  
  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert timestamp to milliseconds
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  const removeHtmlTags = (htmlString) => {
    const regex = /(<([^>]+)>)/gi;
     return htmlString? htmlString.replace(regex, '') : "None"
    
  };
  useEffect(() => {
    userData.applied ? setSelectedJob(
      jobs
      .filter((job) => userData.applied.includes(job.guid))[0]) : console.log("");
    
  }, [userData, jobs, setSelectedJob]);

  return (
    <div className="relative bg-neutral-50 w-full h-screen flex flex-row items-start justify-start text-left text-base text-gray-50 font-text-l-regular">
      <div className="self-stretch flex-1 overflow-hidden flex flex-row p-8 items-start justify-start gap-[32px] text-9xl text-gray-700">
        <AppliedContainer formatDate={formatDate} /> 
        {selectedJob ? (
          <AppliedPanel
            title={selectedJob.title}
            posted={selectedJob.pubDate}
            expiry={selectedJob.expiryDate}
            timezone={selectedJob.timezoneRestriction || "Any"}
            category={selectedJob.categories?.[0] || "None"}
            description={removeHtmlTags(selectedJob.description)}
            company={selectedJob.companyName}
            location={selectedJob.locationRestrictions || "Remote"}
            position={selectedJob.position || ""}
            companyLogo={selectedJob.companyLogo || ""}
            guid={selectedJob.applicationLink || ""}
          />
        ) : (
          <SkeletonJobPanel />
        )}
      </div>
    </div>
  );
};

export default Applied;