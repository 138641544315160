import React from "react";

const SignOutButton = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className="cursor-pointer [border:none] py-0 px-7 bg-[transparent] self-stretch h-[46px] shrink-0 flex flex-row box-border items-center justify-start gap-[12px] rounded-none focus:outline-none"
      >
        <img className="relative w-[22px] h-[22px] shrink-0 overflow-hidden" alt="" src="/exit.svg" />
        <div className="flex-1 relative text-mini tracking-[-0.1px] leading-[2px] font-semibold font-text-l-regular text-gray-50 text-left">
          Sign Out
        </div>
        <img className="relative w-6 h-6 shrink-0 overflow-hidden hidden" alt="" src="/chevrondown1.svg" />
      </button>
    );
  };
  
  export default SignOutButton;