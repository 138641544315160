import React, { createContext, useContext, useState, useEffect } from 'react';
import { useUserData } from './UserDataContext';
import { collection, addDoc, doc, setDoc, getDoc, increment} from "firebase/firestore"; 
import { db, storage } from "./firebase";
const PaymentContext = createContext();

export const usePayment = () => {
  return useContext(PaymentContext);
};

export const PaymentProvider = ({ children }) => {
  const {userData, setUserData, user, isAuthenticated, currentApi} = useUserData();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeFeatureModal, setUpgradeFeatureModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState([0, false]);

  // NEW
  const [appsUsed, setAppsUsed] = useState();
  const [cutoffDate, setCutoffDate] = useState();
  useEffect(() => {
    const loadNewData = async () => {
      await fetchSubscriptionData();
    }
    // check for flag trigger
    if(isAuthenticated && (userData.customer || currentPlan == 0)){
      if(userData.customer){
        setUserData({...userData, customer: false});
      }
      loadNewData();
    }
  })

  const confirmPayment = async (val, monthYear) => {
    if(!isAuthenticated) throw new Error("Not Authenticated");
    else{
      if((currentPlan == 2 && val != 2) || currentPlan == 3){
        setConfirmModalOpen([val, monthYear]);
        return
      }
      else{
        await handlePayment(val, monthYear);
      }
      /*
      let cust = userData.customer;
      if(userData.customer == undefined || userData.customer == null){
        await handlePayment(val, monthYear);
      }
      else{
        const currentSub = await checkStatus(cust);
        //check if plan is active
        //only open modal if downgrading plan
        if((currentSub[1] == 2 && val != 2) || currentSub[1] == 3){
          setConfirmModalOpen([val, monthYear]);
          return
        }
        else{
          await handlePayment(val, monthYear);
        }
      }
      */
    }
  }
  const handlePayment = async (plan, my) => {
    if(!isAuthenticated) throw new Error("Not Authenticated");
    else{
      if(plan == 3){
        await deleteSub();
        return;
      }
      let monthyear = 'month'
      /*if(plan==3){
        await deleteSub();
        return
      }
      let cust = userData.customer;
      if(userData.customer == undefined || userData.customer == null){
        cust = undefined;
      }
      else{
        const currentSub = await checkStatus(cust);
        //check if plan is active
        if(currentSub[1] == 2 || currentSub[1] == 3){
          await deleteSub();
          cust = undefined;
        }
      }*/
      if(my){
        monthyear='year'
        if(plan == 1){
          plan=3;
        }
        else{
          plan=4;
        }
      }
    fetch(`${currentApi}/create-checkout`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            item: {id: plan, quantity: 1},
            authId: user.uid,
            timeframe: monthyear,
        })
    }).then(res => {
        if(res.ok) {
            return res.json()
        }
        else{
          return res.json().then(json => Promise.reject(json))
        }
    }).then(({url}) => {
      window.location=url;

    }).catch(e => {
        console.error(e.error)
    })
    }
}

    /*const saveToFirebase = (data, val) => {
     //console.log(val)
      if(!isAuthenticated) throw new Error("not connected")
      else{
      const docRef = doc(db, "Profiles", (isAuthenticated && user.sub.split("|")[1]));
      setDoc(docRef, data)
        .then(() => {
         //console.log("Document written with ID: ", docRef.id);
          // Save the data to localStorage after it has been Favorites to Firestore
          //localStorage.setItem(isAuthenticated && user.sub.split("|")[1], JSON.stringify(data));
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
      }
    };*/
  /*const checkStatus = async (cust) => {
    if(!isAuthenticated) return;
    if(Object.keys(userData).length == 0) return [0, 0, 0];
    //Returns the number of [apps left, plan id number (1, 2, 3), number of apps sent] in an array of 3 objects
    let checkCustomer = userData.customer;
    if(cust){
      checkCustomer=cust;
    }
    const currentDate = new Date();
    if(checkCustomer == undefined || checkCustomer == null){   
      //free plan
      if(userData.timestamp == undefined || userData.plan != 1){
        //the user's first time
        setUserData({...userData, timestamp: [currentDate.getMonth(), currentDate.getDate(), currentDate.getFullYear()], numberApplied: 0, applicationsLeft: 10, plan: 1});
        saveToFirebase({...userData, timestamp: [currentDate.getMonth(), currentDate.getDate(), currentDate.getFullYear()], numberApplied: 0, applicationsLeft: 10, plan: 1}, 1);
        return [10, 1, 0];
      }

      else if(
        // if the year is the same and the month is the same
        (userData.timestamp[2] == currentDate.getFullYear() 
        && ((currentDate.getMonth()==userData.timestamp[0]) 
        // if the month is one off but its still within a month
        || (currentDate.getMonth()-userData.timestamp[0] == 1 && currentDate.getDate() < userData.timestamp[1])))
        // if its January vs December of different years
        || (currentDate.getFullYear()-userData.timestamp[2] == 1
        && userData.timestamp[0] == 11
        && currentDate.getMonth() == 0
        && currentDate.getDate() < userData.timestamp[1])
      ){
        // dont need to update free apps
        return [userData.applicationsLeft, 1, userData.numberApplied];
      }
      else{
        // set new timestamp to now and refresh apps
        setUserData({...userData, timestamp: [currentDate.getMonth(), currentDate.getDate(), currentDate.getFullYear()], applicationsLeft: 10, numberApplied: 0, plan: 1});
        saveToFirebase({...userData, timestamp: [currentDate.getMonth(), currentDate.getDate(), currentDate.getFullYear()], applicationsLeft: 10, numberApplied: 0, plan: 1}, 3);
        return [10, 1, 0];
      }
    }
    const currPlan = await fetchSubscriptionData();
    //boosted plan
    if(currPlan[0] == 2){
      // first time
      if(userData.applicationsLeft == undefined || userData.applicationsLeft+userData.numberApplied < 148 || userData.applicationsLeft+userData.numberApplied > 152 || userData.plan != 2){
        setUserData({...userData, timestamp: [currPlan[1]-1, currPlan[2], currPlan[3]], applicationsLeft: 150, numberApplied: 0, plan: 2})
        saveToFirebase({...userData, timestamp: [currPlan[1]-1, currPlan[2], currPlan[3]], applicationsLeft: 150, numberApplied: 0, plan: 2}, 4);
        return [150, 2, userData.numberApplied];
      }
      // not first time
      else{
        return [userData.applicationsLeft, 2, userData.numberApplied];
      }
    }
    if(currPlan[0] == 3){
      // first time
      let savedData;
      if(userData.timestamp == undefined || userData.applicationsLeft+userData.numberApplied < 200 || userData.plan != 3){
        savedData = {...userData, timestamp: [currPlan[1]-1, currPlan[2], currPlan[3]], applicationsLeft: 500, numberApplied: 0, plan: 3};
        setUserData({...userData, timestamp: [currPlan[1]-1, currPlan[2], currPlan[3]], applicationsLeft: 500, numberApplied: 0, plan: 3});
        saveToFirebase(savedData, 6);
        return [500, 3, userData.numberApplied];
      }
      //If not set numberapplied
      else{
        return [userData.applicationsLeft, 3, userData.numberApplied];
      }
    }
    //failsafe
    return ([0, 1, 0])
  }*/
  const fetchSubscriptionData = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch(`${currentApi}/retrieve-subscription-data`, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            authId: user.uid,
          })
        });
  
        if (res.ok) {
          /*const { subData, billingMonth, billingDay, billingYear } = await res.json();
          resolve([subData, billingMonth, billingDay, billingYear]);*/
          const { subData, appsUsed, cutoff } = await res.json();
          setCurrentPlan(subData);
          setAppsUsed(appsUsed);
          setCutoffDate(new Date(cutoff));
         //console.log([subData, appsUsed, cutoff]);
          resolve([subData, appsUsed, cutoff]);
        } else {
          const json = await res.json();
          reject(json);
        }
      } catch (e) {
        console.error(e.error);
        reject(e);
      }
    });
  }
  //const incrementApp = (val) => {
    /*const updatedUserData = {...userData, numberApplied: parseInt(userData.numberApplied) + parseInt(val), applicationsLeft:parseInt(userData.applicationsLeft)-parseInt(val)}
    saveToFirebase(updatedUserData, 7);
    setUserData(updatedUserData);*/
    //setAppsUsed(appsUsed + val);
    // increment firebase applied thing by val
  //}
  const openUpgrade = async () => {
    /*const status = await checkStatus();
    if(status[0] == 0 && status[1] == 0 && status[2] == 0) {
      return
    }
    setCurrentPlan(status[1]);*/
    if(currentPlan == 0) await fetchSubscriptionData();
    setShowUpgradeModal(true);
  }
  const deleteSub = async () => {
    try {
      const res = await fetch(`${currentApi}/delete-subscription`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          authId: user.uid
        })
      });
  
      if (res.ok) {
        /*setUserData({...userData, customer: null, applicationsLeft: 10, numberApplied: 0, plan: 1});
        saveToFirebase({...userData, customer: null, applicationsLeft: 10, numberApplied: 0, plan: 1}, 8)*/
        await fetchSubscriptionData();
      } else {
        const json = await res.json();
        throw json;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  return (
    <PaymentContext.Provider value={{cutoffDate, appsUsed, setAppsUsed, showUpgradeModal, setShowUpgradeModal, upgradeFeatureModal, setUpgradeFeatureModal, currentPlan, setCurrentPlan, confirmModalOpen, setConfirmModalOpen,
        confirmPayment, handlePayment, fetchSubscriptionData, openUpgrade, deleteSub}
    }>
      {children}
    </PaymentContext.Provider>
  );
};
