import { motion } from 'framer-motion';
import { usePayment } from '../PaymentContext';
const ConfirmModal = () => {
  const {confirmModalOpen, setConfirmModalOpen, handlePayment} = usePayment();
    const accept = async () => {
        await handlePayment(confirmModalOpen[0], confirmModalOpen[1]);
        setConfirmModalOpen([0, false])
    }
    return confirmModalOpen[0] > 0 ? (
        <motion.div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={() => setConfirmModalOpen([0, false])}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <motion.div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg w-[406px]"
          initial={{ opacity: 0}}
          animate={{ opacity: 1}}
          exit={{ opacity: 0}}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
        <div className="relative rounded-xl [background:linear-gradient(180deg,_#effaf4,_#fff)] w-full overflow-hidden flex flex-col items-center justify-center text-center text-[34px] text-f4e font-text-l-regular">
          <div className="w-[406px] h-[325px]  shrink-0 flex flex-col items-start justify-start">
            <div className="self-stretch relative h-[354px] shrink-0">
              <img
                className="absolute top-[0px] left-[0px] w-[406px] h-[354px] object-cover"
                alt=""
                src="/frame-5761-1@2x.png"
              />
              <div className="absolute top-[41px] left-[19px] flex flex-col items-center justify-start gap-[24px] mb-4">
                <div className="relative leading-[120%] font-semibold inline-block w-[368px] mb-4">
                  <p className="m-0">Are you sure?</p>
                </div>
                <div className="relative text-base leading-[200%] font-medium inline-block w-[333px] opacity-[0.7]">
                  <p className="m-0">We're sorry to see you go...</p>
                  <p className="m-0">Note: You will immediately lose access</p>
                  <p className="m-0">to your current plan</p>
                </div>
                <div className="flex flex-row gap-[12px]">
                <button
                    className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-100 transition-colors duration-300"
                    onClick={() => setConfirmModalOpen([0, false])}
                >
                    No, go back.
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-mediumseagreen-200 text-base font-medium text-white hover:bg-green-300 transition-colors duration-300"
                  onClick={() => accept()}
                >
                  Yes, cancel.
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
        </motion.div>
      ) : null;
}
export default ConfirmModal;