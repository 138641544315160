import React from 'react';
import { useState, useEffect, useRef } from 'react';
import ScrapedForm from './ScrapedForm';
import styles from './Loader.module.css';
import { motion } from 'framer-motion';
import { collection, addDoc, doc, updateDoc, setDoc, getDoc, arrayUnion} from "firebase/firestore";
import { db, storage } from "../firebase";
import { useUserData } from '../UserDataContext';
import { onSnapshot } from "firebase/firestore";
import { useJobs } from '../jobsContext';
import Rive from '@rive-app/react-canvas';
import { usePayment } from '../PaymentContext';
import Confetti from 'react-confetti';

const ApplicationModal = ({ isOpen, onClose, appLink, company, guid}) => {
  const [finalUrl, setFinalUrl] = useState('');
  const [formInfo, setFormInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const {user, userData, setUserData, currentApi} = useUserData();
  const {selectedJob, setSelectedJob, newApplied, setNewApplied} = useJobs();
  const [showButton, setShowButton] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [newApp, setNewApp] = useState(false);
  const [errored, setErrored] = useState(false);
  const modalRef = useRef();
  const {appsUsed, setAppsUsed, setUpgradeFeatureModal, fetchSubscriptionData} = usePayment();

  const Success = () => (
    <Rive src="/success.riv" className="min-w-[250px] min-h-[250px]"></Rive>
  );

  useEffect(() => {
    const requiredFields = formInfo.filter(info => info.required && info.type !== 'file');
    const allRequiredFieldsFilled = requiredFields.every(field => {
      if (field.tag === 'select' && field.options) {
        const validOptions = field.options.map(option => option.value);
        return validOptions.includes(inputValues[field.label]) && inputValues[field.label] !== field.options[0].value;
      }
      return !!inputValues[field.label];
    });

    setIsFormReady(allRequiredFieldsFilled);
  }, [inputValues, formInfo]);

  useEffect(() => {
    if (modalRef.current) {
      const initialSize = { width: 'initialWidth', height: 'initialHeight' };
      const biggerSize = { width: 'biggerWidth', height: 'biggerHeight' };

      // Animate the modal size when newApp state changes
      if (newApp) {
        modalRef.current.animate(initialSize, biggerSize, {
          duration: 0.4,
          easing: 'easeInOut',
        });
      } else {
        modalRef.current.animate(biggerSize, initialSize, {
          duration: 0.4,
          easing: 'easeInOut',
        });
      }
    }
  }, [newApp]);


  useEffect(() => {
    if (isOpen && appLink) {
      setIsLoading(true);
      (async () => {
        const response = await fetch(`${currentApi}/get-form`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ appLink: appLink }),
        });

        if (response.ok) {
          const { finalUrl, formInfo} = await response.json();
          setFinalUrl(finalUrl);
          setFormInfo(formInfo);
        } else {
          console.error('Error while fetching form information');
        }
        setIsLoading(false);
      })();
      return () => {
        setFinalUrl('');
        setFormInfo([]);
        setNewApp(false);
      };
    }
  }, [isOpen, appLink]);


  const handleSubmit = async () => {
    const subdata = await fetchSubscriptionData();
    if((subdata[0] == 1 && 50-subdata[1] <=0) || (subdata[0] == 2 && 150-subdata[1] <=0) || (subdata[0] == 3 && 500-subdata[1] <=0)){
      setUpgradeFeatureModal(true);
      onClose();
      return
    }
    const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
    const jobRef = hashCode(guid).toString();
    const userId = user.uid;
    setUserData(prevUserData => ({
      ...prevUserData,
      applied: [...prevUserData.applied, guid]
    }));
    setNewApplied(true);
    setAppsUsed(appsUsed+1);
    onClose();
    // Map the new values onto the formInfo values
    const updatedFormInfo = formInfo.map((input) => {
      const newValue = inputValues[input.label];
      const { required, options, ...inputWithoutRequiredOrOptions } = input;
      return { ...inputWithoutRequiredOrOptions, value: newValue || input.value };
    });

    try {
      const response = await fetch(`${currentApi}/apply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          authId: userId, 
          applications: [
            { 
              url: finalUrl, 
              inputs: updatedFormInfo, 
              profileId: userId, 
              jobId: jobRef, 
              coverLetter: "" 
            }
          ]
        }),
      });      

      if (response.ok) {
        setShowButton(false);
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          onClose();
        }, 2000);
      } else {
        console.error('Error while submitting the job application');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return isOpen ? (
    <motion.div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
      {showSuccess && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={200} />}
      <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={onClose}>
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <motion.div
        ref={modalRef}
        className="z-20 inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full max-h-[90%]"
        initial={{ width: "initialWidth", height: "initialHeight", opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        <div className="bg-white p-6 flex flex-col">
          <div className="mt-3 text-center sm:text-left">
            <div className={`overflow-y-auto max-h-[600px] ${errored ? "w-full": "w-full"} min-h-[400px]`}>
              {errored ? (
                <div className="relative bg-base-white shadow-[0px_20px_24px_-4px_rgba(16,_24,_40,_0.08),_0px_8px_8px_-4px_rgba(16,_24,_40,_0.03)] w-full overflow-hidden flex flex-col items-start justify-start text-left text-lg text-gray-900 font-text-sm-semibold">
                <div className="self-stretch flex-1 flex flex-col items-start justify-start gap-[48px]">
                  <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
                    <div className="self-stretch flex flex-col items-start justify-start gap-[2px]">
                      <div className="self-stretch relative leading-[28px] font-semibold">
                        Whoops! Something went wrong.
                      </div>
                      <div className="self-stretch relative text-base leading-[24px] text-gray-600">
                        It looks like this job is either expired, or
                        the application exceeded the models context length.
                      </div>
                    </div>
                    <div className="self-stretch py-12 flex flex-col items-center justify-center">
                      <img
                        className="relative w-[390px] h-[258px] object-cover"
                        alt=""
                        src="/error.png"
                      />
                    </div>
                  </div>
                  <div className="self-stretch flex flex-row items-start justify-center gap-[12px] text-sm text-gray-700">
                    <div className="flex-1 rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row py-2.5 px-4 items-center justify-center border-[1px] border-solid border-gray-300 cursor-pointer" onClick={() => { onClose(); setErrored(false); }}>
                      <div className="relative leading-[20px] font-semibold">
                        Go Back
                      </div>
                    </div>
                    <div className="flex-1 rounded-lg bg-mediumseagreen-100 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row py-2.5 px-4 items-center justify-center text-base-white border-[1px] border-solid border-mediumseagreen-100 cursor-pointer" onClick={() => { onClose(); setErrored(false); }}>
                      <div className="relative leading-[20px] font-semibold">Report Error</div>
                    </div>
                  </div>
                </div>
                </div>
              ) : showSuccess ? 
              <div className="flex items-center justify-center min-h-[400px] min-w-[100%]">
                <Success />
              </div>
              :
              <ScrapedForm 
                  appLink={appLink} 
                  errored={errored} 
                  setErrored={setErrored} 
                  company={company} 
                  inputValues={inputValues} 
                  setInputValues={setInputValues} 
                  newApp={newApp} 
                  setNewApp={setNewApp} 
              />
            }
            </div>
            {newApp && !errored ? (
              <div className="flex justify-center mt-4 gap-12">
                <button
                onClick={onClose}
                  className="cursor-pointer py-3 px-6 text-base-white bg-transparent rounded-md flex items-center justify-center gap-[6px] focus:outline-none hover:bg-neutral-200 rounded-3xs border-[1px] border-solid border-neutral-600"
                >
                  <div className="relative text-mini leading-[22px] text-gray-700 font-semibold font-text-l-regular">
                    Cancel
                  </div>
                </button>
                <button
                  className={`cursor-pointer py-3 px-6 rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex items-center justify-center gap-[6px] focus:outline-none transition-colors duration-300 ${isFormReady ? "bg-mediumseagreen-200 text-base-white hover:bg-green-300" : "bg-gray-200 text-gray-500 cursor-not-allowed"}`}
                  onClick={handleSubmit}
                  disabled={!isFormReady}
                >
                  <div className="relative text-mini leading-[22px] font-semibold font-text-l-regular">
                    Submit Application
                  </div>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </motion.div>
    </motion.div>
  ) : null;

  };

  export default ApplicationModal;
