import { FieldValue } from "@firebase/firestore";
import {useState, useEffect} from 'react';
import {motion} from 'framer-motion';
import { usePayment } from "../PaymentContext";
import { useUserData } from "../UserDataContext";
const UpgradeModal = () => {
  const {showUpgradeModal, setShowUpgradeModal, currentPlan, confirmPayment, cutoffDate} = usePayment();
    const {userData, setUserData} = useUserData();
    const [currplan, setCurrplan] = useState([]);
    const [styles, setStyles] = useState([]);
    const [stylestext, setStylestext] = useState([]);
    const [monthYearToggle, setMonthYearToggle] = useState(false);
    //const [timeframe, setTimeframe] = useState([0, 0, 0]);
    useEffect(() => {
      if(currentPlan == 2){
        setCurrplan(["Downgrade Plan", "Current Plan", "Upgrade Plan"]);
        setStyles(["#5cd193", "#f4fbf7", "#5cd193"]);
        setStylestext(["#FFFFFF", "#5cd193", "#FFFFFF"]);
      }
      else if(currentPlan == 3){
        setCurrplan(["Downgrade Plan", "Downgrade Plan", "Current Plan"]);
        setStyles(["#5cd193", "#5cd193", "#f4fbf7"]);
        setStylestext(["#FFFFFF", "#FFFFFF", "#5cd193"]);
      }
      else{
        setCurrplan(["Current Plan", "Upgrade Plan", "Upgrade Plan"]);
        setStyles(["#f4fbf7", "#5cd193", "#5cd193"]);
        setStylestext(["#5cd193", "#FFFFFF", "#FFFFFF"]);
      }
      /*if(userData.timestamp != undefined){
        // m d y
        // check december
        if(userData.timestamp[0]==12){
          setTimeframe([0, userData.timestamp[1], userData.timestamp[2]]);
        }
        // check january
        else if(userData.timestamp[0] == 1 && userData.timestamp[1] > 28){
          setTimeframe([userData.timestamp[0]+1, 28, userData.timestamp[2]]);
        }
        // not july
        else if(userData.timestamp[0] != 7 && userData.timestamp[1] > 30){
          setTimeframe([userData.timestamp[0]+1, 30, userData.timestamp[2]]);
        }
        else{
          setTimeframe([userData.timestamp[0]+1, userData.timestamp[1], userData.timestamp[2]]);
        }
      } else {
       //console.log("no time stamp");
      }*/
    }, [currentPlan, showUpgradeModal])
    const validateRequest = async (val) => {
      if(val == 2 && currentPlan != 2){
        await confirmPayment(1, monthYearToggle);
        setShowUpgradeModal(false)
      }
      else if(val == 3 && currentPlan != 3){
        await confirmPayment(2, monthYearToggle);
        setShowUpgradeModal(false)
      }
      else if(val == 1 && currentPlan != 1){
        await confirmPayment(3, monthYearToggle);
        setShowUpgradeModal(false)
      }
    }
    const handleToggle = () => {
      setMonthYearToggle(!monthYearToggle);
    };
    if(!showUpgradeModal) return null;
    return(
      <motion.div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={() => setShowUpgradeModal(false)}>
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div className="flex flex-col relative rounded-mini bg-base-white box-border shrink-0 border-[2px] border-solid border-bg-color-2 pb-[20px] pl-[20px] pr-[20px] pt-[20px]">
      <motion.div className="flex flex-col items-center justify-start gap-[20px] text-left text-sm text-e"
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        exit={{ opacity: 0}}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
      <div className="absolute left-0 top-2 ml-3 text-left text-mini font-semibold text-gray-50">
        {(cutoffDate && cutoffDate != null && cutoffDate.getFullYear() > 2000) ? ("Next Application Renewal: " + (((cutoffDate.getMonth()+2)<=12) ? cutoffDate.getMonth()+2 : 0) + "/" + cutoffDate.getDate() + "/" + cutoffDate.getFullYear()) : null}
      </div>
      <div className="text-9xl  font-semibold text-f4e">
        Select Your Plan
      </div>
      <div className="flex flex-row">
      <span className="mr-[40px] text-sm font-medium text-gray-900 dark:text-gray-300">
        Monthly
      </span>
      <div
        onClick={handleToggle}
        className={`relative inline-block w-11 h-6 cursor-pointer rounded-full transition-all translate-y-[-3px] translate-x-[-5px] ${
          monthYearToggle ? 'bg-mediumseagreen-100' : 'bg-gray-200'
        }`}
      >
        <div
          className={`absolute w-5 h-5 bg-white border border-gray-300 rounded-full transition-all ${
            monthYearToggle ? 'translate-x-6' : 'translate-x-1'
          }`}
          style={{ top: '2px', left: '-2px' }}
        ></div>
      </div>
      <span className="ml-[40px] text-sm font-medium text-gray-900 dark:text-gray-300 -translate-x-[14px]">
        Yearly
      </span>
    </div>
        <div className="flex flex-row gap-[20px]">
            <div className="relative rounded-mini bg-base-white box-border w-[350px] h-[634px] shrink-0 border-[2px] border-solid border-bg-color-2">
              <button className={(currplan[0] == "Current Plan") ? "cursor-disabled [border:none] p-0 bg-mintcream-100 absolute h-[10.41%] w-[82.86%] top-[81.55%] right-[6.57%] bottom-[8.04%] left-[10.57%] rounded-3xs" : "cursor-pointer [border:none] p-0 bg-mediumseagreen-100 absolute h-[10.41%] w-[82.86%] top-[81.55%] right-[6.57%] bottom-[8.04%] left-[10.57%] rounded-3xs hover:bg-green-300 transition-colors duration-300"}// style={{backgroundColor: styles[0]}}
                onClick={() => validateRequest(1)}
              >
                <div className="absolute w-[80.09%] top-[31.82%] left-[10%] text-xl leading-[24px] font-medium font-text-l-regular text-mediumseagreen-100 text-center inline-block" style={{color: stylestext[0]}}>
                  {currplan[0]}
                </div>
              </button>
              <div className="absolute top-[412px] left-[41.72px] w-[195.28px] h-[23.98px]">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Cover letter generation
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-[23.98px]"
                  alt=""
                  src="/checkmark11.svg"
                />
              </div>
              <div className="absolute top-[452px] left-[41.72px] w-[142.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Future features
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark12.svg"
                />
              </div>
              <div className="absolute top-[372px] left-[41.72px] w-[139.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Basic AI model
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark13.svg"
                />
              </div>
              <div className="absolute top-[332px] left-[41.72px] w-[201.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  50 applications a month
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark14.svg"
                />
              </div>
              <div className="absolute top-[292px] left-[41.72px] w-[193.28px] h-[23.98px]">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Access to 1-click apply
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-[23.98px]"
                  alt=""
                  src="/checkmark15.svg"
                />
              </div>
              <div className="absolute top-[260.06px] left-[21px] bg-bg-color-2 w-[308.56px] h-[1.56px]" />
              <div className="absolute top-[200.69px] left-[101px] text-base leading-[22px] font-medium">
                {monthYearToggle ? "Per Year" : "Per Month"}
              </div>
              <div className="absolute top-[178.3px] left-[39.75px] text-23xl leading-[51px] font-semibold text-f4e">
                $0
              </div>
              <div className="absolute top-[43.12px] left-[39.36px] text-9xl leading-[39px] font-semibold text-f4e">
                Basic
              </div>
            </div>
            <div className="relative rounded-mini bg-base-white shadow-[0px_20px_47px_rgba(0,_0,_0,_0.05)] box-border w-[350px] h-[634px] shrink-0 border-[2px] border-solid border-bg-color-2">
              <button onClick={() => validateRequest(2)} className={(currplan[1] == "Current Plan") ? "cursor-disabled [border:none] p-0 bg-mintcream-100 absolute h-[10.41%] w-[82.86%] top-[81.55%] right-[6.57%] bottom-[8.04%] left-[10.57%] rounded-3xs" : "cursor-pointer [border:none] p-0 bg-mediumseagreen-100 absolute h-[10.41%] w-[82.86%] top-[81.55%] right-[6.57%] bottom-[8.04%] left-[10.57%] rounded-3xs hover:bg-green-300 transition-colors duration-300"} //style={{backgroundColor: styles[1]}}
              >
                <div className="absolute w-[80.09%] top-[31.82%] left-[10%] text-xl leading-[24px] font-medium font-text-l-regular text-base-white text-center inline-block"style={{color: stylestext[1]}}>
                  {currplan[1]}
                </div>
              </button>
              <div className="absolute top-[412px] left-[41.72px] w-[195.28px] h-[23.98px]">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Cover letter generation
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-[23.98px]"
                  alt=""
                  src="/checkmark11.svg"
                />
              </div>
              <div className="absolute top-[452px] left-[41.72px] w-[142.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Future features
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark12.svg"
                />
              </div>
              <div className="absolute top-[372px] left-[41.72px] w-[165.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Improved AI model
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark13.svg"
                />
              </div>
              <div className="absolute top-[332px] left-[41.72px] w-[207.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  150 applications a month
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark14.svg"
                />
              </div>
              <div className="absolute top-[292px] left-[41.72px] w-[200.28px] h-[23.98px]">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Access to auto-apply
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-[23.98px]"
                  alt=""
                  src="/checkmark15.svg"
                />
              </div>
              <div className="absolute top-[260.06px] left-[21px] bg-bg-color-2 w-[308.56px] h-[1.56px]" />
              <div className="absolute top-[201px] text-base leading-[22px] font-medium" style={{left: monthYearToggle ? "196px" : "167px"}}>
                {monthYearToggle ? "Per Year" : "Per Month"}
              </div>
              <div className="absolute top-[178.3px] left-[39.75px] text-23xl leading-[51px] font-semibold text-f4e">
                {monthYearToggle ? "$34.99" : "$4.99"}
              </div>
              <div className="absolute top-[43.12px] left-[39.36px] text-9xl leading-[39px] font-semibold text-f4e">
                Boosted
              </div>
            </div>
            <div className="relative rounded-mini bg-base-white shadow-[0px_20px_47px_rgba(0,_0,_0,_0.05)] box-border w-[350px] h-[634px] shrink-0 border-[2px] border-solid border-bg-color-2">
              <button className={(currplan[2] == "Current Plan") ? "bg-mintcream-100 cursor-disabled [border:none] p-0 absolute h-[10.41%] w-[82.86%] top-[81.55%] right-[6.57%] bottom-[8.04%] left-[10.57%] rounded-3xs" : "bg-mediumseagreen-100 cursor-pointer [border:none] p-0 absolute h-[10.41%] w-[82.86%] top-[81.55%] right-[6.57%] bottom-[8.04%] left-[10.57%] rounded-3xs hover:bg-green-300 transition-colors duration-300"} //style={{backgroundColor: styles[2]}}
              >
                <div onClick={() => validateRequest(3)} className="absolute w-[80.09%] top-[31.82%] left-[10%] text-xl leading-[24px] font-medium font-text-l-regular text-base-white text-center inline-block" style={{color: stylestext[2]}}>
                  {currplan[2]}
                </div>
              </button>
              <div className="absolute top-[412px] left-[41.72px] w-[262.28px] h-[23.98px]">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">{`Resume & cover letter generation `}</div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-[23.98px]"
                  alt=""
                  src="/checkmark11.svg"
                />
              </div>
              <div className="absolute top-[452px] left-[41.72px] w-[142.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Future features
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark12.svg"
                />
              </div>
              <div className="absolute top-[372px] left-[41.72px] w-[133.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Best AI model
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark13.svg"
                />
              </div>
              <div className="absolute top-[332px] left-[41.72px] w-[220.28px] h-6">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  500 applications a month
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-6"
                  alt=""
                  src="/checkmark14.svg"
                />
              </div>
              <div className="absolute top-[292px] left-[41.72px] w-[183.28px] h-[23.98px]">
                <div className="absolute top-[1px] left-[41.28px] leading-[22px]">
                  Access to auto-apply
                </div>
                <img
                  className="absolute top-[0px] left-[0px] w-6 h-[23.98px]"
                  alt=""
                  src="/checkmark15.svg"
                />
              </div>
              <div className="absolute top-[260.06px] left-[21px] bg-bg-color-2 w-[308.56px] h-[1.56px]" />
              <div className="absolute top-[201px] left-[205px] text-base leading-[22px] font-medium" style={{left: monthYearToggle ? "194px" : "166px"}}>
                {monthYearToggle ? "Per Year" : "Per Month"}
              </div>
              <div className="absolute top-[177px] left-[39.5px] text-23xl leading-[51px] font-semibold text-f4e">
                {monthYearToggle ? "$69.99" : "$9.99"}
              </div>
              <div className="absolute top-[43.12px] left-[39.36px] text-9xl leading-[39px] font-semibold text-f4e">
                Premium
              </div>
            </div>
            </div>
          </motion.div>
          </div>
        </motion.div>
    )
}
export default UpgradeModal