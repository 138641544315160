import { createContext, useContext, useState, useEffect, useRef, useCallback, useMemo } from "react";
import { db } from "./firebase"; // Import the db instance
import { collection, query, orderBy, limit, getDocs, startAfter, getDoc, doc, setDoc, where, onSnapshot } from "firebase/firestore"; // Import required Firestore functions
import stringSimilarity from 'string-similarity';
import { useUserData } from "./UserDataContext";

const JobsContext = createContext();

export const useJobs = () => useContext(JobsContext);
const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);

const tagMap = {
  engineering: [['engineer', 2], ['developer', 1], ['programmer', 1], ['devops', 1], ['engineering', 2]],
  sales: [['sales', 2], ['business development', 1], ['account management', 1]],
  marketing: [['marketing', 2], ['branding', 1], ['promotion', 1]],
  product: [['product management', 1], ['product design', 1], ['ux design', 1], ['product', 1]],
  design: [['graphic design', 1], ['web design', 1], ['visual design', 1], ['design', 2], ['ui/ux', 1]],
  support: [['customer support', 1], ['technical support', 1], ['customer success', 1], ['clerk', 1]],
  operations: [['operations', 1], ['logistics', 1], ['supply chain', 1]],
  data: [['data analysis', 1], ['data science', 1], ['business intelligence', 1], ['writing', 2]],
  finance: [['finance', 1], ['accounting', 1], ['tax', 1], ['treasury', 1]],
  management: [['management', 1], ['leadership', 1], ['strategy', 1], ['manager', 2]]
};

const findTags = (category, tagMap, threshold = 0.6) => {
  const words = category.split(/\W+/);
  const tagScores = Object.keys(tagMap).map(tag => {
    const keywords = tagMap[tag];
    const wordScores = keywords.map(([keyword, weight]) => stringSimilarity.compareTwoStrings(keyword, category) * weight);
    const tagScore = Math.max(...wordScores);
    return { tag, score: tagScore };
  });

  const sortedTagScores = tagScores.sort((a, b) => b.score - a.score);
  const bestTags = sortedTagScores.filter(tagScore => tagScore.score >= threshold).map(tagScore => tagScore.tag);

  return bestTags.slice(0, 4);
};

  async function fetchApplyNowLink(jobListingURL) {
    try {
      const corsProxy = "https://proxy.cors.sh/";
      const apiKey = "temp_c0b08eecac510781eea79da0ed63963c";
      const response = await fetch(corsProxy + jobListingURL, {
        headers: {
          "x-cors-api-key": apiKey,
        },
      });
      let html = await response.text();

      // Remove the CORS proxy URL from the fetched HTML
      html = html.replace(new RegExp(corsProxy, "g"), "");

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      // Assuming the "Apply Now" button has a specific class or id, e.g., 'apply-now-button'
      const applyNowButton = doc.querySelector(".btn.btn-primary.btn-xl"); // Replace the selector with the correct one from the Himalayas website.
      const applyNowLink = applyNowButton.getAttribute("href");

      return applyNowLink;
    } catch (error) {
      console.error("Error fetching Apply Now link:", error);
      return null;
    }
  }

  const stripUrlParams = (url) => {
    const urlObj = new URL(url);
    return `${urlObj.origin}${urlObj.pathname}`;
  }
  

const processJobs = async (jobs) => {
  const modifiedJobs = [];

  for (const job of jobs) {
    const categories = job.categories || job.category || [];
    if (categories.length === 0) {
      continue;
    }
    const categoryTags = categories.flatMap(category => {
      const tags = findTags(category.toLowerCase(), tagMap);
      return tags;
    });
    const uniqueTags = [...new Set(categoryTags)];
    const tags = ['engineering', 'sales', 'marketing', 'product', 'design', 'support', 'operations', 'data', 'finance', 'management'];
    const sortedTags = uniqueTags.sort((a, b) => tags.indexOf(a) - tags.indexOf(b));
    const selectedTags = sortedTags.slice(0, 10);

    // Create a hashed ID for the job based on the guid

    const hashedId = hashCode(job.guid).toString();

    // Fetch the Apply Now link
    const applyNowLink = await fetchApplyNowLink(job.applicationLink); // TODO: do this for each job as they're being added to the database

    if (applyNowLink) {
      const cleanedApplyNowLink = stripUrlParams(applyNowLink);
      const currentApi = "https://app.wranglejobs.com/api"
      const response = await fetch(`${currentApi}/get-link`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ appLink: cleanedApplyNowLink }),
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        const { link } = jsonResponse;

        const origin = new URL(link).origin;
        if (
          origin.includes("ashbyhq.com") ||
          origin.includes("workable.com") ||
          origin.includes("lever.co") ||
          origin.includes("greenhouse.io")
        ) {
          const modifiedJob = {
            id: hashedId,
            ...job,
            categories: selectedTags.length > 0 ? selectedTags : null,
            locationRestrictions: job.locationRestrictions && job.locationRestrictions.length > 0 ? job.locationRestrictions.slice(0, 2) : null,
            applyNowLink: link,
          };
          modifiedJobs.push(modifiedJob);
        } else {
         //console.log("Job is not valid");
        }
      }
    }
  }

  return modifiedJobs;
};

async function writeJobs(modifiedJobs) {

  // Store each job in its own document
  const writeJobPromises = modifiedJobs.map(job => async () => {
    const jobDoc = doc(db, "Jobs", job.id);
    await setDoc(jobDoc, job);
  });

  // Write jobs with jobLimited concurrency
  const writeBatchSize = 10;
  for (let i = 0; i < writeJobPromises.length; i += writeBatchSize) {
    const batchedWrites = writeJobPromises.slice(i, i + writeBatchSize);
    await Promise.all(batchedWrites.map(write => write()));
  }
};


const fetchAllJobs = async () => {
  const jobLimit = 10;

  const fetchJobsFromHimalayas = async (offset, jobLimit) => {
    const response = await fetch(`https://himalayas.app/jobs/api?jobLimit=${jobLimit}&offset=${offset}`);
    const data = await response.json();
    return data.jobs || [];
  };

  const fetchJobsFromFirebase = async () => {
    const jobsRef = collection(db, "Jobs");
    const q = query(jobsRef, orderBy("pubDate", "desc"), limit(100));
    const querySnapshot = await getDocs(q);
    const firebaseJobs = [];
    querySnapshot.forEach((doc) => {
      firebaseJobs.push(doc.data());
    });
    return firebaseJobs;
  };

  const firebaseJobs = await fetchJobsFromFirebase();

  let offset = 0;
  let stopFetching = false;
  while (true && !stopFetching) {
    const jobsChunk = await fetchJobsFromHimalayas(offset, jobLimit);
    if (jobsChunk.length === 0 || jobsChunk.length < jobLimit) {
      break;
    }

    const newJobs = [];
    for (const job of jobsChunk) {
      const existingJob = firebaseJobs.find((j) => j.guid === job.guid);
      if (!existingJob) {
        newJobs.push(job);
      } else {
        stopFetching = true;
        break;
      }
    }

    if (newJobs.length > 0) {
      const modifiedJobs = await processJobs(newJobs);
      await writeJobs(modifiedJobs);
    }

    offset += jobLimit;
  }
};



async function fetchJobsFromFirebase(userData, setJobs, setLoading, lastVisibleDoc) {

  //console.log(userData);

  const jobsRef = collection(db, "Jobs");
  let q = query(jobsRef, orderBy("pubDate", "desc"), limit(100));

  if (lastVisibleDoc) {
    q = query(jobsRef, orderBy("pubDate", "desc"), startAfter(lastVisibleDoc), limit(5));
  }

  try {
    const querySnapshot = await getDocs(q);
    const firebaseJobs = [];
    querySnapshot.forEach((doc) => {
      firebaseJobs.push(doc.data());
    });




    // Filter out duplicate jobs before updating the state
    setJobs(prevJobs => {
      const uniqueJobs = firebaseJobs.filter((job) => {
        return !prevJobs.some((prevJob) => prevJob.guid === job.guid);
      });
      return [...prevJobs, ...uniqueJobs];
    });


    setLoading(false);
    return querySnapshot.docs[querySnapshot.docs.length - 1];
  } catch (error) {
    console.error("Error fetching jobs from Firebase:", error);
    setJobs([]);
  }
}


export const JobsProvider = ({ children }) => {
  const {user, userData, setUserData, isAuthenticated} = useUserData();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(jobs[0]);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [showUSOnly, setShowUSOnly] = useState(false);
  const [showRemoteOnly, setShowRemoteOnly] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [screen, setScreen] = useState(null);
  const [fetchedInitialJobs, setFetchedInitialJobs] = useState(false);
  const [newApplied, setNewApplied] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState();
  const [jobslist, setJobslist] = useState();

  useEffect(() => {
    const grabAppliedJobs = async () => {
      const profRef = doc(db, '/Profiles/', user.uid);
      const applicationsRef = collection(db, 'Applications');
      const queryDesc = query(applicationsRef, where('profileRef', '==', profRef), orderBy('submittedAt', 'desc'));
      const queryDescSnapshot = await getDocs(queryDesc);
      let jobsDict = {};

      queryDescSnapshot.forEach((doc) => {
        // Add each doc to the dictionary with its jobRef as the key
        jobsDict[doc.data().jobRef.path.split("/").pop()] = doc.data();
      });

      // Now you can set your state with the jobs dictionary
      setAppliedJobs(jobsDict);
      const joblist = {}
      for (let i = 0; i < Object.keys(jobsDict).length; i++){
        const jobref = doc(db, '/Jobs/', Object.keys(jobsDict)[i]);
        const docSnap = await getDoc(jobref);
        joblist[Object.keys(jobsDict)[i]] = docSnap.data();
      }
      setJobslist(joblist);
    }
    if(isAuthenticated){
      grabAppliedJobs();
    }
  }, [isAuthenticated, newApplied])
  useEffect(() => {
    if(isAuthenticated){
      const profRef = doc(db, '/Profiles/', user.uid);
      const applicationsRef = collection(db, 'Applications');
      const queryDesc = query(applicationsRef, where('profileRef', '==', profRef), orderBy('submittedAt', 'desc'));

      const unsub = onSnapshot(queryDesc, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            setNewApplied(true);
          }
        });
      });

      return () => unsub();
    }
  }, [isAuthenticated])


  const filteredJobs = useMemo(() => {
    if (!jobs.length) {
      return [];
    }
  
    const filtered = jobs.filter((job) => {
      const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
      const isFavorited = userData.favorites ? userData.favorites.includes(job.guid) : false;
      const isWithinApplied = appliedJobs ? !Object.keys(appliedJobs).includes(hashCode(job.guid).toString()) : true;
      const isApplied = userData.applied ? !userData.applied.includes(job.guid) : true;
      const isInSelectedTags = selectedTags.length ? selectedTags.every((tag) => (job.categories || []).includes(tag)) : true;
      const isUSOrRemote = showUSOnly && showRemoteOnly ?
        (job.locationRestrictions && job.locationRestrictions.includes("United States")) || !job.locationRestrictions :
        (!showUSOnly || (job.locationRestrictions && job.locationRestrictions.includes("United States"))) && (!showRemoteOnly || !job.locationRestrictions);
      return isWithinApplied && isApplied && (!showFavoritesOnly || isFavorited) && isInSelectedTags && isUSOrRemote;
    });
  
    return filtered;
  }, [showUSOnly, showRemoteOnly, showFavoritesOnly, selectedTags, jobs, screen, userData, newApplied, appliedJobs ]);
  
  

  const fetchNewJobsFromAPI = useCallback(async () => {
    // TODO: move the fetching of new jobs from himalayas to a serverless function on a cron schedule --
    //  this version:
    //    1) will create a lot of unnecessary firebase reads/writes,
    //    2) create duplicate jobs in the database if multiple users are using the app at the same time
    //    3) could result in an excessively large firebase bill if you have any amount of regular users or traction
    const jobsChunk = await fetchAllJobs();
    if (!jobsChunk) {
      return;
    }
    const newJobs = jobsChunk.filter(job => !jobs.find(j => j.guid === job.guid));
    if(newJobs.length > 0) {
      const batch = writeBatch(db); // TODO: I'm getting an error that `writeBatch` is unresolved -- is it imported?
      newJobs.forEach(job => {
        const jobDoc = doc(db, "Jobs", job.id);
        batch.set(jobDoc, job);
      });
      await batch.commit();
    }
  }, [jobs]);

  async function fetchMoreJobs()  {
    const newLastVisibleDoc = await fetchJobsFromFirebase(setJobs, setLoading, lastVisibleDoc);
    setLastVisibleDoc(newLastVisibleDoc);
  }

  useEffect(() => {
    setNewApplied(false);
  }, [filteredJobs]);

  useEffect(() => {
    if (filteredJobs.length > 0) {
      setSelectedJob(filteredJobs[0]);
    }
  }, [showUSOnly, showRemoteOnly, showFavoritesOnly, selectedTags, jobs, screen, newApplied]);


  useEffect(() => {
    if (!fetchedInitialJobs && (userData.applied != undefined)) {
      fetchJobsFromFirebase(userData, setJobs, setLoading, null)
        .then(() => {
          setFetchedInitialJobs(true);
          fetchNewJobsFromAPI();
        });
    }
  }, [fetchedInitialJobs, userData, fetchNewJobsFromAPI]);


  return (
    <JobsContext.Provider value={{ jobs, setJobs, loading, newApplied, setNewApplied, selectedJob, setSelectedJob, lastVisibleDoc, fetchMoreJobs, showFavoritesOnly, setShowFavoritesOnly, showRemoteOnly, setShowRemoteOnly, showUSOnly, setShowUSOnly, filteredJobs, setScreen, screen, selectedTags, setSelectedTags, appliedJobs, jobslist }}>
      {children}
    </JobsContext.Provider>
  );
};
