import React from 'react';
import {useState, useEffect, useContext, useRef, useMemo} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useJobs } from '../jobsContext';
import { collection, addDoc, doc, updateDoc, getDoc, arrayUnion, onSnapshot} from "firebase/firestore";
import { db, storage } from "../firebase";
import { useUserData } from '../UserDataContext';
import { usePayment} from '../PaymentContext';
import axios from 'axios';
import Rive from 'rive-react';


  const AutoApplyModal = props => {

    const {user, isAuthenticated, currentApi, setUserData, userData} = useUserData();
    const {setSelectedTags, setNewApplied} = useJobs();
    const {appsUsed, setAppsUsed, currentPlan, fetchSubscriptionData} = usePayment();

    const allValuesUndefined = (inputs) => {
      return inputs.every(input => input.value === undefined);
    };
    

    const handleSubmit = async (sliderVal) => {
      props.onClose();
      setSelectedTags([]);

      if(!isAuthenticated) return;
      const checkApps = await fetchSubscriptionData();
      if ((checkApps[0] == 1 && 50-checkApps[1] < sliderVal) || (checkApps[0] == 2 && 150-checkApps[1] < sliderVal) || (checkApps[0] == 3 && 500-checkApps[1] < sliderVal)) {
        props.showUpgrade();
      } else {
        let applications = await Promise.all(
          filteredJobs.slice(0, sliderVal).map(async (job) => {
            try {

              setUserData(prevUserData => ({
                ...prevUserData,
                applied: [...prevUserData.applied, job.guid]
              }));

              setNewApplied(true);
        
        const formInfo = await fetchFormInfo(job.applyNowLink);
      
        const seenQuestions = new Set();
        const prompt = formInfo.filter(({type, label}) => {
          if (type !== "file" && !seenQuestions.has(label)) {
            seenQuestions.add(label);
            return true;
          }
          return false;
        }).map(({selector, options, ...rest}) => {
          if(options && options.length){
            options = options.filter((opt) => !(opt.label.toLowerCase().includes("please select") || opt.label === "--"));
          }
          return `${rest.label}|${rest.type || rest.tag}|${rest.required ? 'Required' : 'Not required'}${options ? '|' + options.map((opt) => opt.label).join(",") : ""}`;
        }).join("\n");

       //console.log("prompt", prompt);
    
        const responses = await openAIWhisper(prompt, job);
        
    
        const updatedFormInfo = formInfo.map((input) => {
          let inputValue = responses[input.label];
          const { required, options, type: inputType, tag: inputTag, ...inputWithoutRequiredOrOptions } = input;
        
          if (Array.isArray(inputValue)) {
            inputValue.forEach((val, idx) => {
              const inputOptionValue = options?.find(({ label }) => label === val || label.includes(val))?.value;
              if (inputOptionValue) {
                inputValue[idx] = inputOptionValue;
              }
            });
          } else if (options && options.length) {
            const val = options.find(({ label }) => label === inputValue || label.includes(inputValue))?.value;
            inputValue = val || inputValue;
          } else if (inputType !== 'text' && inputType !== 'textarea' && inputType !== 'number' && inputTag !== 'input' && inputTag !== 'textarea' && inputTag !== 'text' && typeof inputValue === "string" && inputValue.includes(",")) {
            inputValue = inputValue.split(",").map(item => item.trim());
          }
        
          return { ...inputWithoutRequiredOrOptions, type: inputType, tag: inputTag, value: inputValue || input.value };
        });
    
        const hashCode = s => s.split('').reduce((a, b) => (((a << 5) - a) + b.charCodeAt(0)) | 0, 0);
        const jobRef = hashCode(job.guid).toString();
        return { url: job.applyNowLink, inputs: updatedFormInfo, profileId: user.uid, jobId: jobRef, coverLetter: "" }
      } catch (error) {
        console.error(`Error while processing job ${job.title} - ${job.guid}: `, error);
      }}));

      applications = applications.filter(application => {
        return application !== undefined && !allValuesUndefined(application.inputs);
      });

      try {
        const response = await fetch('https://app.wranglejobs.com/api/apply', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ applications }),
        });
    
        if (response.ok) {
          const { id, status } = await response.json();
         //console.log('Job application submitted:', { id, status });
          setSelectedTags([]);
        } else {
         //console.log(applications);
          console.error('Error while submitting the job applications');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }};


    const openAIWhisper = useMemo(
      () => async (questions, job) => {
        try {
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-3.5-turbo",
              messages: [
                {
                  "role": "system",
                  "content": `
    
                  You're an AI applying for a job at ${job.companyName} as ${job.title}. Based on the instructions below, answer the job application questions to maximize your chances of being hired, and Try to answer as many questions as possible:
    
                  Questions will be in this format: label|type|is required?|options (if applicable). 
                  Example of a required question: First Name|text|Required, What is your Notice Period?|textarea|Required, Do you have experience in Python?|radio|Required|Yes,No, Pronouns|checkbox|Required|Please select,He/him/his,She/her/hers,My pronouns are not listed
                  Example of a non-required question: First Name|text|Not required
    
                  1. Always answer required questions, regardless of the type. If the question is required but the information isn't available come up with an answer.
                  2. If a question is not required, you can leave it blank, but you should try to answer every question.
                  3. For visa status questions, answer "No, I will not require sponsorship now or in the future."
                  4. Provide specific details where possible. Use the data available to you to generate information if needed.
                  5. For multiple-choice questions, choose a suitable option from the given options. Never leave them unanswered if reuiqred. You must answer with one of the given options, never generate a response that is not an option.
                  6. When asked for salary expectations, give a reasonable range for the role of ${job.title}, don't say the job title outright.
                  7. Answer all radio questions with a beneficial option for the application. The options you select must be part of the given options.
                  8. Keep text responses positive, focusing on the applicant's strengths.
                  9. Avoid mentioning AI or the Himalayas.
                  10. Fill every required field. If it's beneficial, fill optional fields as well.
                  11. When asked for additional information or a signature, provide it if possible.
                  12. If given the question "Add a cover letter or anything else you want to share.", craft a response specifically for ${job.companyName}, mentioning why you're a good fit for the role of ${job.title}. Ensure this response goes into the field named "Add a cover letter or anything else you want to share." in the output JSON object. Don't attach this at the end of the output, it should still correspond to this question.
                  13. Leave file input fields blank.
                  14. For textarea types, answer it like you would a normal text question, except longer. 
                  15. When given a question about how you heard about the job, answer with something that the company will recieve positively ("I've been following your product for a long time and saw the job opening on your website.", etc.)
    
                  The goal is to create a compelling job application that follows these instructions, keeping in mind the role, industry standards, and company specifics. All your responses should be tailored to align with ${job.companyName} and the role of ${job.title}. Try to answer as many questions as possible.
                
                  Example profile: {"portfolio":"google.com","favorites":[],"timestamp":[4,2,2023], "applicationsLeft":10,"phone":"+1 123 456 7890", "keyInfo":"This is a test for generating a cover letter","numberApplied":0,"email":"john.doe@example.com","address":"Portland, OR, 97212", "name":"John Doe"}
    
                  Example inputs in format of label|type|is required?|options (if applicable):
              
                  Input:
                    First Name|text|Required
                    Last Name|text|Required
                    Email|text|Required
                    Phone|text|Required
                    LinkedIn Profile|text|Not required
                    Current Company|text|Not required
                    Preferred Name|text|Required
                    What brings you to this industry?|textarea|Not required
                    Pronouns|select|Required|Please select,He/him/his,She/her/hers,My pronouns are not listed
                    Are you legally authorized to work in the United States?|select|Required|Yes,No
                    Do you now or in the future require sponsorship for employment visa status (e.g., H-1B, TN, E-3, F-1 visa status)?|select|Required|Yes,No
                    How did you first learn about Affirm as an employer?|select|Required|Affirm blog,Affirm Recruiting Team reached out,Affirm’s Career Site,Glassdoor,I have used Affirm as a product,I know someone that works at Affirm,Twitter,Other
                    GitHub|text|Not required
                    Twitter|text|Not required
                    Portfolio|text|Not required
                    Other Links|textarea|Not required
                    Which U.S. State or Canadian Province do you reside in?|select|Required|Indiana,Iowa,Kansas,Kentucky,Nevada,Ohio,Oklahoma,Oregon,Pennsylvania
                    What is your current CTC?|textarea|required
                    Add a cover letter or anything else you want to share.|textarea|Not required
    
                    
                  Output:
                    {"First Name":"John","Last Name":"Doe","Email":"john.doe@example.com","Phone":"+1 123 456 7890","LinkedIn Profile":"https://www.linkedin.com/in/johndoe","Preferred Name":"John","What brings you to this industry?":"I've always been passionate about this industry.","Pronouns":"He/him/his","Are you legally authorized to work in the United States?":"Yes","Do you now or in the future require sponsorship for employment visa status (e.g., H-1B, TN, E-3, F-1 visa status)?":"No","How did you first learn about Affirm as an employer?":"Affirm’s Career Site", "Github":"", "Twitter":"", "Portfolio":"https://johndoe.com","Which U.S. State or Canadian Province do you reside in?":"Oregon","What is your current CTC?": "$120,000","Add a cover letter or anything else you want to share.":"Dear Hiring Manager, I am excited to apply for the position of Technical Success Manager at Affirm. As a software engineer at google, I have interacted in a variety of technology companies, including Uber and Roku. My experience in the tech industry, combined with my passion for developing and delivering successful products, make me an excellent candidate for this role. I have a deep understanding of the importance of technical success in a company. My experience working with a variety of startups has given me the ability to identify and solve technical challenges, mitigate risk, and ensure the success of new product launches. I have also developed a strong network of technical experts and engineers, whom I can rely on to help solve any issues that may arise. My attention to detail, communication skills, and focus on results make me an ideal candidate for this role. I am confident that my experience and expertise will enable me to make significant contributions to Spruce. Thank you for considering my application. Best regards, John Doe"}
                  
                
                  Your response should be no more than 1000 tokens total. Try to compress longer phrases to retain meaning but preserve tokens.  `
                },
                {
                  role: "user",
                  content: `Here's is your information: ${JSON.stringify(
                    {
                      Address: userData.address,
                      Portfolio: userData.portfolio,
                      Phone: userData.phone,
                      Name: userData.name,
                      Keyinfo: userData.keyInfo,
                      Filename: userData.filename,
                      Email: userData.email,
                      linkedin: userData.linkedin,
                      figma: userData.figma,
                      dribbble: userData.dribbble,
                      github: userData.github,
                      twitter: userData.twitter,
                      stackoverflow: userData.stackoverflow
                    }
                  )}. Input: 
                    ${questions}
                    
                    Output:
                    `,
                },
              ],
              temperature: 0.8,
              max_tokens: 1250,
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer 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`,
              },
            }
          );
          const {message: {content: rawAnswers}, finish_reason} = response.data.choices[0]
          if(finish_reason !== "stop"){
            console.error("OpenAI response incomplete", {finish_reason, rawAnswers});
            return {};
          }
    
          try{
            try {
             //console.log(JSON.parse(rawAnswers));
              return JSON.parse(rawAnswers);
            } catch(e) {
              console.error("Couldn't parse JSON in raw answers", e);
            }
          }catch(e){
           //console.log("Error parsing JSON response from OpenAI API", rawAnswers, e);
          }
        } catch (error) {
          console.error("Error while calling OpenAI Whisper API:", error);
          return [];
        }
      },
      [userData]
    );
  
    const fetchFormInfo = async (appLink) => {
      try {
        const response = await fetch(`${currentApi}/get-form`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ appLink: appLink }),
        });
    
        if (response.ok) {
          const data = await response.json();
          return data.formInfo;
        } else {
          console.error('Error while fetching form information');
        }
      } catch (error) {
        console.error('Error while fetching form information:', error);
      }
    
      // If there was an error, return null (or some other appropriate value)
      return null;
    };
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const {setShowUSOnly, setShowRemoteOnly, setShowFavoritesOnly, showUSOnly, showRemoteOnly, showFavoritesOnly, filteredJobs, jobs, selectedTags, selectedJob} = useJobs();
    const inputContainerRef = useRef(null);
    const dropdownRef = useRef(null);
    const dropdownWidthRef = useRef(null);
    const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false);
    const filterButtonRef = useRef(null);
    const filterDropdownRef = useRef(null);
    const [jobQuantity, setJobQuantity] = useState(1);
    const [sliderValue, setSliderValue] = useState(jobQuantity);
    const sliderRef = useRef(null);
  
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsInputFocused(false);
      }
    };

    const handleClickOutsideFilter = (event) => {
      if (
        filterButtonRef.current &&
        !filterButtonRef.current.contains(event.target) &&
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target)
      ) {
        setIsFilterDropdownOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);


  const tagColors = {
    engineering: { bgColor: 'bg-green-50', textColor: 'text-green-600' },
    sales: { bgColor: 'bg-blue-50', textColor: 'text-blue-600' },
    marketing: { bgColor: 'bg-purple-50', textColor: 'text-purple-600' },
    product: { bgColor: 'bg-pink-50', textColor: 'text-pink-600' },
    design: { bgColor: 'bg-teal-50', textColor: 'text-teal-600' },
    support: { bgColor: 'bg-red-50', textColor: 'text-red-600' },
    operations: { bgColor: 'bg-orange-50', textColor: 'text-orange-600' },
    data: { bgColor: 'bg-indigo-50', textColor: 'text-indigo-600' },
    finance: { bgColor: 'bg-yellow-50', textColor: 'text-yellow-600' },
    management: { bgColor: 'bg-gray-100', textColor: 'text-gray-600' },
  };
  const allTags = Object.keys(tagColors);

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideFilter);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    };
  }, []);

  const handleFilterOptionClick = (option) => {
    if (isFilterDropdownOpen) {
      if (option === 'Favorites') {
        setShowFavoritesOnly(prevShowFavoritesOnly => !prevShowFavoritesOnly);
      } else if (option === 'US Only') {
        setShowUSOnly(prevShowUSOnly => !prevShowUSOnly);
      } else if (option === 'Remote Jobs') {
        setShowRemoteOnly(prevShowRemoteOnly => !prevShowRemoteOnly);
      }
      setSelectedFilters((prevSelectedFilters) => {
        if (prevSelectedFilters.includes(option)) {
          return prevSelectedFilters.filter((filter) => filter !== option);
        } else {
          return [...prevSelectedFilters, option];
        }
      });
      setIsFilterDropdownOpen(false);
    }
  };

  const renderTag = (tag, handleClickTag) => {
    const normalizedTag = tag.toLowerCase();
    const color =
      tagColors[normalizedTag] || {
        bgColor: 'bg-gray-100',
        textColor: 'text-gray-600',
      };
    const isSelected = selectedTags.includes(tag);
    return (
        <div
        className={`rounded-8xs ${color.bgColor} ${color.textColor} flex flex-row py-1 px-2 items-center justify-start gap-[4px] opacity-100 cursor-pointer mx-1 my-1`} // Added margin for spacing
        style={{ display: 'inline-block', fontSize: '0.875rem' }} // Adjust font size here
        onClick={(event) => {
          event.stopPropagation();
          if (handleClickTag) {
            handleClickTag(tag);
          }
        }}
      >
        <div
          className="relative tracking-[-0.1px] leading-[18px] font-medium"
          style={{ display: 'inline-block', overflowWrap: 'break-word' }}
        >
          {tag.charAt(0).toUpperCase() + tag.slice(1)}
        </div>
      </div>
    );
  };

    return props.isOpen ? (
        <motion.div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-10"
        initial={{ opacity: 0}}
        animate={{ opacity: 1}}
        exit={{ opacity: 0}}
        transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={props.onClose}>
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <div className="relative rounded-md w-full md:w-2/5 lg:w-2/6 xl:w-2/7 bg-base-white shadow-lg overflow-hidden flex flex-col items-center justify-center text-sm text-gray-600 font-text-sm-semibold mx-5">
        <div className="self-stretch flex flex-row items-center justify-center">
          <div className="self-stretch flex-1 flex flex-col py-6 px-5 items-start justify-start gap-[24px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[24px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[2px] text-lg text-gray-900">
                <div className="self-stretch relative leading-[28px] font-semibold">
                  Auto-apply
                </div>
                <div className="self-stretch relative text-base leading-[24px] text-gray-600">
                  Set your amount of jobs, apply filters or tags to 
                  the request, and let Wrangle apply for you.
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
              <div className="mt-2 mb-4 flex-col gap-[24px] self-stretch">
              <div className="flex flex-col items-start justify-start gap-[16px] self-stretch">
                <div className="relative tracking-[-0.1px] leading-[20px] font-medium inline-block self-stretch">
                  Job quantity: {jobQuantity}/{filteredJobs.length}
                </div>
                <input
                  type="range"
                  id="jobQuantity"
                  name="jobQuantity"
                  min="1"
                  max={filteredJobs.length}
                  value={jobQuantity}
                  onChange={(e) => setJobQuantity(e.target.value)}
                  className="self-stretch rounded-lg bg-neutral-500 appearance-none accent-[#5BC68D] h-2"
                  style={{
                    borderColor: '#5BC68D',
                    background: `linear-gradient(to right, #5BC68D 0%, #5BC68D ${jobQuantity / filteredJobs.length * 100}%, #F2F4F7 ${jobQuantity / filteredJobs.length * 100}%, #F2F4F7 100%)`,
                  }}
                />
              </div>
                </div>
                <div className="self-stretch h-15 shrink-0 flex flex-row items-start justify-start gap-[12px] text-sm relative">
                  <div
                    ref={inputContainerRef}
                    onClick={() => {
                      setIsInputFocused(true);
                    }}
                    className="cursor-pointer flex-1 rounded-8xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row p-3 items-center justify-start gap-4 text-sm text-success-600 border-[1px] border-solid border-neutral-700"
                  >
                    <img
                      className="relative w-5 h-5 shrink-0 overflow-hidden"
                      alt=""
                      src="/labeldouble.svg"
                    />
                    <div className="text-neutral-400">
                      Click to select tags...
                    </div>
                  </div>
              <div
                ref={filterButtonRef}
                onClick={() => {
                  dropdownWidthRef.current = filterDropdownRef.clientWidth;
                  setIsFilterDropdownOpen(!isFilterDropdownOpen);
                }}                
                className="cursor-pointer self-stretch rounded-8xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center px-3 justify-center gap-4 text-sm text-success-600 border-[1px] border-solid border-neutral-700"
              >
              <motion.svg
                  whileTap={{ scale: 1.1 }}
                  className={`outline-none relative w-[24px] h-[24px] shrink-0 ${isFilterDropdownOpen || showFavoritesOnly || showUSOnly || showRemoteOnly ? 'mediumseagreen-200' : 'text-gray-200'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M3.56809 2C3.57871 2 3.58934 2.00001 3.59999 2.00001L20.4319 2C20.6843 1.99997 20.9301 1.99994 21.1382 2.01695C21.3668 2.03563 21.6365 2.07969 21.908 2.21799C22.2843 2.40974 22.5903 2.7157 22.782 3.09202C22.9203 3.36345 22.9644 3.63318 22.983 3.86178C23 4.06993 23 4.31571 23 4.56809L23 5.2694C23 5.28764 23 5.30639 23.0001 5.32559C23.0007 5.53391 23.0015 5.79616 22.9343 6.05176C22.8761 6.27325 22.7803 6.4831 22.651 6.67216C22.5019 6.89031 22.3032 7.06149 22.1454 7.19746C22.1308 7.21 22.1166 7.22224 22.1028 7.23419L15.707 12.7772C15.5983 12.8714 15.5439 12.9189 15.506 12.9552L15.5031 12.958L15.5029 12.9621C15.5003 13.0145 15.5 13.0867 15.5 13.2306V18.4584C15.5 18.4693 15.5001 18.4821 15.5003 18.4964C15.5017 18.6339 15.5048 18.9193 15.4054 19.1858C15.3218 19.4099 15.1858 19.6108 15.0087 19.7716C14.7983 19.9629 14.5321 20.0661 14.4039 20.1158C14.3905 20.121 14.3787 20.1256 14.3685 20.1296L10.9406 21.5008C10.7817 21.5644 10.606 21.6347 10.4511 21.681C10.2844 21.7307 10.0106 21.7957 9.69481 21.7301C9.30778 21.6497 8.96815 21.4198 8.74974 21.0903C8.57152 20.8214 8.53021 20.543 8.51449 20.3698C8.49987 20.2088 8.49993 20.0196 8.49998 19.8484L8.49999 13.2306C8.49999 13.0867 8.4997 13.0145 8.49711 12.9621L8.4969 12.958L8.49396 12.9552C8.45606 12.9189 8.40167 12.8714 8.29294 12.7772L1.89717 7.23419C1.88338 7.22224 1.86918 7.21 1.85462 7.19747C1.6968 7.06149 1.49812 6.89032 1.34897 6.67216C1.21972 6.4831 1.12389 6.27325 1.06568 6.05176C0.998502 5.79617 0.999267 5.53391 0.999875 5.3256C0.999931 5.30639 0.999986 5.28764 0.999986 5.2694V4.60001C0.999986 4.58936 0.999985 4.57873 0.999983 4.56811C0.999953 4.31572 0.999924 4.06993 1.01693 3.86178C1.03561 3.63318 1.07968 3.36345 1.21797 3.09202C1.40972 2.7157 1.71568 2.40974 2.092 2.21799C2.36343 2.07969 2.63316 2.03563 2.86176 2.01695C3.06991 1.99994 3.31571 1.99997 3.56809 2Z"/>
                </motion.svg>
              </div>
              <AnimatePresence>
              <AnimatePresence key="filter-presence">
              {filterButtonRef.current && (
                <motion.div
                  key="filter-dropdown"
                  ref={filterDropdownRef}
                  className={`absolute top-full bg-white rounded-md z-10 ${!isFilterDropdownOpen ? 'hidden' : ''}`}
                  style={{
                    right: filterButtonRef.current
                    ? '0px'
                    : "auto",
                    width: "fit-content",
                    minWidth: filterButtonRef.current ? filterButtonRef.current.clientWidth + "px" : "100%",
                    boxSizing: "border-box",
                    border: "1px solid #CBD5E0",
                    maxHeight: isFilterDropdownOpen? "160px" : "40px",
                    overflowY: "scroll",
                  }}
                  initial={{ opacity: 0, y: -10 }}
                  animate={isFilterDropdownOpen ? { opacity: 1, y: 0 } : { opacity: 0, y: -10 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="list-none">
                    {["Favorites", "US Only", "Remote Jobs"].map((option) => (
                      <li
                        key={option}
                        className={`py-4 pr-16 pl-4 ${
                          selectedFilters.includes(option) ? "text-mediumseagreen-200" : ""
                        } hover:bg-gray-100 cursor-pointer whitespace-nowrap text-left`}
                        onClick={() => handleFilterOptionClick(option)}
                      >
                        {option}
                      </li>
                    ))}
                  </div>
                </motion.div>
              )}

              </AnimatePresence>
              <AnimatePresence key="input-presence">
                {isInputFocused && (
                  <motion.div
                    key="input-dropdown"
                    ref={dropdownRef}
                    className={`absolute left-0 top-full bg-white ${isInputFocused ? 'border-[1px] border-solid border-neutral-700' : ''} rounded-md z-10`}
                    style={{
                      width: inputContainerRef.current
                        ? inputContainerRef.current.clientWidth + 'px'
                        : '100%',
                      boxSizing: 'border-box',
                      ...(isInputFocused ? { border: '1px solid #CBD5E0' } : {}),
                      maxHeight: '170px',
                      overflowY: 'scroll',
                    }}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    {props.searchInput
                      ? props.suggestedTags.map((tag) => (
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            key={tag}
                            onClick={() => {
                              setIsInputFocused(false);
                              setSelectedTags((prevSelectedTags) => {
                                if (prevSelectedTags.includes(tag)) {
                                  return prevSelectedTags.filter((t) => t !== tag);
                                } else {
                                  return [...prevSelectedTags, tag];
                                }
                              });
                            }}
                          >
                            {renderTag(tag, null, true)}
                          </div>
                        ))
                      : allTags.map((tag) => (
                          <div
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            key={tag}
                            onClick={() => {
                              setIsInputFocused(false);
                              setSelectedTags((prevSelectedTags) => {
                                if (prevSelectedTags.includes(tag)) {
                                  return prevSelectedTags.filter((t) => t !== tag);
                                } else {
                                  return [...prevSelectedTags, tag];
                                }
                              });
                            }}
                          >
                            {renderTag(tag, null, true)}
                          </div>
                        ))}
                  </motion.div>
                )}
              </AnimatePresence>
                </AnimatePresence>
        </div>
        <div className="self-stretch h-15 shrink-0 flex flex-row items-start justify-start gap-[12px] text-sm relative">
          <div className="flex gap-1">
            {selectedTags.map((tag) =>
              renderTag(tag, (tagToRemove) => {
                setSelectedTags((prevSelectedTags) =>
                  prevSelectedTags.filter((t) => t !== tagToRemove)
                );
              }, false)
            )}
          </div>
        </div>
              
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-center">
                <Rive src="/autoapply.riv" className="relative w-[390px] h-[206px] object-cover"></Rive>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
            <div className="relative tracking-[-0.1px] leading-[20px] font-medium inline-block w-[390px]">
                <div>Estimated Time: {jobQuantity > 3 ? (20 * jobQuantity / 60).toFixed(1) + ' Minutes' : 20 * jobQuantity + ' Seconds'}.</div>
                <div>While in beta, autoapply may have a lower success rate.</div>
            </div>
              <div className="self-stretch flex flex-row items-start justify-center gap-[12px]">
                <button className="cursor-pointer py-2.5 px-4 bg-base-white flex-1 rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-gray-300" onClick={props.onClose}>
                  <div className="relative text-sm leading-[20px] font-semibold font-text-sm-semibold text-gray-700 text-left">
                    Cancel
                  </div>
                </button>
                <button className="cursor-pointer py-2.5 px-4 bg-mediumseagreen-100 flex-1 rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center border-[1px] border-solid border-mediumseagreen-100" onClick={() => handleSubmit(jobQuantity)}>
                  <div className="relative text-sm leading-[20px] font-semibold font-text-sm-semibold text-base-white text-left">
                    Auto-Apply
                  </div>
                </button>
              </div>
            </div>
          </div>
          </div>
        </div>
      </motion.div>
    ): null;
  };

export default AutoApplyModal;
  