import React from 'react';
import { useState, useRef, useEffect } from 'react';
import AutoApplyModal from './AutoApplyModal';
import { motion } from 'framer-motion';


const JobBar = props => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const inputRef = useRef(null);
  const inputContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tagColors = {
    engineering: { bgColor: 'bg-green-50', textColor: 'text-green-600' },
    sales: { bgColor: 'bg-blue-50', textColor: 'text-blue-600' },
    marketing: { bgColor: 'bg-purple-50', textColor: 'text-purple-600' },
    product: { bgColor: 'bg-pink-50', textColor: 'text-pink-600' },
    design: { bgColor: 'bg-teal-50', textColor: 'text-teal-600' },
    support: { bgColor: 'bg-red-50', textColor: 'text-red-600' },
    operations: { bgColor: 'bg-orange-50', textColor: 'text-orange-600' },
    data: { bgColor: 'bg-indigo-50', textColor: 'text-indigo-600' },
    finance: { bgColor: 'bg-yellow-50', textColor: 'text-yellow-600' },
    management: { bgColor: 'bg-gray-100', textColor: 'text-gray-600' },
  };
  const allTags = Object.keys(tagColors);


  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsInputFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const renderTag = (tag, handleClickTag, fromDropdown) => {
    const normalizedTag = tag.toLowerCase();
    const color =
      tagColors[normalizedTag] || {
        bgColor: 'bg-gray-100',
        textColor: 'text-gray-600',
      };
    const isSelected = selectedTags.includes(tag);
    return (
      <div
        className={`rounded-8xs ${color.bgColor} ${color.textColor} flex flex-row py-0.5 px-2 items-center justify-start gap-[4px] ${isSelected ? 'opacity-60' : 'opacity-100'} cursor-pointer`}
        style={{ display: 'inline-block' }}
        onClick={(event) => {
          event.stopPropagation();
          if (fromDropdown) {
            setIsInputFocused(false);
            setSelectedTags((prevSelectedTags) => {
              if (prevSelectedTags.includes(tag)) {
                return prevSelectedTags.filter((t) => t !== tag);
              } else {
                return [...prevSelectedTags, tag];
              }
            });
            props.onTagSelect(tag);
            props.onSearch('');
          } else if (handleClickTag) {
            handleClickTag(tag);
          }
        }}        
      >
        <div
          className="relative tracking-[-0.1px] leading-[18px] font-medium"
          style={{ display: 'inline-block', overflowWrap: 'break-word' }}
        >
          {tag.charAt(0).toUpperCase() + tag.slice(1)}
        </div>
      </div>
    );
  };

  return (
    <div className="self-stretch flex flex-col items-start justify-start gap-[24px] text-9xl ">
      <div className="self-stretch flex flex-col items-start justify-start">
        <div className="self-stretch flex flex-row items-start justify-between">
          <div className="relative tracking-[-0.01em] leading-[38px] font-semibold mb-2">
            Applied
          </div>
            </div>
              <div className="relative text-base tracking-[-0.1px] leading-[24px] text-gray-50 inline-block w-full">
                Here's where you can see your applications, and their responses.
              </div>
            </div>
    </div>
  );
};

export default JobBar;
