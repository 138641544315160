import React from "react";
import {useState, useEffect} from 'react';
import { useUserData } from "../UserDataContext";
import { motion } from "framer-motion";
const AddNewAccountModal = props => {
  if (!props.show) return null;
  const {userData, setUserData} = useUserData();
  const accounts = [
    { name: 'LinkedIn', url: "linkedin.com/in/" + (userData.linkedin || ''), icon: '/linkedin.svg' },
    { name: 'Github', url: "github.com/" + (userData.github || ''), icon: '/github.svg' },
    { name: 'Twitter', url: "twitter.com/" + (userData.twitter || ''), icon: '/twitter.svg' },
    { name: 'Figma', url: "figma.com/@" + (userData.figma || ''), icon: '/figma.svg' },
    { name: 'Dribbble', url: "dribbble.com/" + (userData.dribbble || ''), icon: '/dribbble.svg' },
    { name: 'StackOverflow', url: "stackoverflow.com/users/" + (userData.stackoverflow || ''), icon: '/stack.svg' },
    
  ];  
  const [selected, setSelected] = useState(0);
  const [username, setUsername] = useState('');

  useEffect(() => {
    const selectedAccount = accounts[selected].name.toLowerCase();
    setUsername(userData[selectedAccount] || '');
  }, [selected, userData]);

  const updateUserData = (remove = false) => {
    const selectedAccount = accounts[selected].name.toLowerCase();
  
    if (remove) {
      setUserData(prevData => {
        const newData = { ...prevData };
        delete newData[selectedAccount];
        return newData;
      });
      setUsername('');
    } else {
      setUserData(prevData => ({...prevData, [selectedAccount]: username}));
      setUsername(username);
    }
  };
  
  

  return (
    <motion.div className="flex flex-col items-center justify-start gap-[20px] text-left text-sm text-e"
    initial={{ opacity: 0}}
    animate={{ opacity: 1}}
    exit={{ opacity: 0}}
    transition={{ duration: 0.4, ease: "easeInOut" }}
  >
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-10">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true" onClick={props.onClose}>
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <div className="relative rounded-md max-w-[50%] bg-base-white shadow-[0px_20px_24px_-4px_rgba(16,_24,_40,_0.08),_0px_8px_8px_-4px_rgba(16,_24,_40,_0.03)] w-full overflow-hidden flex flex-row items-start justify-start text-left text-sm text-gray-600 font-text-sm-semibold">
        <div className="self-stretch flex flex-row items-start justify-start">
          <div className="box-border w-80 flex flex-col pt-4 px-0 pb-0 items-start justify-start border-r-[1px] border-solid border-gray-200">
            <div className="self-stretch h-96 overflow-auto shrink-0 flex flex-col pt-0 px-0 pb-4 box-border items-start justify-start relative gap-[2px]">
              <div className="self-stretch flex flex-row py-0 px-[18px] items-center justify-start z-[0]">
                <div className="flex-1 relative leading-[20px] font-medium">
                  Accounts
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-start z-[1] text-gray-900">
                {accounts.map((account, index) => (
                  <div
                    key={index}
                    className={`self-stretch flex flex-row py-0.5 px-2 items-center justify-start`}
                    onClick={() => setSelected(index)}
                  >
                    <div className={`flex-1 rounded-lg overflow-hidden flex flex-row py-2.5 pr-2.5 pl-3.5 items-start justify-start gap-[12px] ${index === selected ? 'bg-gray-neutral-100' : ''}`}>
                      <img
                        className="relative w-10 h-10 overflow-hidden shrink-0"
                        alt={account.name}
                        src={account.icon}
                      />
                      <div className="flex-1 flex flex-col items-start justify-start">
                        <div className="self-stretch relative leading-[20px] font-medium">
                          {account.name}
                        </div>
                        <div className="self-stretch relative leading-[20px] text-gray-600">
                          {account.url}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex-1 flex flex-col py-6 px-5 items-start justify-start gap-[24px] text-gray-7001">
          <div className="self-stretch flex flex-col items-start justify-start">
            <div className="self-stretch flex flex-col items-start justify-start">
              <div className="self-stretch flex flex-row items-start justify-start">
                <div className="flex-1 flex flex-col items-start justify-start gap-[8px]">
                  <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
                    Username
                  </div>
                  <input
                    className="font-text-sm-semibold focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 text-mini bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] overflow-hidden shrink-0 flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700"
                    type="text"
                    placeholder="Name"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[12px] text-base-white">
            <div className="self-stretch rounded-lg bg-mediumseagreen-200 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row py-2.5 px-4 items-center justify-center border-[1px] border-solid border-mediumseagreen-200 cursor-pointer" onClick={() => updateUserData(false)}>
              <div className="relative leading-[20px] font-semibold">
                Update Information
              </div>
            </div>
            <div 
              className={`self-stretch rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] overflow-hidden flex flex-row py-2.5 px-4 items-center justify-center border-[1px] border-solid border-neutral-700 cursor-pointer ${username ? 'text-black' : 'text-neutral-700'}`} 
              onClick={() => {
                updateUserData(true);
                setUsername('');
              }}
            >
              <div className="relative leading-[20px] font-semibold">
                Remove Account
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
  );
};

export default AddNewAccountModal;