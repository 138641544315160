import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useState, useEffect} from "react";
import { setPersistence, browserLocalPersistence, getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from './firebase';
import Dashboard from "./pages/Dashboard";
import Opportunities from "./pages/Opportunities";
import Applied from "./pages/Applied";
import Login from "./pages/Login";
import OnboardingModal from "./components/OnboardingModal"; // import the Onboarding component
import { JobsProvider } from "./jobsContext";
import { UserDataProvider, useUserData } from "./UserDataContext";
import { PaymentProvider } from "./PaymentContext";
import styles from "./components/Loader.module.css";
import SideMenuGeneral from "./components/SideMenuGeneral";
import UpgradeModal from "./components/UpgradeModal";
import UpgradeFeatureModal from "./components/UpgradeFeatureModal";
import ConfirmModal from "./components/ConfirmModal";
import Rive from "rive-react";


function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        localStorage.setItem('user', JSON.stringify({
          uid: currentUser.uid,
          email: currentUser.email,
        }));
        setUser(currentUser);
      } else {
        localStorage.removeItem('user');
        setUser(null);
      }
      setLoading(false);
    });

    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
      setLoading(false);
    }
    return unsubscribe;
  }, []);

  return (
    <UserDataProvider user={user} loading={loading}>
      <PaymentProvider>
        <JobsProvider>
          <AppContent />
        </JobsProvider>
      </PaymentProvider>
    </UserDataProvider>
  );
}

function AppContent() {
  const location = useLocation();
  const { userData, isLoading, isAuthenticated } = useUserData();

  const Loader = () => (
    <Rive src="/loadingwrangle.riv" className="w-screen h-screen"></Rive>
  );

  const showSideMenu = location.pathname !== "/login" && location.pathname !== "/";
;

  if (!isAuthenticated) {
    return <Login />
  }

  if (isLoading)
    return (
      <div className="w-screen text-left text-base text-gray-50 font-text-l-regular" style={{ display: "flex" }}>
        <SideMenuGeneral />
        <Loader />
      </div>
    );

  return (
    <div className="w-screen text-left text-base text-gray-50 font-text-l-regular" style={{ display: "flex" }}>
      {showSideMenu && <SideMenuGeneral />}
      <UpgradeModal />
      <UpgradeFeatureModal />
      <ConfirmModal />
      {isAuthenticated && userData.onboarded === false ? <OnboardingModal /> : null}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/applied" element={<Applied />} />
      </Routes>
    </div>
  );
}


export default App;