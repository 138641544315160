import { useMemo } from "react";

const ContainerButton = props => {
  const buttonStyle = useMemo(() => {
    return {
      backgroundColor: props.propBackgroundColor,
    };
  }, [props.propBackgroundColor]);

  const textStyle = useMemo(() => {
    return {
      color: props.propColor,
    };
  }, [props.propColor]);

  const externalLinkIconStyle = useMemo(() => {
    return {
      display: props.propDisplay,
    };
  }, [props.propDisplay]);

  return (
    <button
      className="cursor-pointer py-6 px-[18px] bg-[transparent] self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center gap-[12px] border-[1px] border-solid border-neutral-600"
      style={buttonStyle}
      onClick={props.onClick}
    >
      <img
        className="relative w-5 h-5 shrink-0 overflow-hidden hidden"
        alt=""
        src="/placeholder3.svg"
      />
      <div
        className="relative text-lg leading-[22px] font-semibold font-text-l-regular text-gray-600 text-left"
        style={textStyle}
      >
        {props.actionText}
      </div>
      <img
        className="relative w-5 h-5 shrink-0 overflow-hidden"
        alt=""
        src={props.actionLinkId}
        style={externalLinkIconStyle}
      />
    </button>
  );
};

export default ContainerButton;
