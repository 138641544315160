import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

const FormInputCard = ({ titleText, inputText, value, onChange, applied }) => {
  return ((applied && (inputText == undefined || inputText == null || inputText == "")) ? null : (
    <div className="flex-1 flex flex-col items-start justify-start text-left text-sm text-gray-700 font-text-l-regular mb-3">
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
        <span>{titleText}</span>
      </div>
      <TextareaAutosize
      className="font-text-l-regular focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 text-mini bg-base-white w-[300px] rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border py-3 px-3 justify-start border-[1px] border-solid border-neutral-700 resize-none"
      placeholder={applied ? inputText : ""}
      required
      value={value}
      onChange={(e) => onChange(e.target.value)}
      readOnly={applied ? true : false}
    />
      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] text-gray-50 hidden">
        Hint text
      </div>
    </div>
  ));
};

export default FormInputCard;
