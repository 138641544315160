import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {  } from 'firebase/auth';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { app, db } from '../firebase';
import { useUserData } from '../UserDataContext';
import { motion } from 'framer-motion';

function Login() {
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const auth = getAuth(app);
  const navigate = useNavigate();
  const { setUserData } = useUserData();

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const loginUser = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (rememberMe) {
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('rememberMe', 'true');
        localStorage.setItem('expiry', new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
      } else {
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('expiry');
      }
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('rememberMe') === 'true' && new Date().getTime() < localStorage.getItem('expiry')) {
      setEmail(localStorage.getItem('email'));
      setPassword(localStorage.getItem('password'));
      setRememberMe(true);
    } else {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
      localStorage.removeItem('expiry');
    }
  }, []);

  const signupUser = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const userId = user.uid;
      const docRef = doc(db, 'Profiles', userId);

      const initialData = {
        email: user.email,
        applied: [],
        favorites: [],
        onboarded: false,
      };

      await setDoc(docRef, initialData, { merge: true });
      setUserData(initialData);

      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message);
    }
  };

  const handleForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setErrorMessage("Check your email for a link to reset your password");
    } catch (error) {
      // An error happened.
      console.error(error);
      setErrorMessage(error.message);
    }
  };
  

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      // The signed-in user info.
      const user = result.user;
      navigate('/dashboard');
    } catch (error) {
      // Handle Errors here.
      console.error(error);
      setErrorMessage(error.message);
    }
  };
  


  const handleFormSwitch = () => {
    setIsSignUp(!isSignUp);
  }

  const handleFormSubmit = () => {
    if (isSignUp) {
      signupUser();
    } else {
      loginUser();
    }
  }

  return (
    <div className="relative bg-base-white w-screen h-screen flex flex-row items-start justify-start text-left text-sm text-gray-900 font-text-l-regular" style={{boxSizing: 'border-box'}}>
    <div className="self-stretch flex-1 flex flex-row p-8 items-center justify-end gap-[48px]">
      <div className="self-stretch flex-1 flex flex-row py-[148px] px-18 items-center justify-center">
        <div className="w-[360px] flex flex-col items-center justify-start gap-[32px]">
          <motion.div className="self-stretch flex flex-col items-center justify-start gap-[24px] text-center text-11xl"
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.8, delay: 0, ease: "easeOut" }}>
            <img
              className="relative w-[58px] h-[58px]"
              alt=""
              src="/logoglyph.png"
            />
            <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
              <div className="self-stretch relative leading-[38px] font-semibold">
                Let's get you hired.
              </div>
              <div className="self-stretch relative text-base leading-[24px] text-gray-600">
                {isSignUp ? 'Welcome! Enter your details to get started.' : 'Welcome back! Log in with your account.'}
              </div>
            </div>
          </motion.div>
          <div className="self-stretch rounded-xl flex flex-col items-center justify-start gap-[24px] text-gray-700">
            <div className="self-stretch flex flex-col items-start justify-start gap-[20px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
              <motion.div className="self-stretch flex flex-col items-start justify-start gap-[6px]"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.8, delay: 0, ease: "easeOut" }}>
                      <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
                        Email
                      </div>
                      <input
                        className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700"
                        type="text"
                        placeholder={"Enter email..."}
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                      />
              </motion.div>
              {(errorMessage.includes("user") || errorMessage.includes("email")) && (
                <div className="self-stretch relative leading-[20px] text-red-600">
                  {errorMessage}
                </div>
              )}
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[6px]">
                <motion.div className="self-stretch flex flex-col items-start justify-start gap-[6px]"
                  initial="hidden"
                  animate="visible"
                  variants={variants}
                  transition={{ duration: 0.8, delay: 0.1, ease: "easeOut" }}>
                    <div className="self-stretch relative tracking-[-0.1px] leading-[20px] font-medium">
                        Password
                      </div>
                      <input
                        className="font-text-l-regular text-mini focus:outline-none focus:ring focus:ring-green-50 focus:border-mediumseagreen-200 bg-base-white self-stretch rounded-md shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border h-[46px] shrink-0 overflow-hidden flex flex-row py-3 px-4 items-center justify-start border-[1px] border-solid border-neutral-700"
                        type="password"
                        placeholder={"Enter password..."}
                        required
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                </motion.div>
                {errorMessage.includes("password") && (
                  <div className="self-stretch relative leading-[20px] text-red-600">
                    {errorMessage}
                  </div>
                )}
              </div>
            </div>
            {!isSignUp ? 
            <div className="self-stretch flex flex-row items-center justify-start">
            <motion.div className="flex-1 flex flex-row items-start justify-start gap-[8px]">
              <div 
                  className="flex flex-row pt-0.5 px-0 pb-0 items-center justify-center" 
                  onClick={() => setRememberMe(!rememberMe)}
              >
                <div 
                  className={`relative rounded box-border w-4 h-4 overflow-hidden shrink-0 border-[1px] border-solid ${rememberMe ? 'bg-mediumseagreen-200' : 'bg-base-white'} border-gray-300`} 
                />
              </div>
              <div className="flex-1 flex flex-col items-start justify-start">
                <div className="self-stretch relative leading-[20px] font-medium">
                  Remember for 30 days
                </div>
                <div className="self-stretch relative leading-[20px] text-gray-600 hidden">
                  Save my login details for next time.
                </div>
              </div>
            </motion.div>
            <motion.div className="self-stretch cursor-pointer overflow-hidden flex flex-row items-center justify-center gap-[8px] text-mediumseagreen-200"
            onClick={handleForgotPassword}>
              <div className="relative leading-[20px] font-semibold cursor-pointer">
                Forgot password
              </div>
            </motion.div>
          </div>
           : null}
            
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px] text-base text-base-white">
              <motion.button
                className="self-stretch rounded-lg bg-mediumseagreen-200 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row py-2.5 px-[18px] items-center justify-center border-[1px] border-solid border-mediumseagreen-200 cursor-pointer"
                onClick={handleFormSubmit}
                initial="hidden"
                animate="visible"
                variants={variants}
                transition={{ duration: 0.8, delay: 0.3, ease: "easeOut" }}
              >
                <div className="relative leading-[24px] font-semibold text-base-white cursor-pointer">
                  {isSignUp ? 'Sign up with email' : 'Log in with email'}
                </div>
              </motion.button>
            </div>
              <motion.div className="self-stretch flex flex-row items-baseline justify-center gap-[4px] text-gray-600"
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.8, delay: 0.5, ease: "easeOut" }}>
                <div className="relative leading-[20px]">
                {isSignUp ? 'Already have an account?' : 'Don’t have an account?'}
                </div>
                <div className="overflow-hidden flex flex-row items-center justify-center gap-[8px] text-mediumseagreen-200">
                  <img
                    className="relative w-5 h-5 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/placeholder2.svg"
                  />
                  <div className="relative leading-[20px] font-semibold cursor-pointer" onClick={handleFormSwitch}>
                    {isSignUp ? 'Log in' : 'Sign up'}
                  </div>
                  <img
                    className="relative w-5 h-5 overflow-hidden shrink-0 hidden"
                    alt=""
                    src="/placeholder.svg"
                  />
              </div>
            </motion.div>
            <div className="self-stretch flex flex-row items-baseline justify-center gap-[4px] text-gray-600">
          </div>
          </div>
        </div>
      </div>
      <div className="self-stretch rounded-8xs [background:linear-gradient(180deg,_#cdfee3,_#e5ffee_97.92%)] w-3/5 overflow-hidden shrink-0 flex flex-col items-start justify-start">
        <motion.img
                    className="self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover"
                    alt=""
                    src="/zzz.png"
                    initial="beast"
                            animate="visible"
                            variants={variants}
                            transition={{ duration: 0.7, delay: 0, ease: "easeOut" }}
        />
      </div>
    </div>
  </div>
  );
}

export default Login;
